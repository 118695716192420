import React from "react";
import style from "../Case/Case.module.css";
import Table from "react-bootstrap/Table";

const MoreDetails = ({ data }) => {
  return (
    <>
     <div className={`${style.moreDetails} mt-0 pt-2`} style={{zIndex:80}}>
        <Table className={`${style.tableDetails} mt-0 pt-0 `}>
          <tbody>
            {data?.majorDataC2FromM1?.otherPartyDateOfBirth && (
              <tr>
              <td className={`ps-4 `}>
                Date of birth : <span className="ps-2">{data?.majorDataC2FromM1?.otherPartyDateOfBirth}</span>
              </td>
            </tr>
            )}
            {/* {data?.majorDataC2FromM1?.otherPartyDateOfBirth && (
              <tr>
              <td className="ps-4">
                Gender : <span className="ps-2">{data.gender}</span>
              </td>
            </tr>
            )} */}
            {data?.majorDataC2FromM1?.otherPartyPhone && (
              <tr>
              <td className="ps-4">
                Phone number : <span className="ms-2">{data?.majorDataC2FromM1?.otherPartyPhone}</span>
              </td>
            </tr>
            )}
            {data?.majorDataC2FromM1?.otherPartyEmail && (
              <tr>
              <td className="ps-4">
                Email : <span className="ps-2">{data.majorDataC2FromM1.otherPartyEmail}</span>
              </td>
            </tr>
            )}
            {/* {data?.suitability && (
              <tr>
              <td className="ps-4">
                MIAM suitability : <span className="ps-2">YES / NO</span>
              </td>
            </tr>
            )} */}
             {data?.majorDataC2FromM1?.otherPartyAddressKnown==='Yes' && (
            <tr>
              <td className="ps-4">
                Address :{" "}
                <span className="ps-2">
                  {data?.majorDataC2FromM1?.otherPartyStreet +
                    " , " +
                    data?.majorDataC2FromM1?.otherPartyCity +
                    " , " +
                    data?.majorDataC2FromM1?.otherPartyPostalCode                    +
                    " , " +
                    data?.majorDataC2FromM1?.otherPartyCountry                  }
                </span>
              </td>
            </tr>
          )}  
            {data?.majorDataC2FromM1?.otherPartyAddressKnown && (
              <tr>
              <td className={`${style.last_td} ps-4`}>
                Address :{" "}
                <span className="ps-2">{data.majorDataC2FromM1.otherPartyAddressKnown}</span>
              </td>
            </tr>
            )}
            
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MoreDetails;



