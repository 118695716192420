import React, { useEffect, useState } from "react";
import style from "./dashboard.module.css";
import CompanyCard from "./companycard";
import AddCompany from "./addCompany/addcompany";
import { useGetCompaniesQuery } from "../../services/auth/companyService";
import { Spinner } from "react-bootstrap";
export default function DashboardHome() {
  const [companiesData, setCompaniesData] = useState(null);

  const {
    data: companies,
    isFetching: fetchingCaseDetails,
    refetch,
  } = useGetCompaniesQuery("data", { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (companies) {
      setCompaniesData(companies);
    }
  }, [companies, companiesData]);

  if (fetchingCaseDetails) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "95vh",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  function handleDeleteRefetch() {
    refetch();
  }

  if (companies) {
    return (
      <div className={style.parent}>
        <div className={style.cardParent}>
          <AddCompany handleDeleteRefetch={handleDeleteRefetch} />
          {companiesData?.map((company) => (
            <CompanyCard
              key={company._id}
              id={company._id}
              name={company.companyName}
              email={company.email}
              mediatorNo={company.mediators.length}
              casesNo={company.cases.length}
              logoPath={company.logo}
              company={company}
              handleDeleteRefetch={handleDeleteRefetch}
            />
          ))}
        </div>
      </div>
    );
  }
}
