import React, { useEffect, useState } from "react";
import style from "../Case/Case.module.css";
import CaseLog from "./CaseLog";
import { Link, useParams } from "react-router-dom";
import { useGetCaseDetailsQuery } from "../../services/auth/authService";
import { TbPointFilled } from "react-icons/tb";

const CaseDrive = () => {
  const { id } = useParams();
  const [CaseData, SetCaseData] = useState(null);
  const { data: CaseDetails, isFetching: fetchingCaseDetails } =
    useGetCaseDetailsQuery(id, {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true, // 15mins
    });
  useEffect(() => {
    if (CaseDetails) {
      SetCaseData(CaseDetails);
    }
  }, [CaseDetails, CaseData]);
  return (
    <>
      <div className="sidebar_container">
        <div className="w-100">
          {fetchingCaseDetails ? (
            ""
          ) : (
            <div className={`${style.DriveContainer} `}>
              <div
                className={` ${style.bg_containerDrive} rounded p-3 ${style.CaseDrive} d-flex justify-content-center align-items-center flex-wrap`}
              >
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${style.btnGoToDrive} mb-3 rounded py-2 text-center text-decoration-none text-white`}
                  to={`https://drive.google.com/drive/u/0/folders/${CaseData?.folderID}`}
                >
                  Go to Drive
                </Link>{" "}
                {CaseData?.MIAM2_C1 === "Data didn't added yet" &&
                CaseData?.MIAM1_C1 === "Data didn't added yet" &&
                CaseData?.MIAM1_C2 === "Data didn't added yet" &&
                CaseData?.MIAM2_C2 === "Data didn't added yet" &&
                CaseData?.mediationRecords.length === 0 &&
                CaseData?.C1Agreement === "" &&
                CaseData?.C2Agreement === "" ? (
                  <div className={`${style.btnDrive} p-3 rounded`}>
                    <div className=" text-center pt-3 mt-5">
                      No form has been filled out until now.
                    </div>
                  </div>
                ) : (
                  <div className={`${style.btnDrive} p-3 rounded`}>
                    {CaseDetails && (
                      <div>
                        {CaseData?.MIAM2_C1 === "Data didn't added yet" ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> MIAM2-C1
                          </p>
                        )}
                        {CaseData?.MIAM1_C1 === "Data didn't added yet" ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> MIAM1-C1
                          </p>
                        )}
                        {CaseData?.MIAM1_C2 === "Data didn't added yet" ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> MIAM1-C2
                          </p>
                        )}
                        {CaseData?.MIAM2_C2 === "Data didn't added yet" ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> MIAM2-C2
                          </p>
                        )}
                        {CaseData?.mediationRecords.length === 0 ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> MediationRecords
                          </p>
                        )}
                        {CaseData?.C1Agreement === "" ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> C1 Agreement
                          </p>
                        )}
                        {CaseData?.C2Agreement === "" ? (
                          " "
                        ) : (
                          <p className="mb-0">
                            <TbPointFilled /> C2 Agreement
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="w-100">
          <CaseLog />
        </div>
      </div>

      {/* <div className={`${style.DriveContainer} `}>
        <div
          className={` ${style.bg_containerDrive} rounded p-3 ${style.CaseDrive} d-flex justify-content-center align-items-center flex-wrap`}
        >
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className={`${style.btnGoToDrive} mb-3 rounded py-2 text-center text-decoration-none text-white`}
            to={`https://drive.google.com/drive/u/0/folders/${CaseData?.folderID}`}
          >
            Go to Drive
          </Link>{" "}
          {fetchingCaseDetails ? (
            <div
              className={`${style.btnDrive} p-3 rounded`}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "1rem",
              }}
            >
              {" "}
              <Spinner animation="border" />{" "}
            </div>
          ) : (CaseData?.MIAM2_C1 === "Data didn't added yet" &&
            CaseData?.MIAM1_C1 === "Data didn't added yet" &&
            CaseData?.MIAM1_C2 === "Data didn't added yet" &&
            CaseData?.MIAM2_C2 === "Data didn't added yet"&&
            CaseData?.mediationRecords.length === 0 &&
            CaseData?.C1Agreement === ""&&
            CaseData?.C2Agreement === "" ) ? (
              <div className={`${style.btnDrive} p-3 rounded`}>
              <div className=" text-center pt-3 mt-5">
              No form has been filled out until now.
             </div>
             </div>
          ) : (
            <div className={`${style.btnDrive} p-3 rounded`}>
              {CaseDetails && (
                <div>
                  {CaseData?.MIAM2_C1 === "Data didn't added yet" ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> MIAM2-C1
                    </p>
                  )}
                  {CaseData?.MIAM1_C1 === "Data didn't added yet" ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> MIAM1-C1
                    </p>
                  )}
                  {CaseData?.MIAM1_C2 === "Data didn't added yet" ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> MIAM1-C2
                    </p>
                  )}
                  {CaseData?.MIAM2_C2 === "Data didn't added yet" ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> MIAM2-C2
                    </p>
                  )}
                  {CaseData?.mediationRecords.length === 0 ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> MediationRecords
                    </p>
                  )}
                  {CaseData?.C1Agreement === "" ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> C1 Agreement
                    </p>
                  )}
                  {CaseData?.C2Agreement === "" ? (
                    " "
                  ) : (
                    <p className="mb-0">
                      <TbPointFilled /> C2 Agreement
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <CaseLog />
      </div> */}
    </>
  );
};
export default CaseDrive;
