import { useState, useRef } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";
import { useDispatch } from "react-redux";

import style from "../casemaim/Case.module.css";
import { updateC1M1, updateC2M1 } from "../../features/auth/authActions";

const EditMIAM1Part1 = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const location = useLocation();
  let data = location.state?.data;
  let children = data?.children;
  const regex = /^\/edit\/([^/]+)/;
  const path = location.pathname.match(regex)[0];
  const dispatch = useDispatch();
  const { id } = useParams();

  const [personalContactAndCaseInfo, setPersonalContactAndCaseInfo] = useState({
    firstName: data?.personalContactAndCaseInfo?.firstName,
    surName: data?.personalContactAndCaseInfo?.surName,
    dateOfBirth: data?.personalContactAndCaseInfo?.dateOfBirth,
    phoneNumber: data?.personalContactAndCaseInfo?.phoneNumber,
    email: data?.personalContactAndCaseInfo?.email,
    street: data?.personalContactAndCaseInfo?.street,
    city: data?.personalContactAndCaseInfo?.city,
    postCode: data?.personalContactAndCaseInfo?.postCode,
    country: data?.personalContactAndCaseInfo?.country,
    doesOtherPartyKnow: data?.personalContactAndCaseInfo?.doesOtherPartyKnow,
    makeDetailsConfidential:
      data?.personalContactAndCaseInfo?.makeDetailsConfidential,
    isClientVulnerable: data?.personalContactAndCaseInfo?.isClientVulnerable,
    disabilityRegistered: [
      data?.personalContactAndCaseInfo?.disabilityRegistered,
    ],
    gender: data?.personalContactAndCaseInfo?.gender,
    isWillingToTryMediation:
      data?.personalContactAndCaseInfo?.isWillingToTryMediation,
    areChangesToServicesRequired:
      data?.personalContactAndCaseInfo?.areChangesToServicesRequired,
    changesRequired: data?.personalContactAndCaseInfo?.changesRequiredl,
    willSupporterAttendMediation:
      data?.personalContactAndCaseInfo?.willSupporterAttendMediation,
    supporterNameAndRelation:
      data?.personalContactAndCaseInfo?.supporterNameAndRelation,
    ethnicOrigin: data?.personalContactAndCaseInfo?.ethnicOrigin,
    hasBritishPassport: data?.personalContactAndCaseInfo?.hasBritishPassport,
    immigrationStatus: data?.personalContactAndCaseInfo?.immigrationStatus,
    howClientFoundDMS: data?.personalContactAndCaseInfo?.howClientFoundDMS,
    facedIssue: data?.personalContactAndCaseInfo?.facedIssue,
    isThereDaysCanNotAttend:
      data?.personalContactAndCaseInfo?.isThereDaysCanNotAttend,
    whatDaysCanNotAttend:
      data?.personalContactAndCaseInfo?.whatDaysCanNotAttend,
    appointmentTime: data?.personalContactAndCaseInfo?.appointmentTime,
    otherParty: data?.personalContactAndCaseInfo?.otherParty,
  });

  const [otherParty, setOtherParty] = useState({
    otherPartyFirstName: data?.otherParty?.otherPartyFirstName,
    otherPartySurname: data?.otherParty?.otherPartySurname,
    otherPartyDateOfBirth: data?.otherParty?.otherPartyDateOfBirth,
    otherPartyEmail: data?.otherParty?.otherPartyEmail,
    otherPartyPhone: data?.otherParty?.otherPartyPhone,
    otherPartyStreet: data?.otherParty?.otherPartyStreet,
    otherPartyCity: data?.otherParty?.otherPartyCity,
    otherPartyCountry: data?.otherParty?.otherPartyCountry,
    otherPartyPostalCode: data?.otherParty?.otherPartyPostalCode,
  });

  const [previousRelationshipDetails, setPreviousRelationshipDetails] =
    useState({
      separationDate: data?.previousRelationshipDetails?.separationDate,
      relationshipPeriod: data?.previousRelationshipDetails?.relationshipPeriod,
      isMarried: data?.previousRelationshipDetails?.isMarried,
      marriageDate: data?.previousRelationshipDetails?.marriageDate,
    });
  const [courtProceedings, setCourtProceedings] = useState({
    isFacingLegalProceedings: data?.courtProceedings?.isFacingLegalProceedings,
    legalProceedingsInfo: data?.courtProceedings?.legalProceedingsInfo,
    courtApplicationKnown: data?.courtProceedings?.courtApplicationKnown,
    courtApplicationInfo: data?.courtProceedings?.courtApplicationInfo,
    maritalStatus: data?.courtProceedings?.maritalStatus,
  });
  const [yourSolicitorOrMcKenzieFriend, setYourSolicitorOrMcKenzieFriend] =
    useState({
      solicitorCheck: data?.yourSolicitorOrMcKenzieFriend?.solicitorCheck,
      solicitorName: data?.yourSolicitorOrMcKenzieFriend?.solicitorName,
      solicitorLawFirmName:
        data?.yourSolicitorOrMcKenzieFriend?.solicitorLawFirmName,
      solicitorTelephone:
        data?.yourSolicitorOrMcKenzieFriend?.solicitorTelephone,
      solicitorEmail: data?.yourSolicitorOrMcKenzieFriend?.solicitorEmail,
      sendMediationCertificateToSolicitor:
        data?.yourSolicitorOrMcKenzieFriend
          ?.sendMediationCertificateToSolicitor,
      consultationRegardingLegalSupport:
        data?.yourSolicitorOrMcKenzieFriend?.consultationRegardingLegalSupport,
    });

  const [child, setChild] = useState({
    firstChildFirstName:
      children && children[0]?.["Child One"]?.firstChildFirstName,
    firstChildSurName:
      children && children[0]?.["Child One"]?.firstChildSurName,
    firstChildGender: children && children[0]?.["Child One"]?.firstChildGender,
    firstChildLivingWith:
      children && children[0]?.["Child One"]?.firstChildLivingWith,
    firstChildDateOfBirth:
      children && children[0]?.["Child One"]?.firstChildDateOfBirth,
    isfirstChildHaveSpecialNeeds:
      children &&
      (children[0]?.["Child One"]?.isfirstChildHaveSpecialNeeds === "No"
        ? children[0]["Child One"]?.isfirstChildHaveSpecialNeeds
        : `${children[0]["Child One"]?.isfirstChildHaveSpecialNeeds} (${children[0]["Child One"]?.firstchildSpecialNeeds})`),
    firstChildResponsibility:
      children && children[0]?.["Child One"]?.firstChildResponsibility,
    secondChildCheck: children && children[0]?.["Child One"]?.secondChildCheck,
  });
  const [secondChild, setSecondChild] = useState({
    secondChildFirstName:
      children && children[1]?.["Child Two"]?.secondChildFirstName,
    secondChildSurName:
      children && children[1]?.["Child Two"]?.secondChildSurName,
    secondChildGender:
      children && children[1]?.["Child Two"]?.secondChildGender,
    secondChildLivingWith:
      children && children[1]?.["Child Two"]?.secondChildLivingWith,
    secondChildDateOfBirth:
      children && children[1]?.["Child Two"]?.secondChildDateOfBirth,
    issecondChildHaveSpecialNeeds:
      children &&
      (children[1]?.["Child Two"]?.issecondChildHaveSpecialNeeds === "No"
        ? children[1]?.["Child Two"]?.issecondChildHaveSpecialNeeds
        : `${children[1]?.["Child Two"]?.issecondChildHaveSpecialNeeds} (${children[1]?.["Child Two"]?.secondChildSpecialNeeds})`),
    secondChildResponsibility:
      children && children[0]?.["Child One"]?.secondChildResponsibility,
    thirdChildCheck: children && children[1]?.["Child Two"]?.thirdChildCheck,
  });

  const [thirdChild, setThirdChild] = useState({
    thirdChildFirstName:
      children && children[2]?.["Child Three"]?.thirdChildFirstName,
    thirdChildSurName:
      children && children[2]?.["Child Three"]?.thirdChildSurName,
    thirdChildGender:
      children && children[2]?.["Child Three"]?.thirdChildGender,
    thirdChildLivingWith:
      children && children[2]?.["Child Three"]?.thirdChildLivingWith,
    thirdChildDateOfBirth:
      children && children[2]?.["Child Three"]?.thirdChildDateOfBirth,
    isthirdChildHaveSpecialNeeds:
      children &&
      (children[2]?.["Child Three"]?.isthirdChildHaveSpecialNeeds === "No"
        ? children[2]?.["Child Three"]?.isthirdChildHaveSpecialNeeds
        : `${children[2]?.["Child Three"]?.isthirdChildHaveSpecialNeeds} (${children[2]?.["Child Three"]?.thirdChildSpecialNeeds})`),
    thirdChildResponsibility:
      children && children[0]?.["Child One"]?.thirdChildResponsibility,
    fourthChildCheck:
      children && children[2]?.["Child Three"]?.fourthChildCheck,
  });

  const [fourthChild, setFourthChild] = useState({
    fourthChildFirstName:
      children && children[3]?.["Child Four"]?.childFirstName,
    fourthChildSurName: children && children[3]?.["Child Four"]?.childSurName,
    fourthChildGender: children && children[3]?.["Child Four"]?.childGender,
    fourthChildLivingWith:
      children && children[3]?.["Child Four"]?.childLivingWith,
    fourthChildDateOfBirth:
      children && children[3]?.["Child Four"]?.childDateOfBirth,
    isfourthChildHaveSpecialNeeds:
      children &&
      (children[3]?.["Child Four"]?.isChildHaveSpecialNeeds === "No"
        ? children[3]?.["Child Four"]?.isChildHaveSpecialNeeds
        : `${children[3]?.["Child Four"]?.isChildHaveSpecialNeeds} (${children[3]?.["Child Four"]?.childSpecialNeeds})`),
    fourthChildResponsibility:
      children && children[0]?.["Child One"]?.fourthChildResponsibility,
    fifthChildCheck: children && children[3]?.["Child Four"]?.fifthChildCheck,
  });

  const [fifthChild, setFifthChild] = useState({
    fifthChildFirstName:
      children && children[4]?.["Child Five"]?.fifthChildFirstName,
    fifthChildSurName:
      children && children[4]?.["Child Five"]?.fifthChildSurName,
    fifthChildGender: children && children[4]?.["Child Five"]?.fifthChildGender,
    fifthChildLivingWith:
      children && children[4]?.["Child Five"]?.fifthChildLivingWith,
    fifthChildDateOfBirth:
      children && children[4]?.["Child Five"]?.fifthChildDateOfBirth,
    isfifthChildHaveSpecialNeeds:
      children &&
      (children[4]?.["Child Five"]?.isfifthChildHaveSpecialNeeds === "No"
        ? children[4]?.["Child Five"]?.isfifthChildHaveSpecialNeeds
        : `${children[4]?.["Child Five"]?.isfifthChildHaveSpecialNeeds} (${children[4]?.["Child Five"]?.fifthChildSpecialNeeds})`),
    fifthChildResponsibility:
      children && children[0]?.["Child One"]?.fifthChildResponsibility,
    sixthChildCheck: children && children[4]?.["Child Five"]?.sixthChildCheck,
  });

  const [sixthChild, setSixthChild] = useState({
    sixthChildFirstName:
      children && children[5]?.["Child Six"]?.sixthChildFirstName,
    sixthChildSurName:
      children && children[5]?.["Child Six"]?.sixthChildSurName,
    sixthChildGender: children && children[5]?.["Child Six"]?.sixthChildGender,
    sixthChildLivingWith:
      children && children[5]?.["Child Six"]?.sixthChildLivingWith,
    sixthChildDateOfBirth:
      children && children[5]?.["Child Six"]?.sixthChildDateOfBirth,
    issixthChildHaveSpecialNeeds:
      children &&
      (children[5]?.["Child Six"]?.issixthChildHaveSpecialNeeds === "No"
        ? children[5]?.["Child Six"]?.issixthChildHaveSpecialNeeds
        : `${children[5]?.["Child Six"]?.issixthChildHaveSpecialNeeds} (${children[5]?.["Child Six"]?.sixthChildSpecialNeeds})`),
    sixthChildResponsibility:
      children && children[0]?.["Child One"]?.sixthChildResponsibility,
  });

  const submit = () => {
    setLoading(true);
    const patchEditMIAMOne = {
      personalContactAndCaseInfo: personalContactAndCaseInfo,
      otherParty: otherParty,
      previousRelationshipDetails: previousRelationshipDetails,
      courtProceedings: courtProceedings,
      yourSolicitorOrMcKenzieFriend: yourSolicitorOrMcKenzieFriend,
      children: [],
    };

    if (children.length !== 0) {
      patchEditMIAMOne.children.push({ "Child One": child });
    }
    if (data?.children[0]?.["Child One"].secondChildCheck === "Yes") {
      patchEditMIAMOne.children.push({ "Child Two": secondChild });
    }
    if (data?.children[1]?.["Child Two"].thirdChildCheck === "Yes") {
      patchEditMIAMOne.children.push({ "Child Three": thirdChild });
    }
    if (data?.children[2]?.["Child Three"].fourthChildCheck === "Yes") {
      patchEditMIAMOne.children.push({ "Child Four": fourthChild });
    }
    if (data?.children[3]?.["Child Four"].fifthChildCheck === "Yes") {
      patchEditMIAMOne.children.push({ "Child Five": fifthChild });
    }
    if (data?.children[4]?.["Child Five"].sixthChildCheck === "Yes") {
      patchEditMIAMOne.children.push({ "Child Six": sixthChild });
    }
    const path = location.pathname.match(regex)[0];

    if (path === "/edit/C1") {
      dispatch(updateC1M1({ patchEditMIAMOne, id })).then((res) => {
        if (res) {
          setLoading(false);
        }
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.res,
          life: 1500,
        });
        setTimeout(() => {
          navigate(`/case/maim1/${id}`);
        }, 1600);
      });
    } else {
      dispatch(updateC2M1({ patchEditMIAMOne, id })).then((res) => {
        if (res) {
          setLoading(false);
        }
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.res,
          life: 1500,
        });

        setTimeout(() => {
          navigate(`/case/MIAM1/${id}`);
        }, 1600);
      });
    }
  };

  const handleClick = (e, idx) => {
    switch (idx) {
      case 0:
        let childObject = child;
        childObject[e.target.name] = e.target.value;
        setChild(childObject);
        break;
      case 1:
        let secondChildObject = secondChild;
        secondChildObject[e.target.name] = e.target.value;
        setSecondChild(secondChildObject);
        break;
      case 2:
        let thirdChildObject = thirdChild;
        thirdChildObject[e.target.name] = e.target.value;
        setThirdChild(thirdChildObject);
        break;
      case 3:
        let fourthChildObject = fourthChild;
        fourthChildObject[e.target.name] = e.target.value;
        setFourthChild(fourthChildObject);
        break;
      case 4:
        let fifthChildObject = fifthChild;
        fifthChildObject[e.target.name] = e.target.value;
        setFifthChild(fifthChildObject);
        break;
      case 5:
        let sixthChildObject = sixthChild;
        sixthChildObject[e.target.name] = e.target.value;
        setSixthChild(sixthChildObject);
        break;
      default:
        break;
    }
    let myUser = { ...personalContactAndCaseInfo };
    myUser[e.target.name] = e.target.value;
    setPersonalContactAndCaseInfo(myUser);
    let otherPartyObject = { ...otherParty };
    otherPartyObject[e.target.name] = e.target.value;
    setOtherParty(otherPartyObject);
    let previousRelationshipDetailsObject = { ...previousRelationshipDetails };
    previousRelationshipDetailsObject[e.target.name] = e.target.value;
    setPreviousRelationshipDetails(previousRelationshipDetailsObject);
    let courtProceedingsObject = { ...courtProceedings };
    courtProceedingsObject[e.target.name] = e.target.value;
    setCourtProceedings(courtProceedingsObject);

    let yourSolicitorOrMcKenzieFriendObject = {
      ...yourSolicitorOrMcKenzieFriend,
    };
    yourSolicitorOrMcKenzieFriendObject[e.target.name] = e.target.value;
    setYourSolicitorOrMcKenzieFriend(yourSolicitorOrMcKenzieFriendObject);
  };
  return (
    <>
      <Toast ref={toast} />
      <section>
        <div className="container mt-5">
          <div className="row mt-5 gy-3">
            <div className="col-md-12  d-flex justify-content-center align-items-center">
              <div className={`${style.backEditBTN}`}>
                <Link
                  className={` text-white text-decoration-none `}
                  to={{
                    pathname: path.includes("/edit/C1")
                      ? `/case/maim1/${id}`
                      : `/case/MIAM1/${id}`,
                  }}
                >
                  <div
                    className=" rounded"
                    style={{ backgroundColor: "#0337a4" }}
                  >
                    <TiArrowBack className="fs-3" />{" "}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        paddingTop: "20px",
                      }}
                    >
                      Back
                    </span>
                  </div>
                </Link>{" "}
              </div>
              <div>
                <p className={`${style.titleMiam} py-2 mt-5`}>
                  {path === "/edit/C1"
                    ? "Edit C1 MIAM Part 1"
                    : "Edit C2 MIAM Part 1"}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`ms-2`}>
                <Table className={`${style.table}`}>
                  <tbody>
                    <tr>
                      <td className={`${style.title} ps-4`}>
                        Personal Details
                      </td>
                    </tr>
                    {data?.personalContactAndCaseInfo?.firstName && (
                      <tr>
                        <td className={`ps-4`}>
                          <span className={style.font_weight}>
                            First Name :{" "}
                          </span>

                          <input
                            type="text"
                            className={`${style.edit_input}  p-1 rounded border-0`}
                            name="firstName"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.firstName
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.surName && (
                      <tr>
                        <td className="ps-4">
                          Surname :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="surName"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.surName
                            }
                          />
                        </td>
                      </tr>
                    )}

                    {data?.personalContactAndCaseInfo?.dateOfBirth && (
                      <tr>
                        <td className="ps-4">
                          Date of birth :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="dateOfBirth"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.dateOfBirth
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.phoneNumber && (
                      <tr>
                        <td className="ps-4">
                          Telephone :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="phoneNumber"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo.phoneNumber
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.email && (
                      <tr>
                        <td className="ps-4">
                          Email :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="email"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.email
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.street && (
                      <tr>
                        <td className="ps-4">
                          Street :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="street"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.street
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.city && (
                      <tr>
                        <td className="ps-4">
                          City :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="city"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.city
                            }
                          />
                        </td>
                      </tr>
                    )}

                    {data?.personalContactAndCaseInfo?.country && (
                      <tr>
                        <td className="ps-4">
                          Country :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="country"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.country
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.postCode && (
                      <tr>
                        <td className="ps-4">
                          Postal Code :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="postCode"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.postCode
                            }
                          />
                        </td>
                      </tr>
                    )}

                    {data?.personalContactAndCaseInfo?.gender && (
                      <tr>
                        <td className="ps-4">
                          Gender :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="gender"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.gender
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.doesOtherPartyKnow && (
                      <tr>
                        <td className="ps-4">
                          Other participant know contact details :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="doesOtherPartyKnow"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.doesOtherPartyKnow
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo
                      ?.makeDetailsConfidential && (
                      <tr>
                        <td className="ps-4">
                          Keep details confidential :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="makeDetailsConfidential"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.makeDetailsConfidential
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.disabilityRegistered && (
                      <tr>
                        <td className="ps-4">
                          Registered disability :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="disabilityRegistered"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.disabilityRegistered
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo
                      ?.isWillingToTryMediation && (
                      <tr>
                        <td className="ps-4">
                          Willing to try mediation :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="isWillingToTryMediation"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.isWillingToTryMediation
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo
                      ?.areChangesToServicesRequired && (
                      <tr>
                        <td className="ps-4">
                          Make any changes to our services :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="areChangesToServicesRequired"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.areChangesToServicesRequired
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.changesRequired && (
                      <tr>
                        <td className="ps-4">
                          Do to assist :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="changesRequired"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.changesRequired
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo
                      ?.willSupporterAttendMediation && (
                      <tr>
                        <td className="ps-4">
                          Bring support to mediation :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="willSupporterAttendMediation"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.willSupporterAttendMediation
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo
                      ?.supporterNameAndRelation && (
                      <tr>
                        <td className="ps-4">
                          Supporters Names :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="supporterNameAndRelation"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.supporterNameAndRelation
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.ethnicOrigin && (
                      <tr>
                        <td className="ps-4">
                          Ethnic origin :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="ethnicOrigin"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.ethnicOrigin
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.hasBritishPassport && (
                      <tr>
                        <td className="ps-4">
                          Have B.nationality/passport/P.R :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="hasBritishPassport"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.hasBritishPassport
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.immigrationStatus && (
                      <tr>
                        <td className="ps-4">
                          Immigration status in UK :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="immigrationStatus"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.immigrationStatus
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.howClientFoundDMS && (
                      <tr>
                        <td className="ps-4">
                          Found out about our services :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="howClientFoundDMS"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.howClientFoundDMS
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.facedIssue && (
                      <tr>
                        <td className="ps-4">
                          Issues that facing the client :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="facedIssue"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.facedIssue
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo
                      ?.isThereDaysCanNotAttend && (
                      <tr>
                        <td className="ps-4">
                          Day/Dates that cannot make :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="isThereDaysCanNotAttend"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.isThereDaysCanNotAttend
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.appointmentTime && (
                      <tr>
                        <td className="ps-4">
                          Appointment time :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="appointmentTime"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.appointmentTime
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.courtProceedings?.maritalStatus && (
                      <tr>
                        <td className="ps-4">
                          Marital status :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="maritalStatus"
                            onChange={handleClick}
                            defaultValue={data?.courtProceedings?.maritalStatus}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.courtProceedings?.maritalStatus && (
                      <tr>
                        <td className="ps-4">
                          Marital status :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="maritalStatus"
                            onChange={handleClick}
                            defaultValue={data?.courtProceedings?.maritalStatus}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.isClientVulnerable && (
                      <tr>
                        <td className="ps-4">
                          Vulnerable adult :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="isClientVulnerable"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo
                                ?.isClientVulnerable
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.courtProceedings?.isFacingLegalProceedings && (
                      <tr>
                        <td className="ps-4">
                          Aware of any legal proceedings:{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="isFacingLegalProceedings"
                            onChange={handleClick}
                            defaultValue={
                              data?.courtProceedings?.isFacingLegalProceedings
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.courtProceedings?.courtApplicationKnown && (
                      <tr>
                        <td className="ps-4">
                          Any court applications :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="courtApplicationKnown"
                            onChange={handleClick}
                            defaultValue={
                              data?.courtProceedings?.courtApplicationKnown
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo && (
                      <tr>
                        <td className="ps-4">
                          Confirm that he/her read care letter :{" "}
                          <span className="ps-2">Yes</span>
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo && (
                      <tr>
                        <td className="ps-4">
                          Confirm that he/her watch the MIAM video :{" "}
                          <span className="ps-2">Yes</span>
                        </td>
                      </tr>
                    )}
                    {data?.previousRelationshipDetails && (
                      <tr>
                        <td className={`${style.title} ps-4`}>
                          Previous relationship's details
                        </td>
                      </tr>
                    )}
                    {data?.previousRelationshipDetails?.separationDate && (
                      <tr>
                        <td className={`ps-4`}>
                          Separate Date :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="separationDate"
                            onChange={handleClick}
                            defaultValue={
                              data?.previousRelationshipDetails?.separationDate
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.previousRelationshipDetails?.relationshipPeriod && (
                      <tr>
                        <td className={`ps-4`}>
                          Relationship Length :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="relationshipPeriod"
                            onChange={handleClick}
                            defaultValue={
                              data?.previousRelationshipDetails
                                ?.relationshipPeriod
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.previousRelationshipDetails?.isMarried && (
                      <tr>
                        <td className="ps-4">
                          Married or in a civil partnership to your ex-partner :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="isMarried"
                            onChange={handleClick}
                            defaultValue={
                              data?.previousRelationshipDetails?.isMarried
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.previousRelationshipDetails?.marriageDate && (
                      <tr>
                        <td className="ps-4">
                          Marriage/Civil partnership Date :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="marriageDat"
                            onChange={handleClick}
                            defaultValue={
                              data?.previousRelationshipDetails?.marriageDate
                            }
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`ms-2`}>
                <Table className={`${style.table} `}>
                  <tbody className="tbody">
                    {data?.otherParty && (
                      <tr>
                        <td className={`${style.title} ps-4`}>
                          Other Person's Details
                        </td>
                      </tr>
                    )}
                    {data?.personalContactAndCaseInfo?.otherParty && (
                      <tr>
                        <td className={`ps-4`}>
                          Other party in the conflict :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="otherParty"
                            onChange={handleClick}
                            defaultValue={
                              data?.personalContactAndCaseInfo?.otherParty
                            }
                          />
                        </td>
                      </tr>
                    )}

                    {data?.otherParty?.otherPartyFirstName && (
                      <tr>
                        <td className={`ps-4`}>
                          First Name :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="otherPartyFirstName"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartyFirstName}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartySurname && (
                      <tr>
                        <td className="ps-4">
                          Surname :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="otherPartySurname"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartySurname}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartyDateOfBirth && (
                      <tr>
                        <td className="ps-4">
                          Date of birth :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="otherPartyDateOfBirth"
                            onChange={handleClick}
                            defaultValue={
                              data?.otherParty?.otherPartyDateOfBirth
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartyPhone && (
                      <tr>
                        <td className="ps-4">
                          Telephone :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="otherPartyPhone"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartyPhone}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartyEmail && (
                      <tr>
                        <td className="ps-4">
                          Email :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="otherPartyEmail"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartyEmail}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartyStreet && (
                      <tr>
                        <td className="ps-4">
                          Street :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="otherPartyStreet"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartyStreet}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartyCity && (
                      <tr>
                        <td className="ps-4">
                          City :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="otherPartyCity"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartyCity}
                          />
                        </td>
                      </tr>
                    )}

                    {data?.otherParty?.otherPartyCountry && (
                      <tr>
                        <td className="ps-4">
                          Country :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="otherPartyCountry"
                            onChange={handleClick}
                            defaultValue={data?.otherParty?.otherPartyCountry}
                          />
                        </td>
                      </tr>
                    )}
                    {data?.otherParty?.otherPartyPostalCode && (
                      <tr>
                        <td className="ps-4">
                          Postal Code :{" "}
                          <input
                            type="text"
                            className="p-1 rounded border-0"
                            name="otherPartyPostalCode"
                            onChange={handleClick}
                            defaultValue={
                              data?.otherParty?.otherPartyPostalCode
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend && (
                      <tr>
                        <td className={`${style.title} ps-4`}>
                          Solicitor/Mckenzie friend's Details
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend?.solicitorCheck && (
                      <tr>
                        <td className={`ps-4`}>
                          Do you have a solicitor/McKenzie friend :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="solicitorCheck"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend
                                ?.solicitorCheck
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend?.solicitorName && (
                      <tr>
                        <td className={`ps-4`}>
                          Name :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="solicitorName"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend?.solicitorName
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend
                      ?.solicitorLawFirmName && (
                      <tr>
                        <td className="ps-4">
                          Name of the law firm :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="solicitorLawFirmName"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend
                                ?.solicitorLawFirmName
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend
                      ?.solicitorTelephone && (
                      <tr>
                        <td className="ps-4">
                          Telephone :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="solicitorTelephone"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend
                                ?.solicitorTelephone
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend?.solicitorEmail && (
                      <tr>
                        <td className="ps-4">
                          Email :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="solicitorEmail"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend.solicitorEmail
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend
                      ?.sendMediationCertificateToSolicitor && (
                      <tr>
                        <td className="ps-4">
                          Send mediation certificate to friend :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="sendMediationCertificateToSolicitor"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend
                                ?.sendMediationCertificateToSolicitor
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {data?.yourSolicitorOrMcKenzieFriend
                      ?.consultationRegardingLegalSupport && (
                      <tr>
                        <td className={`ps-4`}>
                          Free, no-obligation consultation :{" "}
                          <input
                            type="text"
                            className="  p-1 rounded border-0"
                            name="consultationRegardingLegalSupport"
                            onChange={handleClick}
                            defaultValue={
                              data?.yourSolicitorOrMcKenzieFriend
                                ?.consultationRegardingLegalSupport
                            }
                          />
                        </td>
                      </tr>
                    )}

                    {/* edit children */}

                    <div>
                      {children?.map((value, idx) => {
                        let number;
                        switch (idx) {
                          case 0:
                            number = "first";
                            break;
                          case 1:
                            number = "second";
                            break;
                          case 2:
                            number = "third";
                            break;
                          case 3:
                            number = "fourth";
                            break;
                          case 4:
                            number = "fifth";
                            break;
                          case 5:
                            number = "sixth";
                            break;
                          default:
                            break;
                        }
                        let childFirstName;
                        let childSurName;
                        let childDateOfBirth;
                        let childGender;
                        let childLivingWith;
                        let isChildHaveSpecialNeeds;
                        let childSpecialNeeds;
                        let childResponsibility;
                        switch (idx) {
                          case 0:
                            childFirstName = `firstChildFirstName`;
                            childSurName = `firstChildSurName`;
                            childDateOfBirth = `firstChildDateOfBirth`;
                            childGender = `firstChildGender`;
                            childLivingWith = `firstChildLivingWith`;
                            isChildHaveSpecialNeeds = `isfirstChildHaveSpecialNeeds`;
                            childSpecialNeeds = `firstChildSpecialNeeds`;
                            childResponsibility = `firstChildResponsibility`;
                            break;
                          case 1:
                            childFirstName = `secondChildFirstName`;
                            childSurName = `secondChildSurName`;
                            childDateOfBirth = `secondChildDateOfBirth`;
                            childGender = `secondChildGender`;
                            childLivingWith = `secondChildLivingWith`;
                            isChildHaveSpecialNeeds = `issecondChildHaveSpecialNeeds`;
                            childSpecialNeeds = `secondChildSpecialNeeds`;
                            childResponsibility = `secondChildResponsibility`;
                            break;
                          case 2:
                            childFirstName = `thirdChildFirstName`;
                            childSurName = `thirdChildSurName`;
                            childDateOfBirth = `thirdChildDateOfBirth`;
                            childGender = `thirdChildGender`;
                            childLivingWith = `thirdChildLivingWith`;
                            isChildHaveSpecialNeeds = `isthirdChildHaveSpecialNeeds`;
                            childSpecialNeeds = `thirdChildSpecialNeeds`;
                            childResponsibility = `thirdChildResponsibility`;
                            break;
                          case 3:
                            childFirstName = `fourthChildFirstName`;
                            childSurName = `fourthChildSurName`;
                            childDateOfBirth = `fourthChildDateOfBirth`;
                            childGender = `fourthChildGender`;
                            childLivingWith = `fourthChildLivingWith`;
                            isChildHaveSpecialNeeds = `isfourthChildHaveSpecialNeeds`;
                            childSpecialNeeds = `fourthChildSpecialNeeds`;
                            childResponsibility = `fourthChildResponsibility`;
                            break;
                          case 4:
                            childFirstName = `fifthChildFirstName`;
                            childSurName = `fifthChildSurName`;
                            childDateOfBirth = `fifthChildDateOfBirth`;
                            childGender = `fifthChildGender`;
                            childLivingWith = `fifthChildLivingWith`;
                            isChildHaveSpecialNeeds = `isfifthChildHaveSpecialNeeds`;
                            childSpecialNeeds = `fifthChildSpecialNeeds`;
                            childResponsibility = `fifthChildResponsibility`;
                            break;
                          case 5:
                            childFirstName = `sixthChildFirstName`;
                            childSurName = `sixthChildSurName`;
                            childDateOfBirth = `sixthChildDateOfBirth`;
                            childGender = `sixthChildGender`;
                            childLivingWith = `sixthChildLivingWith`;
                            isChildHaveSpecialNeeds = `issixthChildHaveSpecialNeeds`;
                            childSpecialNeeds = `sixthChildSpecialNeeds`;
                            childResponsibility = `sixthChildResponsibility`;
                            break;
                          default:
                            break;
                        }
                        return (
                          <Table className={`${style.table} `} key={idx}>
                            <tbody>
                              {children && (
                                <tr>
                                  <td className={`${style.title} ps-4`}>
                                    {Object.entries(value)[0][0]}'s Details
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][`${childFirstName}`]
                              }` && (
                                <tr>
                                  <td className={`ps-4`}>
                                    First Name :{" "}
                                    <input
                                      type="text"
                                      className="p-1 rounded border-0"
                                      name={`${number}ChildFirstName`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={`${
                                        Object.entries(value)[0][1][
                                          `${childFirstName}`
                                        ]
                                      }`}
                                    />
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][`${childSurName}`]
                              }` && (
                                <tr>
                                  <td className="ps-4">
                                    Surname :{" "}
                                    <input
                                      type="text"
                                      className="  p-1 rounded border-0"
                                      name={`${number}ChildSurName`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={`${
                                        Object.entries(value)[0][1][
                                          `${childSurName}`
                                        ]
                                      }`}
                                    />
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][
                                  `${childDateOfBirth}`
                                ]
                              }` && (
                                <tr>
                                  <td className="ps-4">
                                    Date of birth :
                                    <input
                                      type="text"
                                      className="  p-1 rounded border-0"
                                      name={`${number}ChildDateOfBirth`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={`${
                                        Object.entries(value)[0][1][
                                          `${childDateOfBirth}`
                                        ]
                                      }`}
                                    />
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][`${childGender}`]
                              }` && (
                                <tr>
                                  <td className="ps-4">
                                    Gender :
                                    <input
                                      type="text"
                                      className="  p-1 rounded border-0"
                                      name={`${number}ChildGender`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={`${
                                        Object.entries(value)[0][1][
                                          `${childGender}`
                                        ]
                                      }`}
                                    />
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][
                                  `${childLivingWith}`
                                ]
                              }` && (
                                <tr>
                                  <td className="ps-4">
                                    Living mostly with :
                                    <input
                                      type="text"
                                      className="  p-1 rounded border-0"
                                      name={`${number}ChildLivingWith`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={`${
                                        Object.entries(value)[0][1][
                                          `${childLivingWith}`
                                        ]
                                      }`}
                                    />
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][
                                  `${isChildHaveSpecialNeeds}`
                                ]
                              }` && (
                                <tr>
                                  <td className="ps-4">
                                    Special needs/health issues :
                                    <input
                                      type="text"
                                      className="  p-1 rounded border-0"
                                      name={`is${number}ChildHaveSpecialNeeds`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={
                                        `${
                                          Object.entries(value)[0][1][
                                            `${isChildHaveSpecialNeeds}`
                                          ]
                                        }` === "Yes"
                                          ? "Yes" +
                                            " ( " +
                                            `${
                                              Object.entries(value)[0][1][
                                                `${childSpecialNeeds}`
                                              ]
                                            }` +
                                            " )"
                                          : "No"
                                      }
                                    />
                                  </td>
                                </tr>
                              )}
                              {`${
                                Object.entries(value)[0][1][
                                  `${childResponsibility}`
                                ]
                              }` && (
                                <tr>
                                  <td className="ps-4">
                                    Parental responsibility of the child :
                                    <input
                                      type="text"
                                      className="  p-1 rounded border-0"
                                      name={`${number}ChildResponsibility`}
                                      onChange={(e) => handleClick(e, idx)}
                                      defaultValue={`${
                                        Object.entries(value)[0][1][
                                          `${childResponsibility}`
                                        ]
                                      }`}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        );
                      })}
                    </div>
                  </tbody>
                </Table>
              </div>
            </div>
            <button
              onClick={submit}
              className="w-50 mt-5 mb-2 fs-5 m-auto btn btn-info py-2 border-0"
              style={{ backgroundColor: "#040848", color: "white" }}
            >
              {loading ? <Spinner animation="border" /> : "Confirm"}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditMIAM1Part1;
