import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useGetUserDetailsQuery } from "../../services/auth/authService";

const AddMediatorProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);

  const { data, isFetching } = useGetUserDetailsQuery("userDetails", {
    refetchOnMountOrArgChange: true,
  });

  // show unauthorized screen if no user is found in redux store
  if (!token) {
    return (
      <div>
        <h1>Unauthorized :</h1>
        <span>
          <NavLink to="/login">Login</NavLink> to gain access
        </span>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div>
        <Spinner animation="border" />
      </div>
    );
  } else {
    if (data && data?.role === "mediator") {
      return <h1>You No Access as mediator</h1>;
    }
  }

  // returns child route elements
  return <Outlet />;
};
export default AddMediatorProtectedRoute;
