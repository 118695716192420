import React from 'react'
import AdminLoginD from '../components/AdminLogin/AdminLoginD'

export const AdminLogin = () => {
  return (
    <>
      <AdminLoginD/>
    </>
  
  )
}
