import { useEffect, useState } from "react";
import { Container, Spinner, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import { loginCompany, loginMediator } from "../features/auth/authActions";
import FormCard from "../components/FormCard";
import Error from "../components/Error";

const LoginForm = () => {
  const { loading, token, error } = useSelector((state) => state.auth);
  const [passwordValue, setpasswordValue] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  const [rolePath, setRolePath] = useState("");

  const handleRole = (e) => {
    if (e.target.value === "mediator") {
      setRolePath("/mediator-login");
    } else if (e.target.value === "company") {
      setRolePath("/company-login");
    }
  };

  const onSubmit = (data) => {
    const email = data.email;
    const password = passwordValue;

    if (passwordValue?.length === 0) {
      setPasswordError("Password is required");
    }
    if (rolePath === "/mediator-login") {
      if (passwordValue?.length !== 0) {
        dispatch(loginMediator({ email, password }));
      }
    } else if (rolePath === "/company-login") {
      if (passwordValue?.length !== 0) {
        dispatch(loginCompany({ email, password }));
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <FormCard />
        <Col lg={8} className="p-0 mt-5">
          <Container className="card-container-form ">
            <Container fluid className="form-container-authentication">
              <Col lg={6}>
                <h1 className="form-header-authentication">
                  Login to your dashboard
                </h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label className="form-label">Email address:</label>
                  <input
                    className="form-input bg-transparent"
                    type="email"
                    data-test-mediator-email="name"
                    placeholder="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <p className="errorMsg">Email is required</p>
                  )}
                  <label className="form-label">Password:</label>
                  <Password
                    className="form-input AvailabilityForm ps-0"
                    placeholder="Password"
                    toggleMask
                    feedback={false}
                    value={passwordValue}
                    onChange={(e) => setpasswordValue(e.target.value)}
                  />
                  {passwordValue?.length === 0 && (
                    <small className="text-danger">
                      <span>{PasswordError}</span>
                    </small>
                  )}

                  <label className="form-label">Role:</label>
                  <div className="radio-style">
                    <label className="form-radio">
                      <input
                        className="form-radio"
                        type="radio"
                        value="company"
                        {...register("role", { required: true })}
                        onClick={handleRole}
                      />
                      Company
                    </label>
                    <label className="form-radio">
                      <input
                        className="form-radio"
                        type="radio"
                        value="mediator"
                        {...register("role", { required: true })}
                        onClick={handleRole}
                      />
                      Mediator
                    </label>
                  </div>
                  {errors.role && errors.role.type === "required" && (
                    <p className="errorMsg">Role is required</p>
                  )}
                  {error && <Error>{error}</Error>}
                  <button className="login-button py-2 mt-1" type="submit">
                    {loading ? <Spinner animation="border" /> : "Login"}
                  </button>
                  <Link className="mt-3" to="/forget-password">
                    Forget Password?
                  </Link>
                </form>
              </Col>
            </Container>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
