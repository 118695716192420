import React, { useRef, useState } from "react";
import styles from "./Case.module.css";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { sendAgreementForm } from "../../features/auth/authActions";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

const SendAgreementToMediate = ({ sendAgreement, close }) => {
  const toast = useRef(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setIsLoading(true);
    let targetClient=data.client
    dispatch(sendAgreementForm({ id ,targetClient})).then((res) => {
      if (res.payload.status === 200) {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.meesage,
          life: 1500,
        });
      } else {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 1500,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={sendAgreement}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader} pb-0`}>
            <h4 className="text-center pt-4">Send agreement to mediate</h4>
            <Button
              onClick={close}
              type="button"
              className={`bg-transparent border-0`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Select Client:</label>
            <select
              className="select-input"
              {...register("client", { required: "select client is required" })}
            >
              <option value="" disabled>
                Select Client
              </option>
              <option>C1</option>
              <option>C2</option>
            </select>

            <small className="text-danger">
              {errors?.client && errors.client.message}
            </small>
            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "Send"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default SendAgreementToMediate;
