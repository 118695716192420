import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateCaseLog } from "../../features/auth/authActions";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

const EditCaseLogBody = ({
  showEditCaseBodyLog,
  close,
  CaseData,
  idd,
  refetch,
}) => {
  const x = CaseData?.caseLogs.find((y) => y._id === idd);
  const [valueOfCaseLog, setvalueOfCaseLog] = useState("");
  const [errorOnSubmit, seterrorOnSubmit] = useState("");

  useEffect(() => {
    setvalueOfCaseLog(x?.logBody);
  }, [x]);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { id } = useParams();
  const onSubmit = (e) => {
    e.preventDefault();
    const _id = idd;
    const logBody = valueOfCaseLog;
    setIsLoading(true);
    if (valueOfCaseLog?.length === 0) {
      setIsLoading(false);
      seterrorOnSubmit("This field is required");
    }
    if (valueOfCaseLog !== "") {
      dispatch(updateCaseLog({ _id, logBody, id })).then((res) => {
        if (res.payload.status === 200) {
          setIsLoading(false);
          close();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.payload.data.res,
            life: 1500,
          });
        }
      });
      refetch();
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={showEditCaseBodyLog}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0 d-flex justify-content-end">
          <div className={`  pb-0  `}>
            {/* <h4 className="text-center pt-4">Send Court Form</h4> */}
            <Button
              onClick={close}
              type="button"
              className={`bg-transparent border-0`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={onSubmit}>
            <label className="form-label">Edit Note:</label>
            <textarea
              value={valueOfCaseLog}
              onChange={(e) => setvalueOfCaseLog(e.target.value)}
              rows={5}
              className="h-100 select-input  mt-3"
              type="text"
            ></textarea>
            {valueOfCaseLog === "" ? (
              <small className="text-danger">
                <span>{errorOnSubmit}</span>
              </small>
            ) : (
              ""
            )}
            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "Submit"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default EditCaseLogBody;
