import { Table } from "react-bootstrap";
import styles from "../casemaim2/Case.module.css";

const TenItemsTable = ({ item, idx }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <p
          className={`py-2 d-flex justify-content-center ${styles.titleOfMIAM} `}
        >
          Session Number {idx + 1}
        </p>
      </div>
      <div className="d-flex justify-content-center  flex-wrap ">
        <Table className={`${styles.tableCase} `}>
          <tbody>
            <tr>
              <td className={`${styles.left_td} ps-4`}>C1 full name : </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">
                  {item?.clientData?.clientOneFullName}
                </span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>C2 full name : </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">
                  {item?.clientData?.clientTwoFullName}
                </span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>
                Date of the session :{" "}
              </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">{item?.clientData?.sessionDate}</span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>Mediator's name : </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">{item?.clientData?.mediatorName}</span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>Type of case : </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">{item?.clientData?.caseType}</span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>
                Number of the session :{" "}
              </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">
                  {item?.clientData?.mediationSessionNumber}
                </span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>
                Length of the session :{" "}
              </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">{item?.clientData?.sessionLength}</span>
              </td>
            </tr>
            <tr>
              <td className={`${styles.left_td} ps-4`}>Type of mediation : </td>
              <td className={`${styles.right_td} ps-4`}>
                <span className="ps-2">
                  {item?.recordOfMattersDiscussed?.mediationType}
                </span>
              </td>
            </tr>
            {item?.NextSteps?.returningToMediationDate && (
              <tr>
                <td className={`${styles.left_td} ps-4`}>
                  Date for the clients to return to mediation :
                </td>
                <td className={`${styles.right_td} ps-4`}>
                  <span className="ps-2">
                    {item?.NextSteps?.returningToMediationDate}
                  </span>
                </td>
              </tr>
            )}
            {item?.NextSteps?.appointmentTime && (
              <tr>
                <td className={`${styles.left_td} ps-4`}>
                  Time for the next mediation session :
                </td>
                <td className={`${styles.right_td} ps-4`}>
                  <span className="ps-2">
                    {item?.NextSteps?.appointmentTime}
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TenItemsTable;
