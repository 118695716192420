// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminAuth = createApi({
  reducerPath: "adminAuth",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.directmediationservices.co.uk/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("adminLoginToken");
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => ({
        url: `/get-companies`,
        method: "GET",
      }),
    }),
    addDrive: builder.query({
      query: ({ id, sharingGmail }) => ({
        url: `/update-gmail/${id}`,
        method: "PATCH",
        body: { sharingGmail },
      }),
    }),
  }),
});

export const { useGetCompaniesQuery, useAddDriveQuery } = adminAuth;
