import React from "react";
import Table from "react-bootstrap/Table";
import style from "../casemaim/Case.module.css";

const FriendDetails = ({ data }) => {
  return (
    <div className={`${style.casew} ms-2`}>
      <Table className={`${style.table} `}>
        <tbody>
          {data?.yourSolicitorOrMcKenzieFriend && (
            <tr>
              <td className={`${style.title} ps-4`}>
                Solicitor/Mckenzie friend's Details
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend?.solicitorCheck && (
            <tr>
              <td className={`ps-4`}>
                Do you have a solicitor/McKenzie friend :{" "}
                <span className="ps-2">
                  {data?.yourSolicitorOrMcKenzieFriend?.solicitorCheck}
                </span>
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend?.solicitorName && (
            <tr>
              <td className={`ps-4`}>
                Name :{" "}
                <span className="ps-2">
                  {data?.yourSolicitorOrMcKenzieFriend?.solicitorName}
                </span>
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend?.solicitorLawFirmName && (
            <tr>
              <td className="ps-4">
                Name of the law firm :{" "}
                <span className="ps-2">
                  {data?.yourSolicitorOrMcKenzieFriend?.solicitorLawFirmName}
                </span>
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend?.solicitorTelephone && (
            <tr>
              <td className="ps-4">
                Telephone :{" "}
                <span className="ps-2">
                  {data?.yourSolicitorOrMcKenzieFriend?.solicitorTelephone}
                </span>
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend?.solicitorEmail && (
            <tr>
              <td className="ps-4">
                Email :{" "}
                <span className="ps-2">
                  {data?.yourSolicitorOrMcKenzieFriend?.solicitorEmail}
                </span>
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend
            ?.sendMediationCertificateToSolicitor && (
            <tr>
              <td className="ps-4">
                Send mediation certificate to friend :{" "}
                <span className="ps-2">
                  {
                    data?.yourSolicitorOrMcKenzieFriend
                      ?.sendMediationCertificateToSolicitor
                  }
                </span>
              </td>
            </tr>
          )}
          {data?.yourSolicitorOrMcKenzieFriend
            ?.consultationRegardingLegalSupport && (
            <tr>
              <td className={`ps-4`}>
                Free, no-obligation consultation :{" "}
                <span className="ps-2">
                  {
                    data?.yourSolicitorOrMcKenzieFriend
                      ?.consultationRegardingLegalSupport
                  }
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default FriendDetails;
