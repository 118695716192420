import React from "react";
import Table from "react-bootstrap/Table";
import style from "../casemaim/Case.module.css";

const PrevRelations = ({ data }) => {
  return (
    <div className={`${style.casew} ms-2`}>
      <Table className={`${style.table} `}>
        <tbody>
          {data?.previousRelationshipDetails && (
            <tr>
              <td className={`${style.title} ps-4`}>
                Previous relationship's details
              </td>
            </tr>
          )}
          {data?.previousRelationshipDetails?.separationDate && (
            <tr>
              <td className={`ps-4`}>
                Separate Date :{" "}
                <span className="ps-2">
                  {data?.previousRelationshipDetails?.separationDate}
                </span>
              </td>
            </tr>
          )}
          {data?.previousRelationshipDetails?.relationshipPeriod && (
            <tr>
              <td className={`ps-4`}>
                Relationship Length :{" "}
                <span className="ps-2">
                  {data?.previousRelationshipDetails?.relationshipPeriod}
                </span>
              </td>
            </tr>
          )}
          {data?.previousRelationshipDetails?.isMarried && (
            <tr>
              <td className="ps-4">
                Married or in a civil partnership to your ex-partner :{" "}
                <span className="ps-2">
                  {data?.previousRelationshipDetails?.isMarried}
                </span>
              </td>
            </tr>
          )}
          {data?.previousRelationshipDetails?.marriageDate && (
            <tr>
              <td className="ps-4">
                Marriage/Civil partnership Date :{" "}
                <span className="ps-2">
                  {data?.previousRelationshipDetails?.marriageDate}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default PrevRelations;
