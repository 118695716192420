import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Case.module.css";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendSMS } from "../../features/auth/authActions";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { HiExternalLink } from "react-icons/hi";

const SmsInfo = ({ ShowSmsModal, close, MIAM1_C1, MIAM1_C2 }) => {
  const { id } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [showTemplate, SetshowTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchLastNameCheck = watch("messageBodyData");

  useEffect(() => {
    SetshowTemplate(watchLastNameCheck);
  }, [watchLastNameCheck]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const modifiedPhoneNumber = data.clientNumber.replace(/C\d+ \| /, "");
    const fullData = {
      clientNumber: modifiedPhoneNumber,
      messageBodyData: showTemplate,
    };
    dispatch(sendSMS(fullData)).then((res) => {
      if (res.payload.status === 200) {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.message,
          life: 1500,
        });
      }else{
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 1500,
        });
      }
    });
  };

  switch (showTemplate) {
    case "Legal Aid Application - Low income - C1":
      SetshowTemplate(
        `Dear [Name],Thank you for contacting us regarding your Legal Aid application for family mediation.To start your application please follow the link below  https://laa-low-income.vercel.app/LOWINCOME_NOINCOME/C1/${id} Applications are only considered via this route.Direct Mediation Services`
      );
      break;
    case "Legal Aid Application - Low income - C2":
      SetshowTemplate(
        `Dear [Name],Thank you for contacting us regarding your Legal Aid application for family mediation.To start your application please follow the link below:https://laa-low-income.vercel.app/LOWINCOME_NOINCOME/C2/${id}Applications are only considered via this route.Direct Mediation Services.`
      );
      break;
    case "Client 1 - MIAM Part 1 - only after client has been successful for Legal Aid funding":
      SetshowTemplate(
        `Dear [Name],Thank you for the signed Legal Aid form. Your application for Legal Aid was SUCCESSFUL. BEFORE booking you for your Mediation Information & Assessment Meeting (MIAM) with one of our family mediators, we need you to complete an online form that records basic information about you and your situation.AFTER you have filled and SUBMITTED this form, a member of our team will get back to you to book your appointment. Please click on the link below: https://direct-mediation-services.vercel.app/MIAM_PART_1/C1/${id}PLEASE REMEMBER THAT WHEN YOU BOOK YOUR APPOINTMENT, IF YOU MISS IT, WE WILL NOT BE ABLE TO BOOK YOU ANOTHER. Direct Mediation Services.`
      );
      break;
    case "Client 1 - MIAM Part 1 - Private clients":
      SetshowTemplate(
        `Dear [Name],BEFORE your Mediation Information & Assessment Meeting (MIAM) with one of our family mediators, we need you to complete an online form that records basic information about you and your situation.Please click on the link below:https://direct-mediation-services.vercel.app/MIAM_PART_1/C1/${id}Direct Mediation Services.`
      );
      break;
    case "Legal Aid Application - Passporting benefits - C1":
      SetshowTemplate(
        `Hi [Name],Thank you for contacting us regarding your Legal Aid application for family mediation.To start your application please follow the link below:https://passport-benefits.vercel.app/PASSPORTING/C1/${id}Applications are only considered via this route. Direct Mediation Services."
      );
      break;
    case "Legal Aid Application - Passporting benefits - C2":
      SetshowTemplate(
        "Hi [Name],Thank you for contacting us regarding your Legal Aid application for family mediation.To start your application please follow the link below:https://passport-benefits.vercel.app/PASSPORTING/C2/${id}Applications are only considered via this route. Direct Mediation Services.`
      );
      break;
    case "C2 call for invitation":
      SetshowTemplate(
        `Dear [Name], I called you but your line was busy. This is about the invitation to family mediation. Please contact me on 0113 468 9593 Regar Dorian DMS`
      );
      break;
    case "Client 2 - Declined invitation - SMS to Client 1":
      SetshowTemplate(
        `Dear [Client 1],[Client 2 full name] has declined the invitation to mediation. In view of that, your mediator has closed your case and issued your court form. This form was sent via email. All the necessary information is in the email. We wish you all the best.Regards Direct Mediation Service`
      );
      break;
    case "Message to invite C2":
      SetshowTemplate(
        `Dear [Name Client 2], You've been sent an invitation to family mediation by email/post. Please call our office on 01134689593. Look forward to hearing from you.`
      );
      break;
    case "C2 MIAM Part 1 - After they have agreed to attend mediation":
      SetshowTemplate(
        `Dear [Client 2],Thank you for responding to the invitation to mediation. BEFORE your Mediation Information & Assessment Meeting (MIAM) with one of our family mediators, we need you to complete an online form that records basic information about you and your situation. Without this form completed, we cannot proceed with the appointment.YOU HAVE A DEADLINE OF THREE WORKING DAYS TO COMPLETE THIS FORM. Please click on the link below: https://direct-mediation-services.vercel.app/MIAM_PART_1/C2/${id}  PLEASE REMEMBER THAT WHEN YOU BOOK YOUR APPOINTMENT, IF YOU MISS IT, WE WILL NOT BE ABLE TO BOOK YOU ANOTHER.Direct Mediation Services`
      );
      break;
    case "Case update":
      SetshowTemplate(
        "Dear [Client]I tried to call you but your line was busy. This is about your family mediation case. Please contact me on 0113 468 9593. Regards, Dorian DMS"
      );
      break;
    case "Court form sent":
      SetshowTemplate(
        "Dear [Client], Your court form has been sent to your email address. We wish you all the best. Regards, DMS"
      );
      break;

    case "Email sent":
      SetshowTemplate(
        "Dear [Client], An email with info about your family mediation case was sent to you. You may need to check your SPAM folder. Thank you. Direct Mediation Services"
      );
      break;

    case "Family mediation inquiry":
      SetshowTemplate(
        "Dear [Client], I tried to call you but your line was busy. We got an inquiry from you about mediation. Please contact me on 0113 468 9593. Regards, Dorian DMS"
      );
      break;
    case "Legal Aid successful":
      SetshowTemplate(
        "Dear [Client],Thank you for your legal aid application for family mediation. Your application has been SUCCESSFUL. In a few minutes time you will receive an email asking for your electronic signature. Please sign the form electronically for us to be able to secure your legal aid funding and to continue with your application. Please note you don't need to call us you have done this. Regards Direct Mediation Services"
      );
      break;
    case "Legal Aid Refusal":
      SetshowTemplate(
        "Dear [Client], Please check your email. An email from our Legal Aid Assessment team has been sent to you. You may need to check your SPAM or Junk folder. Please do not call our office to discuss the decision, as all correspondence needs to be done via email. Thank you. Direct Mediation Services"
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={ShowSmsModal}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader} pb-0`}>
            <h4 className="text-center pt-4">SMS Information</h4>
            <Button
              onClick={close}
              type="button"
              className={`bg-transparent border-0`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
          <div className=" d-flex justify-content-center me-4">
            <Link to={`/chatSMS/${id}`} className="text-decoration-none">
              See Previous Chat <HiExternalLink className="fs-4" />
            </Link>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Phone:</label>
            <select
              defaultValue=""
              className="select-input"
              {...register("clientNumber", { required: "select Phone Number" })}
            >
              <option value="" disabled>
                Select Phone Number
              </option>
              <option>
                C1 | {MIAM1_C1?.personalContactAndCaseInfo?.phoneNumber}
              </option>
              <option>
                C2 | {MIAM1_C2?.personalContactAndCaseInfo?.phoneNumber}
              </option>
            </select>

            <small className="text-danger">
              {errors?.clientNumber && errors.clientNumber.message}
            </small>
            <label className="form-label">Client:</label>
            <select
              type="text"
              defaultValue=""
              className="select-input"
              {...register("messageBodyData", { required: true })}
            >
              <option value="" disabled>
                Select template
              </option>

              <option>Legal Aid Application - Low income - C1</option>
              <option>Legal Aid Application - Low income - C2</option>
              <option>
                Client 1 - MIAM Part 1 - only after client has been succesfull
                for Legal Aid funding
              </option>
              <option>Client 1 - MIAM Part 1 - Private clients</option>
              <option>Legal Aid Application - Passporting benefits - C1</option>
              <option>Legal Aid Application - Passporting benefits - C2</option>
              <option>C2 call for invitation</option>
              <option>Client 2 - Declined invitation - SMS to Client 1</option>
              <option>Message to invite C2</option>
              <option>
                C2 MIAM Part 1 - After they have agreed to attend mediation
              </option>
              <option>Case update</option>
              <option>Court form sent</option>
              <option>Email sent</option>
              <option>Family mediation inquiry</option>
              <option>Legal Aid successful</option>
              <option>Legal Aid Refusal</option>
            </select>
            <textarea
              rows={5}
              className="h-100 select-input  mt-3"
              {...register("caseType")}
              type="text"
              value={showTemplate}
              onChange={(e) => SetshowTemplate(e.target.value)}
            ></textarea>
            <small className="text-danger">
              {errors.messageBodyData && <span>This field is required</span>}
            </small>
            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "Send"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default SmsInfo;
