import styles from "../../Case/Case.module.css";
import { Button, Modal } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { getStatistics } from "../../../features/auth/authActions";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";


const CSV = ({ showModal, close }) => {
  const [month, setvalueOfMonth] = useState("");
  const [year, setvalueOfYear] = useState("");
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [MonthError, setMonthError] = useState(false);
  const [YearError, setYearError] = useState(false);
 
const dispatch=useDispatch()

  const onSubmit = async (e) => {
   e.preventDefault();
    setIsLoading(true);
    if (month.length === 0) {
        setMonthError('month is required');
         setIsLoading(false);
    }
    if (year.length === 0) {
        setYearError('year is required');
         setIsLoading(false);
    }

if(month.length !== 0 && year.length !== 0){

    dispatch(getStatistics({ month , year})).then((res) => {
       
        if (res.payload.status === 200) {
          setIsLoading(false);
          close();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: 'CSV file downloaded',
            life: 1500,
          });
          const worksheet = XLSX.utils.json_to_sheet(res.payload.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          const excelData = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(excelData, "data.xlsx");
      }
      
    
        
      });
}

  };
  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={showModal}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader}  pb-0`}>
            <h4 className="text-center pt-4">Save CSV</h4>
            <Button
              type="button"
              className={`bg-transparent border-0`}
              onClick={close}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={onSubmit}>
            <label className="form-label">Month:</label>
            <InputMask
              style={{border:"solid 1px #040848 " ,borderRadius:"10px" ,width:'100%' ,padding:'10px'}}
              value={month}
              onChange={(e) => setvalueOfMonth(e.target.value)}
              mask="99"
              placeholder="mm"
              slotChar="mm"
            />
            {!month && <div className="text-danger">
            {MonthError}
            </div> }
           
            <label className="form-label">Year:</label>
            <InputMask
             style={{border:"solid 1px #040848 " ,borderRadius:"10px" ,width:'100%' ,padding:'10px'}}
              value={year}
              onChange={(e) => setvalueOfYear(e.target.value)}
              mask="9999"
              placeholder="yyyy"
              slotChar="yyyy"
            />
              {!year&& <div className="text-danger">
            {YearError}
            </div> }
            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "download"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CSV;
