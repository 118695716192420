import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";

import style from "./SentAppointmentIConfirmation.module.css";
import { BOOK_MEDIATION_SESSION } from "../../features/auth/authActions";

const Data = ({ CaseData }) => {
  const { id } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [datesOptions, setDatesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [location, setLocation] = useState("select");
  const [showTextarea, setShowTextarea] = useState("No");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [option5, setOption5] = useState("");
  const [option6, setOption6] = useState("");
  const [isThereOption2, setIsThereOption2] = useState("No");
  const [isThereOption3, setIsThereOption3] = useState("No");
  const [isThereOption4, setIsThereOption4] = useState("No");
  const [isThereOption5, setIsThereOption5] = useState("No");
  const [isThereOption6, setIsThereOption6] = useState("No");
  const [valueOfTextarea, setValueOfTextarea] = useState("");

  useEffect(() => {
    let daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let monthsOfYear = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dates = [];
    let formattedDate = [];
    setDatesOptions(formattedDate);
    if (option1 !== "") dates.push(option1);
    if (option1 !== "" && isThereOption2 === "Yes" && option2 !== "")
      dates.push(option2);
    if (
      option1 !== "" &&
      isThereOption2 === "Yes" &&
      option2 !== "" &&
      isThereOption3 === "Yes" &&
      option3 !== ""
    )
      dates.push(option3);
    if (
      option1 !== "" &&
      isThereOption2 === "Yes" &&
      option2 !== "" &&
      isThereOption3 === "Yes" &&
      option3 !== "" &&
      isThereOption4 === "Yes" &&
      option4 !== ""
    )
      dates.push(option4);
    if (
      option1 !== "" &&
      isThereOption2 === "Yes" &&
      option2 !== "" &&
      isThereOption3 === "Yes" &&
      option3 !== "" &&
      isThereOption4 === "Yes" &&
      option4 !== "" &&
      isThereOption5 === "Yes" &&
      option5 !== ""
    )
      dates.push(option5);
    if (
      option1 !== "" &&
      isThereOption2 === "Yes" &&
      option2 !== "" &&
      isThereOption3 === "Yes" &&
      option3 !== "" &&
      isThereOption4 === "Yes" &&
      option4 !== "" &&
      isThereOption5 === "Yes" &&
      option5 !== "" &&
      isThereOption6 === "Yes" &&
      option6 !== ""
    )
      dates.push(option6);

    for (let i = 0; i < dates.length; i++) {
      const date = new Date(dates[i]);
      const dayOfWeek = daysOfWeek[date.getDay()];
      const dayOfMonth = date.getDate();
      const month = monthsOfYear[date.getMonth()];
      const hour = date.getHours();
      const minute = date.getMinutes();

      const timeRangeString = `${dayOfWeek}, ${dayOfMonth} ${month} between ${hour}:${minute} - ${
        hour + 2
      }:${minute}`;
      formattedDate.push(timeRangeString);
    }
  }, [
    isThereOption2,
    isThereOption3,
    isThereOption4,
    isThereOption5,
    isThereOption6,
    option1,
    option2,
    option3,
    option4,
    option5,
    option6,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let Output = {
      dates: datesOptions,
      location: location,
      textBody: "",
      id,
    };
    if (valueOfTextarea !== "" && showTextarea === "Yes") {
      Output = { ...Output, textBody: valueOfTextarea };
    }
    if (location === "select") {
      setIsLoading(false);
      setErrorLocation("location is required");
    } else if (datesOptions?.length === 0) {
      setIsLoading(false);
      setErrorOnSubmit("please enter dates options");
    } else {
      setErrorLocation("select");
      setErrorOnSubmit("");
      dispatch(BOOK_MEDIATION_SESSION(Output)).then((res) => {
        setIsLoading(false);
        if (res.payload.status === 200) {
          setIsLoading(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.payload.data.meesage,
            life: 1500,
          });
        } else {
          setIsLoading(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 1500,
          });
        }
      });
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="w-100 ">
        <div className="d-flex justify-content-end">
          <Link
            className={` text-white text-decoration-none mt-3`}
            to={`/case/${id}`}
          >
            <div
              className=" px-3 rounded py-2 me-3"
              style={{ backgroundColor: "#0337a4" }}
            >
              <TiArrowBack className="fs-3 " />{" "}
              <span
                style={{
                  color: "white",
                  fontWeight: "bold",
                  paddingTop: "20px",
                }}
              >
                Back
              </span>
            </div>
          </Link>{" "}
        </div>
        <div className={`${style.containerForTitle}  w-100 mt-3`}>
          <p className={style.titleMiam}>
            Availability - Joint Mediation Session
          </p>
        </div>
        <div className={`${style.containerForForm} mt-5 px-1 `}>
          <div>
            <form onSubmit={onSubmit} className="AvailabilityForm ">
              <label className="form-label mt-3" htmlFor="option1">
                Option 1 :
              </label>
              <input
                type="datetime-local"
                name="dates"
                value={option1}
                id="option1"
                onChange={(e) => setOption1(e.target.value)}
                className="select-input"
              />
              <label className="form-label">Is there option 2 ?</label>
              <div className="d-flex justify-content-start flex-column align-items-start">
                <div className="mb-2">
                  <input
                    type="radio"
                    value="Yes"
                    name="option2"
                    id="Yes-Two-option"
                    checked={isThereOption2 === "Yes"}
                    onChange={(e) => {
                      setIsThereOption2(e.target.value);
                    }}
                    required
                  />
                  <label htmlFor="Yes-Two-option" className="ms-1">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="No"
                    name="option2"
                    id="No-option-two"
                    checked={isThereOption2 === "No"}
                    onChange={(e) => {
                      setIsThereOption2(e.target.value);
                    }}
                  />
                  <label htmlFor="No-option-two" className="ms-1">
                    No
                  </label>
                </div>
              </div>
              {isThereOption2 === "Yes" && (
                <>
                  <label className="form-label mt-3" htmlFor="option2">
                    Option 2 :
                  </label>
                  <input
                    type="datetime-local"
                    name="dates"
                    value={option2}
                    id="option2"
                    onChange={(e) => setOption2(e.target.value)}
                    className="select-input"
                    required
                  />
                  <label className="form-label">Is there option 3 ?</label>
                  <div className="d-flex justify-content-start flex-column align-items-start">
                    <div className="mb-2">
                      <input
                        type="radio"
                        value="Yes"
                        name="option3"
                        id="Yes-Option-Three"
                        checked={isThereOption3 === "Yes"}
                        onChange={(e) => {
                          setIsThereOption3(e.target.value);
                        }}
                        required
                      />
                      <label htmlFor="Yes-Option-Three" className="ms-1">
                        Yes
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="No"
                        name="option3"
                        id="No-Option-Three"
                        checked={isThereOption3 === "No"}
                        onChange={(e) => {
                          setIsThereOption3(e.target.value);
                        }}
                      />
                      <label htmlFor="No-Option-Three" className="ms-1">
                        No
                      </label>
                    </div>
                  </div>
                </>
              )}
              {isThereOption2 === "Yes" && isThereOption3 === "Yes" && (
                <>
                  <label className="form-label mt-3">Option 3 :</label>
                  <input
                    type="datetime-local"
                    name="dates"
                    value={option3}
                    onChange={(e) => setOption3(e.target.value)}
                    className="select-input"
                    required
                  />
                  <label className="form-label">Is there option 4 ?</label>
                  <div className="d-flex justify-content-start flex-column align-items-start">
                    <div className="mb-2">
                      <input
                        type="radio"
                        value="Yes"
                        name="option4"
                        id="Yes-Option-Four"
                        checked={isThereOption4 === "Yes"}
                        onChange={(e) => {
                          setIsThereOption4(e.target.value);
                        }}
                        required
                      />
                      <label htmlFor="Yes-Option-Four" className="ms-1">
                        Yes
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="No"
                        name="option4"
                        id="No-Option-Four"
                        checked={isThereOption4 === "No"}
                        onChange={(e) => {
                          setIsThereOption4(e.target.value);
                        }}
                      />
                      <label htmlFor="No-Option-Four" className="ms-1">
                        No
                      </label>
                    </div>
                  </div>
                </>
              )}
              {isThereOption2 === "Yes" &&
                isThereOption3 === "Yes" &&
                isThereOption4 === "Yes" && (
                  <>
                    <label className="form-label mt-3" htmlFor="option4">
                      Option 4 :
                    </label>
                    <input
                      type="datetime-local"
                      name="dates"
                      value={option4}
                      id="option4"
                      onChange={(e) => setOption4(e.target.value)}
                      className="select-input"
                      required
                    />
                    <label className="form-label">Is there option 5 ?</label>
                    <div className="d-flex justify-content-start flex-column align-items-start">
                      <div className="mb-2">
                        <input
                          type="radio"
                          value="Yes"
                          name="option5"
                          id="Yes-Option-Five"
                          checked={isThereOption5 === "Yes"}
                          onChange={(e) => {
                            setIsThereOption5(e.target.value);
                          }}
                          required
                        />
                        <label htmlFor="Yes-Option-Five" className="ms-1">
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          name="option5"
                          id="No-Option-Five"
                          checked={isThereOption5 === "No"}
                          onChange={(e) => {
                            setIsThereOption5(e.target.value);
                          }}
                        />
                        <label htmlFor="No-Option-Five" className="ms-1">
                          No
                        </label>
                      </div>
                    </div>
                  </>
                )}

              {isThereOption2 === "Yes" &&
                isThereOption3 === "Yes" &&
                isThereOption4 === "Yes" &&
                isThereOption5 === "Yes" && (
                  <>
                    <label className="form-label mt-3">Option 5 :</label>
                    <input
                      type="datetime-local"
                      name="dates"
                      value={option5}
                      onChange={(e) => setOption5(e.target.value)}
                      className="select-input"
                    />
                    <label className="form-label">Is there option 6 ?</label>
                    <div className="d-flex justify-content-start flex-column align-items-start">
                      <div className="mb-2">
                        <input
                          type="radio"
                          value="Yes"
                          name="option6"
                          id="Yes"
                          checked={isThereOption6 === "Yes"}
                          onChange={(e) => {
                            setIsThereOption6(e.target.value);
                          }}
                        />
                        <label htmlFor="Yes" className="ms-1">
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          name="option6"
                          id="No"
                          checked={isThereOption6 === "No"}
                          onChange={(e) => {
                            setIsThereOption6(e.target.value);
                          }}
                        />
                        <label htmlFor="No" className="ms-1">
                          No
                        </label>
                      </div>
                    </div>
                  </>
                )}

              {isThereOption2 === "Yes" &&
                isThereOption3 === "Yes" &&
                isThereOption4 === "Yes" &&
                isThereOption5 === "Yes" &&
                isThereOption6 === "Yes" && (
                  <>
                    <label className="form-label mt-3" htmlFor="option6">
                      Option 6 :
                    </label>
                    <input
                      type="datetime-local"
                      name="dates"
                      id="option6"
                      value={option6}
                      onChange={(e) => setOption6(e.target.value)}
                      className="select-input"
                      required
                    />
                  </>
                )}
              {/* {arr.length === 0 ? (
                <small className="text-danger">
                  <span>{errorOnSubmit}</span>
                </small>
              ) : (
                ""
              )} */}
              <>
                <label className="form-label">Location:</label>
                <select
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="select-input"
                >
                  <option value="select">
                    ----- please select an option -----
                  </option>
                  <option value="Online via WhatsApp">
                    Online via WhatsApp
                  </option>
                  <option value="Zoom">Zoom</option>
                </select>
              </>
              <>
                <label htmlFor="zoom"></label>
              </>
              {location === "select" && (
                <small className="text-danger">
                  <span>{errorLocation}</span>
                </small>
              )}
              <label className="form-label">
                Is there an additional paragraph ?
              </label>
              <div className="d-flex justify-content-start flex-column align-items-start">
                <div className="mb-2">
                  <input
                    type="radio"
                    value="Yes"
                    name="gender"
                    id="Yes"
                    checked={showTextarea === "Yes"}
                    onChange={(e) => setShowTextarea(e.target.value)}
                  />
                  <label htmlFor="Yes" className="ms-1">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="No"
                    name="gender"
                    id="No"
                    checked={showTextarea === "No"}
                    onChange={(e) => setShowTextarea(e.target.value)}
                  />
                  <label htmlFor="No" className="ms-1">
                    No
                  </label>
                </div>
              </div>
              {showTextarea === "Yes" && (
                <div>
                  <label htmlFor="textarea" className="form-label">
                    Additional Paragraph
                  </label>
                  <textarea
                    rows={3}
                    id="textarea"
                    className="h-100 select-input mt-3"
                    type="text"
                    value={valueOfTextarea}
                    onChange={(e) => setValueOfTextarea(e.target.value)}
                  ></textarea>
                </div>
              )}
              {errorOnSubmit !== "" && (
                <small className="text-danger">
                  <span>{errorOnSubmit}</span>
                </small>
              )}
              <button className="login-button py-2 my-3" type="submit">
                {isLoading ? <Spinner animation="border" /> : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Data;
