import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import style from "../Case/Case.module.css";
import { GrContactInfo } from "react-icons/gr";
import { updateMediatorData } from "../../features/auth/authActions";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
export const EditMediator = ({
  close,
  showEditMediator,
  allMediatorsData,
  idd,
  refetch,
}) => {
  const [valueOfName, setvalueOfName] = useState("");
  const [valueOFLastName, setvalueOFLastName] = useState("");
  const [valueOFEmail, setvalueOFEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const x = allMediatorsData?.find((y) => y.id === idd);

  const fullName = x?.name;
  const nameParts = fullName?.split(" ");
  let firstName;
  let lastName;
  if (nameParts) {
    firstName = nameParts[0];
    lastName = nameParts[1];
  }

  useEffect(() => {
    setvalueOFEmail(x?.email);
    setvalueOfName(firstName);
    setvalueOFLastName(lastName);
  }, [x, firstName, lastName, idd]);
  const onSubmit = (e) => {
    e.preventDefault();
    const id = x.id;
    let firstName = valueOfName;
    let lastName = valueOFLastName;
    let email = valueOFEmail;
    setIsLoading(true);

    if (
      valueOFEmail !== "" &&
      (valueOFLastName !== "") & (valueOfName !== "")
    ) {
      dispatch(updateMediatorData({ id, firstName, lastName, email })).then(
        (res) => {
          if (res?.meta?.requestStatus === "fulfilled") {
            setIsLoading(false);
            close();
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res?.payload?.message,
              life: 1500,
            });
          }
        }
      );
      setTimeout(() => {
        refetch();
      }, 1500);
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={showEditMediator}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0 d-flex justify-content-end">
          <div className={`  pb-0  `}>
            {/* <h4 className="text-center pt-4">Send Court Form</h4> */}
            <Button
              onClick={close}
              type="button"
              className={`bg-transparent border-0`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4 text-center">
          <div className="d-flex align-items-end justify-content-center">
            <div className={`${style.line} w-50 me-3`}></div>
            <GrContactInfo className=" m-auto fs-2" />
            <div className={`${style.line} w-50 ms-3`}></div>
          </div>
          <div className="px-4 pb-4">
            <form onSubmit={onSubmit}>
              <label className="form-label">First Name :</label>
              <input
                value={valueOfName}
                onChange={(e) => setvalueOfName(e.target.value)}
                className=" select-input  mt-3"
                type="text"
              />
              <label className="form-label">Last Name :</label>
              <input
                value={valueOFLastName}
                onChange={(e) => setvalueOFLastName(e.target.value)}
                className=" select-input  mt-3"
                type="text"
              />
              <label className="form-label">Email :</label>
              <input
                value={valueOFEmail}
                onChange={(e) => setvalueOFEmail(e.target.value)}
                className=" select-input  mt-3"
                type="text"
              />
              <button className="login-button py-2 mt-3" type="submit">
                {isLoading ? <Spinner animation="border" /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
