import React, { useEffect, useRef, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";

import style from "./dashboard.module.css";
import {
  addDrive,
  deleteCompany,
  twilioConfig,
  updateCompany,
} from "../../features/auth/authActions";
export default function CompanyCard({
  id,
  name,
  email,
  mediatorNo,
  casesNo,
  logoPath,
  company,
  handleDeleteRefetch,
}) {
  const [card, setCard] = useState("");
  const [flip, setFlip] = useState(false);
  const [driveAccount, setDriveAccount] = useState("");
  const [twilioId, setTwilioId] = useState("");
  const [twilioToken, setTwilioToken] = useState("");
  const [twilioNumber, setTwilioNumber] = useState("");
  const [modalView, setModalView] = useState(false);
  const [show, setShow] = useState(false);
  const [getCompanyName, setCompanyName] = useState(company?.companyName);
  const [getCompanyemail, setCompanyEmail] = useState(company?.email);
  const [imageLogo, setImageLogo] = useState(company?.logo);
  const [passwordValue, setpasswordValue] = useState("");
  const [PasswordError] = useState("");
  const [conirmPasswordValue, setconirmPasswordValue] = useState("");
  const [conirmPasswordError] = useState("");
  const [gmailAccount, setGmailAccount] = useState(company?.sharingGmail);
  const [loading, setLoading] = useState(false);
  const [twilioServiceId, setTwilioServiceId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditShow = () => setModalView(true);
  const handleEditClose = () => setModalView(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useRef(null);
  useEffect(() => {
    setGmailAccount(company.sharingGmail);
  }, [company, driveAccount, gmailAccount]);
  const onSubmit = () => {
    if (passwordValue === "") {
      const companyData = {
        companyName: getCompanyName,
        email: getCompanyemail,
        logo: imageLogo,
        sharingGmail: gmailAccount || "",
      };
      const data = { id: id, companyData: companyData };
      setLoading(true);
      dispatch(updateCompany(data))
        .then((data) => {
          setLoading(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Company Updated Successfully",
            life: 1500,
          });
        })
        .then(() => {
          setTimeout(() => {
            handleDeleteRefetch();
          }, 1600);
        });
    } else {
      if (passwordValue !== conirmPasswordValue) {
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: "confirm password value is incorrect",
          life: 1500,
        });
      } else {
        const companyData = {
          companyName: getCompanyName,
          email: getCompanyemail,
          logo: imageLogo,
          sharingGmail: gmailAccount || "",
          password: passwordValue,
        };
        const data = { id: id, companyData: companyData };
        setLoading(true);
        dispatch(updateCompany(data))
          .then((data) => {
            setLoading(false);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Company Updated Successfully",
              life: 1500,
            });
          })
          .then(() => {
            setTimeout(() => {
              handleDeleteRefetch();
            }, 1600);
          });
      }
    }
  };
  function driveCard(flip) {
    setFlip(!flip);
    setCard("Drive");
  }
  function twilioCard(flip) {
    setFlip(!flip);
    setCard("Twilio");
  }
  const onSubmitDrive = (e) => {
    e.preventDefault();
    const data = { id: id, sharingGmail: driveAccount };
    dispatch(addDrive(data))
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.payload.message,
          life: 1500,
        });
        setDriveAccount("");
        setFlip(!flip);
      })
      .then(() => {
        setTimeout(() => {
          handleDeleteRefetch();
        }, 1600);
      });
  };
  const handleTwilio = (e) => {
    e.preventDefault();
    const twilioConfigData = {
      twillioSID: twilioId,
      twillioToken: twilioToken,
      twillioNumber: twilioNumber,
    };

    const data = { email: email, twillioData: twilioConfigData };
    dispatch(twilioConfig(data))
      .then((data) => {
        setFlip(!flip);
      })
      .then(() => {
        handleDeleteRefetch();
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteCompany({ id })).then((data) => {
      if (data.meta.requestStatus === "rejected") {
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: data.payload,
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.payload.message,
          life: 1500,
        });
      }
      handleDeleteRefetch();
      handleClose();
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <ReactCardFlip
        isFlipped={flip}
        flipDirection="horizontal"
        className={style.companyCard}
      >
        <div className={style.companyCard}>
          <div className={style.icons}>
            <div className={style.icon}>
              <Button
                variant="primary"
                onClick={handleEditShow}
                className="bg-transparent border-0"
              >
                <img
                  src="/images/edit-icon.png"
                  alt="edit icon"
                  className={style.editIcon}
                />
              </Button>
            </div>
            <>
              <div>
                <button
                  onClick={handleShow}
                  className={`${style.iconDelete}  border-0`}
                >
                  <img
                    src="/images/delete-icon.png"
                    alt="delete icon"
                    className={`${style.editIcon} `}
                    onClick={handleShow}
                  />
                </button>
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton className="border-0">
                  <Modal.Title className={`${style.header} fw-bold`}>
                    Confirm
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0">
                  Are you sure you want to delete this company ?
                </Modal.Body>
                <Modal.Footer className="border-0">
                  <Button variant="secondary" onClick={handleClose}>
                    No
                  </Button>
                  <Button variant="danger" onClick={handleDelete}>
                    Yes , Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          </div>
          <div className={style.logo}>
            <img
              src={logoPath ? logoPath : "/images/logos-05.png"}
              alt="logo icon"
            />
          </div>
          <div className={style.data}>
            <h3>{name ? name : "Company Name not found"}</h3>
            <h5>{email ? email : "Email not found"}</h5>
          </div>
          <div className={style.btns}>
            <div className={style.btnValue}>
              <div className={style.cardcase}>
                <h3>{mediatorNo ? mediatorNo : 0}</h3>
                <h5>Mediators</h5>
              </div>
              <Button
                className={style.driveBtn}
                onClick={() => driveCard(flip)}
              >
                <img src="/images/Drive-logo.png" alt="drive icon" /> Drive
              </Button>
            </div>
            <div className={style.btnValue}>
              <div className={style.cardcase}>
                <h3>{casesNo ? casesNo : 0}</h3>
                <h5>Cases</h5>
              </div>
              <Button
                className={`${style.twilioBtn}`}
                onClick={() => twilioCard(flip)}
              >
                <img src="/images/Twilio-logo.png" alt="twilio icon" /> Twilio
              </Button>
            </div>
          </div>
        </div>
        {card === "Drive" ? (
          <div
            className={`${style.companyCard} ${style.flipCard} position-relative`}
          >
            <button
              className="position-absolute top-0 start-0 p-4 fs-4 text-white border-0 bg-transparent"
              onClick={() => setFlip(!flip)}
            >
              <AiOutlineArrowLeft />
            </button>

            <div className={style.driveLogo}>
              <img
                src="/images/Drive-logo.png"
                alt="drive icon"
                className="pb-2"
              />
              <h3>Add Google Drive</h3>
            </div>
            <div className={style.formInput}>
              <form onSubmit={onSubmitDrive}>
                <input
                  className="mb-3"
                  type="email"
                  placeholder="Enter Gmail Account"
                  value={driveAccount}
                  onChange={(e) => setDriveAccount(e.target.value)}
                />
                <button type="submit" className={`w-100 ${style.btnAdd}`}>
                  Add
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div
            className={`${style.companyCard} ${style.flipCard} ${style.twilioCard} position-relative`}
          >
            <button
              className="position-absolute top-0 start-0 p-4 fs-4 text-white border-0 bg-transparent"
              onClick={() => setFlip(!flip)}
            >
              <AiOutlineArrowLeft />
            </button>

            <div className={`${style.driveLogo}`}>
              <img
                src="/images/Twilio-logo.png"
                alt="drive icon"
                className="pb-2"
              />
              <h3>Configure twilio</h3>
            </div>
            <div className={`${style.formInput} ${style.twilioCard} p-3 pt-0`}>
              <form onSubmit={handleTwilio}>
                <input
                  className="mb-3"
                  type="name"
                  placeholder="Enter Service ID"
                  value={twilioServiceId}
                  onChange={(e) => setTwilioServiceId(e.target.value)}
                />
                <input
                  className="mb-3"
                  type="name"
                  placeholder="Enter ID"
                  value={twilioId}
                  onChange={(e) => setTwilioId(e.target.value)}
                />
                <input
                  className="mb-3"
                  type="name"
                  placeholder="Enter Token"
                  value={twilioToken}
                  onChange={(e) => setTwilioToken(e.target.value)}
                />
                <input
                  className="mb-3"
                  type="name"
                  placeholder="Enter Phone Number"
                  value={twilioNumber}
                  onChange={(e) => setTwilioNumber(e.target.value)}
                />
                <button type="submit" className={`w-100 ${style.btnAdd}`}>
                  Configure
                </button>
              </form>
            </div>
          </div>
        )}
      </ReactCardFlip>
      <Modal show={modalView} onHide={handleEditClose} centered>
        <Modal.Header closeButton className={`${style.header} border-0`}>
          <h4 className="text-center">Edit company</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Name:</label>
            <input
              className="form-input"
              type="name"
              value={getCompanyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <label className="form-label">Email:</label>
            <input
              className="form-input"
              type="email"
              value={getCompanyemail}
              onChange={(e) => setCompanyEmail(e.target.value)}
            />
            <small className="text-danger">
              {errors?.email && errors.email.message}
            </small>
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <label className="form-label">Image logo:</label>
              </div>
            </div>
            <input
              className="form-input"
              type="text"
              // placeholder={!imageLogo && "enter company logo"}
              value={imageLogo}
              onChange={(e) => setImageLogo(e.target.value)}
            />
            <div className="d-flex justify-content-between">
              <div>
                <label className="form-label">Gmail account:</label>
              </div>
            </div>
            <input
              className="form-input"
              type="text"
              placeholder={!gmailAccount && "enter gmail account"}
              value={gmailAccount && gmailAccount}
              onChange={(e) => setGmailAccount(e.target.value)}
            />
            <div className="d-flex justify-content-between">
              <div>
                <label className="form-label">Password:</label>
              </div>
            </div>
            <p className="text-primary m-0 p-0">
              Please enter a password that is at least 8 characters long and
              includes at least one letter and one number.
            </p>
            <Password
              className="form-input AvailabilityForm ps-0"
              placeholder="Password"
              toggleMask
              feedback={false}
              value={passwordValue}
              onChange={(e) => setpasswordValue(e.target.value)}
            />
            {passwordValue?.length === 0 && (
              <small className="text-danger">
                <span>{PasswordError}</span>
              </small>
            )}
            <div className="d-flex justify-content-between">
              <div>
                <label className="form-label">Confirm Password:</label>
              </div>
            </div>
            <Password
              className="form-input AvailabilityForm ps-0"
              placeholder="confirm password"
              toggleMask
              feedback={false}
              value={conirmPasswordValue}
              onChange={(e) => setconirmPasswordValue(e.target.value)}
            />
            {conirmPasswordValue?.length === 0 && (
              <small className="text-danger">
                <span>{conirmPasswordError}</span>
              </small>
            )}
            <button className="login-button py-2 mt-4" type="submit">
              {loading ? <Spinner animation="border" /> : "edit company"}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
