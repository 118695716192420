import React, { useRef, useState } from "react";
import { TiArrowBack } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Toast } from "primereact/toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "../casemaim2/Case.module.css";
import { updateC1M2, updateC2M2 } from "../../features/auth/authActions";

const EditMIAM2Part1 = () => {
  const [loadind, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const regex = /^\/editMIAM\/([^/]+)/;
  const path = location.pathname.match(regex)[0];
  const { id } = useParams();
  let data = location.state.data;
  const [mediationDetailsObject, setMediationDetailsObject] = useState({
    MediatorName: data?.mediationDetails?.MediatorName,
    MediatorEmail: data?.mediationDetails?.MediatorEmail,
    DateOfMIAM: data?.mediationDetails?.DateOfMIAM,
    Location: data?.mediationDetails?.Location,
    SpecifiedLocation: data?.mediationDetails?.SpecifiedLocation,
    C1OrC2: data?.mediationDetails?.C1OrC2,
    clientFirstName: data?.mediationDetails?.clientFirstName,
    clientSurName: data?.mediationDetails?.clientSurName,
    clientEmail: data?.mediationDetails?.clientEmail,
    otherPartySurname: data?.mediationDetails?.otherPartySurname || " ",
  });

  const [caseDetailsObject, setCaseDetailsObject] = useState({
    privateOrLegalAid: data?.caseDetails?.privateOrLegalAid,
    paymentMade: data?.caseDetails?.paymentMade,
    whyPaymentNotMade: data?.caseDetails?.whyPaymentNotMade,
    advancePayment: data?.caseDetails?.advancePayment,
  });
  const [MediationTypesObject, setMediationTypesObject] = useState({
    isClientWillingToGoWithMediation:
      data?.MediationTypes?.isClientWillingToGoWithMediation,
    mediationFormPreference: data?.MediationTypes?.mediationFormPreference,
    reasonsForRefusal: data?.MediationTypes?.reasonsForRefusal,
    confirmLegalDispute: data?.MediationTypes?.confirmLegalDispute,
    isChildInclusiveAppropriate:
      data?.MediationTypes?.isChildInclusiveAppropriate,
    doesClientWishForChildInclusive:
      data?.MediationTypes?.doesClientWishForChildInclusive,
    informationGivenToClient: data?.MediationTypes?.informationGivenToClient,
    explainWhyADRInfoWasNotProvided:
      data?.MediationTypes?.explainWhyADRInfoWasNotProvided,
    clientPreference: data?.MediationTypes?.clientPreference,
    areSeparateWaitingAreasRequired:
      data?.MediationTypes?.areSeparateWaitingAreasRequired,
    areSeparateArrivalAndDepartureTimesRequired:
      data?.MediationTypes?.areSeparateArrivalAndDepartureTimesRequired,
  });
  const [FinalCommentsObject, setFinalCommentsObject] = useState({
    isSuitable: data?.FinalComments?.isSuitable,
    whyNotSuitable: data?.FinalComments?.whyNotSuitable,
    uploadCourtForm: data?.FinalComments?.uploadCourtForm,
    CommentsToDMS: data?.FinalComments?.CommentsToDMS,
  });

  const [comments, setcomments] = useState({
    MediatorComments: data?.comments?.MediatorComments,
    isDomesticAbuseOrViolence: data?.comments?.isDomesticAbuseOrViolence,
    domesticAbuseOrViolenceDetails:
      data?.comments?.domesticAbuseOrViolenceDetails,
    isPoliceInvolve: data?.comments?.isPoliceInvolve,
    policeInvolveDetails: data?.comments?.policeInvolveDetails,
    isSocialServiceInvolve: data?.comments?.isSocialServiceInvolve,
    socialServiceInvolveDetails: data?.comments?.socialServiceInvolveDetails,
    isSafeguardingIssues: data?.comments?.isSafeguardingIssues,
    safeguardingIssuesDetails: data?.comments?.safeguardingIssuesDetails,
    isCourtOrders: data?.comments?.isCourtOrders,
    courtOrdersDetails: data?.comments?.courtOrdersDetails,
    isClientRequireSignposting: data?.comments?.isClientRequireSignposting,
    clientRequireSignpostingDetails:
      data?.comments?.clientRequireSignpostingDetails,
  });
  const handleClick = (e) => {
    let myUser = {
      ...mediationDetailsObject,
    };
    myUser[e.target.name] = e.target.value;
    setMediationDetailsObject(myUser);

    let commentsObject = {
      ...comments,
    };
    commentsObject[e.target.name] = e.target.value;
    setcomments(commentsObject);

    let caseDetail = {
      ...caseDetailsObject,
    };
    caseDetail[e.target.name] = e.target.value;
    setCaseDetailsObject(caseDetail);

    let MediationTypes = {
      ...MediationTypesObject,
    };
    MediationTypes[e.target.name] = e.target.value;
    setMediationTypesObject(MediationTypes);

    let FinalComments = {
      ...FinalCommentsObject,
    };
    FinalComments[e.target.name] = e.target.value;
    setFinalCommentsObject(FinalComments);
  };
  const patchObject = {
    mediationDetails: mediationDetailsObject,
    caseDetails: caseDetailsObject,
    MediationTypes: MediationTypesObject,
    FinalComments: FinalCommentsObject,
    comments: comments,
  };

  const submit = () => {
    setLoading(true);
    const path = location.pathname.match(regex)[0];
    if (path === "/editMIAM/C1") {
      dispatch(updateC1M2({ patchObject, id })).then((res) => {
        if (res) {
          setLoading(false);
        }
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.res,
          life: 1500,
        });
        setTimeout(() => {
          navigate(`/case/maim2/${id}`);
        }, 1600);
      });
    } else {
      dispatch(updateC2M2({ patchObject, id })).then((res) => {
        if (res) {
          setLoading(false);
        }
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.res,
          life: 1500,
        });
        setTimeout(() => {
          navigate(`/case/MIAM2/${id}`);
        }, 1600);
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <section>
        <div className="container mb-4 ">
          <div className="row mt-5 gy-3 mt-5">
            <div className="col-md-12  d-flex justify-content-center align-items-center">
              <div className={`${styles.backEditBTN}`}>
                <Link
                  className={` text-white text-decoration-none `}
                  to={{
                    pathname:
                      path === "/editMIAM/C2"
                        ? `/case/MIAM2/${id}`
                        : `/case/maim2/${id}`,
                  }}
                >
                  <div
                    className=" rounded"
                    style={{ backgroundColor: "#0337a4" }}
                  >
                    <TiArrowBack className="fs-3" />{" "}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        paddingTop: "20px",
                      }}
                    >
                      Back
                    </span>
                  </div>
                </Link>{" "}
              </div>
              <div>
                <p className={`${styles.titleOfMIAM} py-2 mt-5`}>
                  {path === "/editMIAM/C1"
                    ? "Edit C1 MIAM Part 2"
                    : "Edit C2 MIAM Part 2"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <Table className={`${styles.tableCase} ${styles.table} `}>
                <tbody>
                  {data?.mediationDetails?.MediatorName && (
                    <tr>
                      <td className={`${styles.left_td} ps-4`}>
                        Name of Mediator :
                      </td>
                      <td className={` ${styles.right_td} ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="MediatorName"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.MediatorName}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.MediatorEmail && (
                    <tr>
                      <td className="ps-4">Mediator's email :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="MediatorEmail"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.MediatorEmail}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.DateOfMIAM && (
                    <tr>
                      <td className="ps-4">Date of MIAM :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="DateOfMIAM"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.DateOfMIAM}
                        />
                      </td>
                    </tr>
                  )}

                  {data?.mediationDetails?.Location && (
                    <tr>
                      <td className="ps-4">Location :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="Location"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.Location}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.SpecifiedLocation && (
                    <tr>
                      <td className="ps-4">Specify Location :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="SpecifiedLocation"
                          onChange={handleClick}
                          defaultValue={
                            data?.mediationDetails?.SpecifiedLocation
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.clientFirstName && (
                    <tr>
                      <td className="ps-4">Client's first name :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="clientFirstName"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.clientFirstName}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.clientSurName && (
                    <tr>
                      <td className="ps-4">Client's surname :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="clientSurName"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.clientSurName}
                        />
                      </td>
                    </tr>
                  )}

                  {data?.mediationDetails?.clientEmail && (
                    <tr>
                      <td className="ps-4">Client's email :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="clientEmail"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.clientEmail}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.otherPartySurname && (
                    <tr>
                      <td className="ps-4">Other party's surname :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="otherPartySurname"
                          onChange={handleClick}
                          defaultValue={
                            data?.mediationDetails?.otherPartySurname
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.mediationDetails?.C1OrC2 && (
                    <tr>
                      <td className="ps-4">MIAM for C1 or C2 :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="C1OrC2"
                          onChange={handleClick}
                          defaultValue={data?.mediationDetails?.C1OrC2}
                        />
                      </td>
                    </tr>
                  )}

                  {data?.caseDetails?.privateOrLegalAid && (
                    <tr>
                      <td className="ps-4">Private/LA : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="privateOrLegalAid"
                          onChange={handleClick}
                          defaultValue={data?.caseDetails?.privateOrLegalAid}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.caseDetails?.paymentMade && (
                    <tr>
                      <td className="ps-4">Appointment's payment : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="privateOrLegalAid"
                          onChange={handleClick}
                          defaultValue={data?.caseDetails?.paymentMade}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.caseDetails?.whyPaymentNotMade && (
                    <tr>
                      <td className="ps-4">Why Payment not paid : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="whyPaymentNotMade"
                          onChange={handleClick}
                          defaultValue={data?.caseDetails?.whyPaymentNotMade}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.caseDetails?.advancePayment && (
                    <tr>
                      <td className="ps-4">Payment in advance explained : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="advancePayment"
                          onChange={handleClick}
                          defaultValue={data?.caseDetails?.advancePayment}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.MediatorComments && (
                    <tr>
                      <td className="ps-4">Comments from the mediator :</td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="MediatorComments"
                          onChange={handleClick}
                          defaultValue={data?.comments?.MediatorComments}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.isDomesticAbuseOrViolence && (
                    <tr>
                      <td className="ps-4">Domestic abuse / violence : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isDomesticAbuseOrViolence"
                          onChange={handleClick}
                          defaultValue={
                            data?.comments?.isDomesticAbuseOrViolence
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.domesticAbuseOrViolenceDetails && (
                    <tr>
                      <td className="ps-4">
                        Domestic abuse / violence(Details):{" "}
                      </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="domesticAbuseOrViolenceDetails"
                          onChange={handleClick}
                          defaultValue={
                            data?.comments?.domesticAbuseOrViolenceDetails
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.isPoliceInvolve && (
                    <tr>
                      <td className="ps-4">Police involvement : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isPoliceInvolve"
                          onChange={handleClick}
                          defaultValue={data?.comments?.isPoliceInvolve}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.policeInvolveDetails && (
                    <tr>
                      <td className="ps-4">Police involvement(Details) : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="policeInvolveDetails"
                          onChange={handleClick}
                          defaultValue={data?.comments?.policeInvolveDetails}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.isSocialServiceInvolve && (
                    <tr>
                      <td className="ps-4">Social Services involvement : </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isSocialServiceInvolve"
                          onChange={handleClick}
                          defaultValue={data?.comments?.isSocialServiceInvolve}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.socialServiceInvolveDetails && (
                    <tr>
                      <td className="ps-4">
                        Social Services involvement(Details) :{" "}
                      </td>
                      <td className="ps-4">
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="socialServiceInvolveDetails"
                          onChange={handleClick}
                          defaultValue={
                            data?.comments?.socialServiceInvolveDetails
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.isSafeguardingIssues && (
                    <tr>
                      <td
                        className={`${styles.right_td} ${styles.left_td} ps-4`}
                      >
                        Safeguarding issues :{" "}
                      </td>
                      <td
                        className={`${styles.right_td} ${styles.left_td} ps-4`}
                      >
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isSafeguardingIssues"
                          onChange={handleClick}
                          defaultValue={data?.comments?.isSafeguardingIssues}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.safeguardingIssuesDetails && (
                    <tr>
                      <td
                        className={`${styles.right_td} ${styles.left_td} ps-4`}
                      >
                        Safeguarding issues(Details) :{" "}
                      </td>
                      <td
                        className={`${styles.right_td} ${styles.left_td} ps-4`}
                      >
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="safeguardingIssuesDetails"
                          onChange={handleClick}
                          defaultValue={
                            data?.comments?.safeguardingIssuesDetails
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.isCourtOrders && (
                    <tr>
                      <td className={`ps-4`}>Court orders in place : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isCourtOrders"
                          onChange={handleClick}
                          defaultValue={data?.comments?.isCourtOrders}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.courtOrdersDetails && (
                    <tr>
                      <td className={`ps-4`}>
                        Court orders in place(Details) :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="courtOrdersDetails"
                          onChange={handleClick}
                          defaultValue={data?.comments?.courtOrdersDetails}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.isClientRequireSignposting && (
                    <tr>
                      <td className={`ps-4`}>Client require signposting : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isClientRequireSignposting"
                          onChange={handleClick}
                          defaultValue={
                            data?.comments?.isClientRequireSignposting
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.comments?.clientRequireSignpostingDetails && (
                    <tr>
                      <td className={`ps-4`}>
                        Client require signposting (Details) :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="clientRequireSignpostingDetails"
                          onChange={handleClick}
                          defaultValue={
                            data?.comments?.clientRequireSignpostingDetails
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.isClientWillingToGoWithMediation && (
                    <tr>
                      <td className={`ps-4`}>
                        Willing to go ahead with mediation :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isClientWillingToGoWithMediation"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes
                              ?.isClientWillingToGoWithMediation
                          }
                        />
                      </td>
                    </tr>
                  )}

                  {data?.MediationTypes?.reasonsForRefusal && (
                    <tr>
                      <td className={`ps-4`}>Reasons for refusal :</td>
                      <input
                        type="text"
                        className="  p-1 rounded border-0"
                        name="reasonsForRefusal"
                        onChange={handleClick}
                        defaultValue={data?.MediationTypes?.reasonsForRefusal}
                      />
                    </tr>
                  )}

                  {data?.MediationTypes?.mediationFormPreference && (
                    <tr>
                      <td className={`ps-4`}>Form of mediation : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="mediationFormPreference"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes?.mediationFormPreference
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.confirmLegalDispute && (
                    <tr>
                      <td className={`ps-4`}>Confirm legal dispute : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="confirmLegalDispute"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes?.confirmLegalDispute
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.isChildInclusiveAppropriate && (
                    <tr>
                      <td className={`ps-4`}>
                        Child Inclusive Mediation appropriate :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isChildInclusiveAppropriate"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes?.isChildInclusiveAppropriate
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.doesClientWishForChildInclusive && (
                    <tr>
                      <td className={`ps-4`}>
                        Client wish for Child Inclusive Mediation :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="doesClientWishForChildInclusive"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes
                              ?.doesClientWishForChildInclusive
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.informationGivenToClient && (
                    <tr>
                      <td className={`ps-4`}>
                        Information about other forms of ADR :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="informationGivenToClient"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes?.informationGivenToClient
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.explainWhyADRInfoWasNotProvided && (
                    <tr>
                      <td className={`ps-4`}>Reason : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="explainWhyADRInfoWasNotProvided"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes
                              ?.explainWhyADRInfoWasNotProvided
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.clientPreference && (
                    <tr>
                      <td className={`ps-4`}>Mediation online/in person : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="clientPreference"
                          onChange={handleClick}
                          defaultValue={data?.MediationTypes?.clientPreference}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes?.areSeparateWaitingAreasRequired && (
                    <tr>
                      <td className={`ps-4`}>
                        Separate waiting areas required :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="areSeparateWaitingAreasRequired"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes
                              ?.areSeparateWaitingAreasRequired
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.MediationTypes
                    ?.areSeparateArrivalAndDepartureTimesRequired && (
                    <tr>
                      <td className={`ps-4`}>
                        Separate arrival and departure times required :{" "}
                      </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="areSeparateArrivalAndDepartureTimesRequired"
                          onChange={handleClick}
                          defaultValue={
                            data?.MediationTypes
                              ?.areSeparateArrivalAndDepartureTimesRequired
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {data?.FinalComments?.isSuitable && (
                    <tr>
                      <td className={`ps-4`}>Case suitable for mediation : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="isSuitable"
                          onChange={handleClick}
                          defaultValue={data?.FinalComments?.isSuitable}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.FinalComments?.whyNotSuitable && (
                    <tr>
                      <td className={`ps-4`}>why Not Suitable : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="whyNotSuitable"
                          onChange={handleClick}
                          defaultValue={data?.FinalComments?.whyNotSuitable}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.FinalComments?.uploadCourtForm && (
                    <tr>
                      <td className={`ps-4`}>Upload a court form : </td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="uploadCourtForm"
                          onChange={handleClick}
                          defaultValue={data?.FinalComments?.uploadCourtForm}
                        />
                      </td>
                    </tr>
                  )}
                  {data?.FinalComments?.CommentsToDMS && (
                    <tr>
                      <td className={`ps-4`}>Comments to DMS admin team :</td>
                      <td className={`ps-4`}>
                        <input
                          type="text"
                          className="  p-1 rounded border-0"
                          name="CommentsToDMS"
                          onChange={handleClick}
                          defaultValue={data?.FinalComments?.CommentsToDMS}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <button
              onClick={submit}
              className="w-50 mt-5 mb-2 fs-5 m-auto btn btn-info py-2 border-0"
              style={{ backgroundColor: "#040848", color: "white" }}
            >
              {loadind ? <Spinner animation="border" /> : "Confirm"}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditMIAM2Part1;
