import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CaseBtns from "../Case/CaseBtns";
import CaseDrive from "../Case/CaseDrive";
import Data from "./Data";
import { useLocation } from "react-router-dom";
import { Splitter, SplitterPanel } from "primereact/splitter";
import Calendar from "../../components/Calendar/calendar";

const SentAppointmentIConfirmation = () => {
  const location = useLocation();
  let data = location?.state?.data;

  return (
    <>
     <Container fluid className="mt-5 d-lg-block d-none">
     <Row>
          <Col xl={2} className="py-3">
            <CaseBtns />
          </Col>
          <Col className="mt-3" xl={10}>
            <Splitter style={{minHeight:"700px",backgroundColor:"transparent",border:"none"}}>
              <SplitterPanel  style={{overflow:"auto"}}>
                <div
                  className="SplitterPanel d-flex "
               
                >
                 <Data CaseData={data} />
                  <CaseDrive />{" "}
                </div>
              </SplitterPanel>
              <SplitterPanel 
              style={{ overflow: "auto", maxWidth: "400px" }}
              >
                <div
                  className="split-calendar-table"
                  style={{ width: "400px" }}
                >
                  <Calendar isSplitter={true} />
                </div>
              </SplitterPanel>
            </Splitter>
          </Col>
        </Row>
    </Container>
    <Container fluid className="mt-5 d-lg-none">
      <Row>
        <Col xl={2} className="py-3">
          <CaseBtns />
        </Col>
        <Col xl={8}>
          <Data CaseData={data} />
        </Col>
        <Col xl={2} className="py-3">
          {" "}
          <CaseDrive />{" "}
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default SentAppointmentIConfirmation;
