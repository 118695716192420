import React from "react";
import { Button } from "primereact/button";
const CloseBtn = ({ addToRef, rowData, confirmDeleteProduct }) => {
  return (
    <>
      {rowData.closed === false ? (
        <Button
          ref={addToRef}
          id={rowData._id}
          style={{ backgroundColor: "#ff0000", border: 0 }}
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
          disabled={false}
          className="btn_access"
        >
          Close the case
        </Button>
      ) : (
        <Button
          ref={addToRef}
          id={rowData._id}
          style={{ backgroundColor: "#ff0000", border: 0 }}
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
          disabled={true}
          className="btn_access btn_disabled"
        >
          Close the case
        </Button>
      )}
    </>
  );
};

export default CloseBtn;
