import React, { useState, useEffect } from "react";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Styles from "./calendar.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddReminder from "./addReminder";
import UpdateReminder from "./update-delete-Reminder";
import { useGetAllRemindersQuery } from "../../services/auth/authService";
import { useDispatch } from "react-redux";
import { deleteReminder } from "../../features/auth/authActions";

function Calendar({isSplitter}) {
  // const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reminderID, setReminderID] = useState();
  const [clickedEvent, setClickedEvent] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();

  const { data: allReminders, isFetching: isFetchingGetAllReminders } =
    useGetAllRemindersQuery("AllReminders", {
      refetchOnMountOrArgChange: true,
    });
  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
    setErrorMsg();
  };

  const openModal = () => {
    setShowModal(true);
  };
  const openUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  const handleGoBack = () => {
    window.history.back();
  };

  const renderSidebar = () => {
    return (
      <Row>
        {isSplitter=== true ?'':  
       <>
         <Col className={Styles.ccc} xl={4}>
          <Button className={Styles.backButton} onClick={handleGoBack}>
            Back
          </Button>
        </Col>
        <div>
          <Col xl={4} lg={4} md={6} sm={8}>
            <div className="demo-app-sidebar ">
              <div className={Styles.events_Of_Today}>
                <h2 className={Styles.all_Events_Title}>All Events - </h2>
                <h4 className={Styles.all_Events_Title}>
                  {currentEvents.length}
                </h4>
                {currentEvents.length >= 1 ? (
                  <div
                    className="container"
                    style={{
                      maxHeight: showMore ? "none" : "190px",
                      overflow: "hidden",
                    }}
                  >
                    <ul>{currentEvents.map(renderSidebarEvent)}</ul>
                  </div>
                ) : (
                  <p>You don't have any appointments</p>
                )}
             
                {currentEvents.length > 8 ? (
                  <div className={Styles.seeMoreButtonContainer}>
                    <button
                      className={Styles.seeMoreButton}
                      onClick={toggleShowMore}
                    >
                      {showMore ? "See Less" : "See More"}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </div> 
       </> }
       
      </Row>
    );
  };

  const handleDateSelect = (selectInfo) => {
    openModal();
  };

  const handleEventClick = (clickInfo) => {
    setReminderID(clickInfo.event._def.publicId);
    setClickedEvent(clickInfo);
    openUpdateModal();
  };

  const handleDeleteReminder = () => {
    dispatch(deleteReminder(reminderID))
      .then((response) => {
        if (response.payload.res === "you don't have access on this reminder") {
          setErrorMsg(response.payload.res);
        } else {
          closeUpdateModal();
          window.location.reload();
        }
      })
      .catch((error) => {
        // Handle error here
      });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className={Styles.event_indicator}>
        <b>{eventInfo.timeText}</b>
        <div className={Styles.event_indicator_title}>
          <p>{eventInfo.event.title}</p>
        </div>
      </div>
    );
  };

  const renderSidebarEvent = (event, index) => {
    return (
      <li key={index}>
        <b>
          {formatDate(event.start, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </b>
        <span className="mx-2">{event.title}</span>
      </li>
    );
  };

  useEffect(() => {
    if (allReminders) {
      setCurrentEvents(allReminders);
      setIsLoading(false); // Set loading state to false when data is fetched
    }
  }, [allReminders]);

  //  renderEventContent(allReminders)
  useEffect(() => {
    setIsLoading(isFetchingGetAllReminders); // Update loading state based on data fetching status
  }, [isFetchingGetAllReminders, allReminders]);

  if (isFetchingGetAllReminders) {
    return (
      <>
        <div className={Styles.spinner}>
          <Spinner animation="border" />
        </div>
      </>
    );
  } else {
    return (
      <>
        {!isLoading && (
          <Container
            fluid
            className={`pt-4 cases_container ${Styles.calendar_Container} `}
          >
            <AddReminder
              close={() => setShowModal(false)}
              showModal={showModal}
            />
            {showUpdateModal && (
              <UpdateReminder
                close={closeUpdateModal}
                delete={handleDeleteReminder}
                eventData={clickedEvent}
                erorrs={errorMsg}
              />
            )}
            <div className="demo-app">
              {renderSidebar()}
              <div className="demo-app-main">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  // weekends={weekendsVisible}
                  initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
                  select={handleDateSelect}
                  eventContent={renderEventContent} // custom render function
                  eventClick={handleEventClick}
                  eventsSet={handleEvents} // called after events are initialized/added/changed/remove
                />
              </div>
            </div>
          </Container>
        )}
      </>
    );
  }
}
export default Calendar;
