import React from "react";
import Table from "react-bootstrap/Table";
import style from "../casemaim/Case.module.css";

const OtherPersonData = ({ data }) => {
  return (
    <div className={`${style.casew} ms-2`}>
      <Table className={`${style.table} `}>
        <tbody className="tbody">
          {data?.otherParty && (
            <tr>
              <td className={`${style.title} ps-4`}>Other Person's Details</td>
            </tr>
          )}

          {data?.personalContactAndCaseInfo?.otherParty && (
            <tr>
              <td className={`ps-4`}>
                Other party in the conflict :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo?.otherParty}
                </span>
              </td>
            </tr>
          )}

          {data?.otherParty?.otherPartyFirstName && (
            <tr>
              <td className={`ps-4`}>
                First Name :{" "}
                <span className="ps-2">
                  {data.otherParty?.otherPartyFirstName}
                </span>
              </td>
            </tr>
          )}
          {data?.otherParty?.otherPartySurname && (
            <tr>
              <td className="ps-4">
                Surname :{" "}
                <span className="ps-2">
                  {data?.otherParty?.otherPartySurname}
                </span>
              </td>
            </tr>
          )}
          {data?.otherParty?.otherPartyDateOfBirth && (
            <tr>
              <td className="ps-4">
                Date of birth :{" "}
                <span className="ps-2">
                  {data?.otherParty?.otherPartyDateOfBirth}
                </span>
              </td>
            </tr>
          )}
          {data?.otherParty?.otherPartyPhone && (
            <tr>
              <td className="ps-4">
                Telephone :{" "}
                <span className="ps-2">{data?.otherParty?.otherPartyPhone}</span>
              </td>
            </tr>
          )}
          {data?.otherParty?.otherPartyEmail && (
            <tr>
              <td className="ps-4">
                Email :{" "}
                <span className="ps-2">{data.otherParty?.otherPartyEmail}</span>
              </td>
            </tr>
          )}
          {data?.otherParty?.otherPartyStreet && (
            <tr>
              <td className="ps-4">
                Address :{" "}
                <span className="ps-2">
                  {data.otherParty?.otherPartyStreet +
                    " , " +
                    data.otherParty?.otherPartyCity +
                    " , " +
                    data.otherParty?.otherPartyCountry +
                    " , " +
                    data.otherParty?.otherPartyPostalCode}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default OtherPersonData;
