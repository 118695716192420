import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsFillBellFill } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Spinner } from "react-bootstrap";

import logo from "../images/dms-logotype2.webp";
import user_img from "../images/user img .png";
import { logout } from "../features/auth/authSlice";
import { useGetAllRemindersQuery } from "../services/auth/authService";

const NavBar = () => {
  const [todayEvents, setTodayEvents] = useState([]);
  const [notificationNumber, setNotificationNumber] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const { data: allReminders, isFetching: isFetchingGetAllReminders } =
    useGetAllRemindersQuery("AllReminders", {
      refetchOnMountOrArgChange: true,
    });

  const today = new Date();
  const todayDateString = today.toISOString().split("T")[0];

  const filteredEvents = todayEvents.filter((event) => {
    if (event.start) {
      const eventDate = event.start.split("T")[0];
      return eventDate === todayDateString;
    }
    return false;
  });

  useEffect(() => {
    setIsLoading(isFetchingGetAllReminders);
    if (token) {
      if (allReminders) {
        setTodayEvents(allReminders);
        setNotificationNumber(filteredEvents.length);
        setIsLoading(false);
      }
    }
  }, [allReminders, filteredEvents, token, isFetchingGetAllReminders]);

  useEffect(() => {}, [token]);
  const manageLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const notifyIcon = () => {
    return (
      <span style={{ flexWrap: "nowrap" }}>
        <BsFillBellFill
          style={{
            fontSize: "1.8rem",
            color: "040848",
            marginTop: "5px",
          }}
        />
        <FaCircle
          style={{
            fontSize: "1.25rem",
            color: "red",
            marginLeft: "-15",
            marginTop: "-10px",
            border: "2px solid white",
            borderRadius: "10px",
          }}
        />
        <p
          style={{
            fontSize: "0.55rem",
            fontWeight: "bold",
            color: "white",
            marginLeft: "14px",
            marginTop: "-28px",
          }}
        >
          {notificationNumber}
        </p>
      </span>
    );
  };

  return (
    <>
      <Navbar expand="lg" className=" fixed-top py-0" id="Navbar">
        <Container fluid>
          <Nav className="w-100">
            <Nav.Item className="Nav--light d-flex justify-content-lg-start justify-content-between my-2">
              <img src={logo} alt="" className="logo_img" />
              <Navbar.Toggle aria-controls="basic-navbar-nav " />
            </Nav.Item>
          </Nav>
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className=" w-50  d-flex justify-content-lg-end text-center w-100 ">
              <Nav.Item className=" Nav--light  py-0" to="">
                {token ? (
                  <div className="d-flex flex-lg-row flex-column justify-content-center align-items-center ">
                    <NavDropdown
                      className="px-4"
                      title={notifyIcon()}
                      id="scrollable-dropdown"
                      style={{ marginBottom: "5px" }}
                    >
                      <div
                        style={{
                          overflowY: "auto",
                          maxHeight: "400px",
                          maxWidth: "500px",
                        }}
                      >
                        {isLoading && <Spinner />}
                        {filteredEvents.map((reminder, index) => {
                          return (
                            <div key={index}>
                              <NavDropdown.Item
                                id="scrollable-dropdown"
                                href="#action/3.1"
                                style={{
                                  maxWidth: "200px",
                                }}
                                className="text-wrap text-break"
                              >
                                {reminder.title}
                              </NavDropdown.Item>
                              <NavDropdown.Divider />
                            </div>
                          );
                        })}
                      </div>
                    </NavDropdown>
                    <div className=" w-100">
                      <button
                        onClick={manageLogout}
                        className="login-button py-2 px-4 w-100"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <img src={user_img} alt="" className="img_user" />
                  </>
                )}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
