import React, { useRef, useState } from "react";
import styles from "./Case.module.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadFiles } from "../../features/auth/authActions";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";

const UploadFiles = ({ showUploadFiles, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const {id} =useParams();
  const [pdfData, setpdfData] = useState([]);
  const dispatch=useDispatch()
  const handleChange = (file) => {
   
    setpdfData([...pdfData,file]);
    
  };

  const onSubmit=()=>{
    setIsLoading(true);
    dispatch(uploadFiles({id,pdfData})).then((res) => {
      if (res.payload.status === 200) {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.message,
          life: 1500,
        });
      }else{
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 1500,
        });
      }
    })
  }
  return (
    <> <Toast ref={toast} />
      <Modal
        show={showUploadFiles}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          
        </div>
        <FileUploader handleChange={handleChange} name="file">
          <div className={`px-4 pb-4 m-3 ${styles.uploadContainer} `}>
            <AiOutlineCloudUpload className={`${styles.CloudUpload}`} />
            {pdfData.length !==0 ? (
              pdfData?.map((ele,i)=>{  return ( <h6 key={i}>{ele?.name}</h6>)   })
            
            ) : (
              <>
                <h6>Drag and Drop files here</h6>
              </>
            )}
          </div>
        </FileUploader>
      
        {pdfData.length >0 &&   <button className={`${styles.browseBtn} py-2 my-3 m-auto`} type="submit" onClick={()=>onSubmit()}>
        {isLoading ? <Spinner animation="border" /> : "Upload"}
              
            </button> }
      </Modal>
    </>
  );
};

export default UploadFiles;
