import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import Layout from './components/Layout'
import Cases from './pages/Cases'
import LoginForm from './pages/loginform'
import RegisterForm from './pages/RegisterForm'
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoute'
import AddMediatorProtectedRoute from './components/ProtectedRoutes/AddMediatorProtectedRoute'
import Case from './pages/Case'
import AccessMaimC1 from './pages/AccessMaimC1'
import AccessMaimC2 from './pages/AccessMaimC2'
import AccessMiamPart1 from './pages/AccessMiamPart1'
import AccessMiamPart2 from './pages/AccessMiamPart2'
import Calendar from './components/Calendar/calendar'
import Addmediator from './pages/Add_mediator_form/Addmediatorform'
import Dashboard from './pages/Dashboard'
import AdimnProtectedRoute from './components/Dashboard/ProtectedRoute'
import { AdminLogin } from './pages/Admin_login'
import EditMIAM1Part1 from './components/casemaim/EditMIAM1Part1'
import EditMIAM2Part1 from './components/casemaim2/EditMIAM2Part1'
import DatesForMediation from './components/DatesForMediation/DatesForMediation'
import SentAppointmentIConfirmation from './components/SentAppointmentIConfirmation/SentAppointmentIConfirmation'
import ChatSMS from './components/ChatSMS/ChatSMS'
import MediationSessionRecordItems from './pages/MediationSessionRecordItems'
import PageNotFound from './components/PageNotFound/PageNotFound'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import LegalAidTable from './components/legalAidTable/legalAidTable'
import LegalAidDataTableMarketing from './components/legalAidTable/LegalAidDataTableMarketing'

function App () {
  const { token } = useSelector(state => state.auth)
  const { token: adminToken } = useSelector(state => state.admin)

  return (
    <>
      <Layout />
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<Cases />} />
        </Route>
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route
          path='/login'
          element={token ? <Navigate to='/'></Navigate> : <LoginForm />}
        />
        <Route
          path='/admin/login'
          element={
            adminToken ? (
              <Navigate to='/admin/dashboard'></Navigate>
            ) : (
              <AdminLogin />
            )
          }
        />
        <Route path='/register' element={<RegisterForm />} />
        <Route element={<ProtectedRoute />}>
          <Route index={true} element={<Cases />} />
        </Route>
        <Route element={<AddMediatorProtectedRoute />}>
          <Route path='/addmediator' element={<Addmediator />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route
            path='/sentAppointmentConfirmation/:id'
            element={<SentAppointmentIConfirmation />}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/case/:id' element={<Case />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path='/legalAidDataTable'
            element={<LegalAidDataTableMarketing />}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path='/datesForMediation/:id'
            element={<DatesForMediation />}
          />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path='/case/maim1/:id' element={<AccessMaimC1 />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path='/case/maim2/:id' element={<AccessMaimC2 />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path='/case/MIAM1/:id' element={<AccessMiamPart1 />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path='/case/MediationSessionRecordItems/:id'
            element={<MediationSessionRecordItems />}
          />
        </Route>

        <Route path='/calendar' element={<Calendar />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/case/MIAM2/:id' element={<AccessMiamPart2 />} />
        </Route>
        <Route element={<AdimnProtectedRoute />}>
          <Route path='/admin/dashboard' element={<Dashboard />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/edit/C1/:id' element={<EditMIAM1Part1 />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/edit/C2/:id' element={<EditMIAM1Part1 />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/editMIAM/C1/:id' element={<EditMIAM2Part1 />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/editMIAM/C2/:id' element={<EditMIAM2Part1 />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/chatSMS/:id' element={<ChatSMS />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/legalAidTable' element={<LegalAidTable />} />
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      {/* <Route element={<ProtectedRoute />}>

      </Route> */}
    </>
  )
}

export default App
