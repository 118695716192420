import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import Layout from "../components/Layout";
import FormCard from "../components/FormCard";
import { resetPassword } from "../features/auth/authActions";

const ResetPassword = () => {
  const toast = useRef(null);
  const [passwordValue, setpasswordValue] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [conirmnewPassword, setconirmconirmnewPassword] = useState("");
  const [conirmPasswordError, setconirmPasswordError] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [error, setError] = useState("");
  const { resetPasswordSuccess } = useSelector((state) => state.auth);
  const { handleSubmit } = useForm();

  // const passwordValue = watch("newPassword");
  // const confirmPasswordValue = watch("confirm_password");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    setToken(token); // You can use the token value as needed
    // Add your logic here to handle the token
    // For example, you can make an API request to validate the token and render the appropriate UI
  }, [location]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      navigate("/login");
    }
  }, [navigate, resetPasswordSuccess]);

  const onSubmit = (data) => {
    if (passwordValue?.length === 0) {
      setPasswordError("Password is required");
    }
    if (conirmnewPassword?.length === 0) {
      setconirmPasswordError("confirm new Password is required");
    }
    if (passwordValue !== conirmnewPassword) {
      setError("Password Not Matching");
    } else if (passwordValue.length !== 0) {
      setError("");
      dispatch(
        resetPassword({ resetToken: token, newPassword: conirmnewPassword })
      ).then((res) => {
        if (res.payload.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.payload.data.message,
            life: 1500,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail:
              res.payload === "Invalid reset token" &&
              "Token Expired, Please Enter Your Email Again",
            life: 1500,
          });
        }
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Layout />
      <Container fluid>
        <Row>
          <FormCard />
          <Col lg={8} className="p-0">
            <Container className="card-container-form ">
              <Container fluid className="form-container-authentication">
                <Col lg={6}>
                  <h1 className="form-header-authentication">Reset Password</h1>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label className="form-label">New Password:</label>
                    {/* <input
                      className="form-input"
                      type="password"
                      placeholder="Password"
                      {...register("newPassword", { required: true })}
                    />
                    {errors.newPassword &&
                      errors.newPassword.type === "required" && (
                        <p className="errorMsg">Password is required</p>
                      )} */}
                    <Password
                      className="form-input AvailabilityForm ps-0"
                      placeholder="Password"
                      toggleMask
                      feedback={false}
                      value={passwordValue}
                      onChange={(e) => setpasswordValue(e.target.value)}
                    />
                    {passwordValue?.length === 0 && (
                      <small className="text-danger">
                        <span>{PasswordError}</span>
                      </small>
                    )}
                    <label className="form-label">Confirm New Password:</label>
                    {/* <input
                      className="form-input"
                      type="password"
                      placeholder="Confirm Password"
                      {...register("confirm_password", { required: true })}
                    />
                    {errors.confirm_password &&
                      errors.confirm_password.type === "required" && (
                        <p className="errorMsg">Password is required</p>
                      )} */}
                    <Password
                      className="form-input AvailabilityForm ps-0"
                      placeholder="confirm password"
                      toggleMask
                      feedback={false}
                      value={conirmnewPassword}
                      onChange={(e) =>
                        setconirmconirmnewPassword(e.target.value)
                      }
                    />
                    {conirmnewPassword?.length === 0 && (
                      <small className="text-danger">
                        <span>{conirmPasswordError}</span>
                      </small>
                    )}
                    <p className="errorMsg">{error}</p>
                    <button className="login-button py-2 mt-3" type="submit">
                      Reset
                    </button>
                  </form>
                </Col>
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
