import { NavLink, Outlet } from "react-router-dom";

const AdimnProtectedRoute = () => {
  const adminToken = localStorage.getItem("adminLoginToken");
  // show unauthorized screen if no user is found in redux store
  if (adminToken) {
    // returns child route elements
    return <Outlet />;
  } else if (!adminToken) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-1 fw-bold">401</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span> Unauthorized.
          </p>

          <NavLink to="/admin/login" className="btn btn-primary">
            Login
          </NavLink>
        </div>
      </div>
    );
  }
};
export default AdimnProtectedRoute;
