import Table from "react-bootstrap/Table";

import style from "../casemaim/Case.module.css";

const ChildDetails = ({ data }) => {
  let children = data;
  return (
    <div className={`${style.casew} ms-2`}>
      {children?.map((value, idx) => {
        let childFirstName;
        let childSurName;
        let childDateOfBirth;
        let childGender;
        let childLivingWith;
        let isChildHaveSpecialNeeds;
        let childSpecialNeeds;
        let childResponsibility;
        switch (idx) {
          case 0:
            childFirstName = `firstChildFirstName`;
            childSurName = `firstChildSurName`;
            childDateOfBirth = `firstChildDateOfBirth`;
            childGender = `firstChildGender`;
            childLivingWith = `firstChildLivingWith`;
            isChildHaveSpecialNeeds = `isfirstChildHaveSpecialNeeds`;
            childSpecialNeeds = `firstChildSpecialNeeds`;
            childResponsibility = `firstChildResponsibility`;

            break;
          case 1:
            childFirstName = `secondChildFirstName`;
            childSurName = `secondChildSurName`;
            childDateOfBirth = `secondChildDateOfBirth`;
            childGender = `secondChildGender`;
            childLivingWith = `secondChildLivingWith`;
            isChildHaveSpecialNeeds = `issecondChildHaveSpecialNeeds`;
            childSpecialNeeds = `secondChildSpecialNeeds`;
            childResponsibility = `secondChildResponsibility`;

            break;
          case 2:
            childFirstName = `thirdChildFirstName`;
            childFirstName = `thirdChildFirstName`;
            childSurName = `thirdChildSurName`;
            childDateOfBirth = `thirdChildDateOfBirth`;
            childGender = `thirdChildGender`;
            childLivingWith = `thirdChildLivingWith`;
            isChildHaveSpecialNeeds = `isthirdChildHaveSpecialNeeds`;
            childSpecialNeeds = `thirdChildSpecialNeeds`;
            childResponsibility = `thirdChildResponsibility`;
            break;
          case 3:
            childFirstName = `fourthChildFirstName`;
            childFirstName = `fourthChildFirstName`;
            childSurName = `fourthChildSurName`;
            childDateOfBirth = `fourthChildDateOfBirth`;
            childGender = `fourthChildGender`;
            childLivingWith = `fourthChildLivingWith`;
            isChildHaveSpecialNeeds = `isfourthChildHaveSpecialNeeds`;
            childSpecialNeeds = `fourthChildSpecialNeeds`;
            childResponsibility = `fourthChildResponsibility`;
            break;
          case 4:
            childFirstName = `fifthChildFirstName`;
            childFirstName = `fifthChildFirstName`;
            childSurName = `fifthChildSurName`;
            childDateOfBirth = `fifthChildDateOfBirth`;
            childGender = `fifthChildGender`;
            childLivingWith = `fifthChildLivingWith`;
            isChildHaveSpecialNeeds = `isfifthChildHaveSpecialNeeds`;
            childSpecialNeeds = `fifthChildSpecialNeeds`;
            childResponsibility = `fifthChildResponsibility`;
            break;
          case 5:
            childFirstName = `sixthChildFirstName`;
            childFirstName = `sixthChildFirstName`;
            childSurName = `sixthChildSurName`;
            childDateOfBirth = `sixthChildDateOfBirth`;
            childGender = `sixthChildGender`;
            childLivingWith = `sixthChildLivingWith`;
            isChildHaveSpecialNeeds = `issixthChildHaveSpecialNeeds`;
            childSpecialNeeds = `sixthChildSpecialNeeds`;
            childResponsibility = `sixthChildResponsibility`;
            break;
          default:
            break;
        }
        return (
          <Table className={`${style.table} `} key={idx}>
            <tbody>
              {data && (
                <tr>
                  <td className={`${style.title} ps-4`}>
                    {Object.entries(value)[0][0]}'s Details
                  </td>
                </tr>
              )}
              {`${Object.entries(value)[0][1][`${childFirstName}`]}` && (
                <tr>
                  <td className={`ps-4`}>
                    First Name :{" "}
                    <span className="ps-2">
                      {`${Object.entries(value)[0][1][`${childFirstName}`]}`}
                    </span>
                  </td>
                </tr>
              )}
              {`${Object.entries(value)[0][1][`${childSurName}`]}` && (
                <tr>
                  <td className="ps-4">
                    Surname :{" "}
                    <span className="ps-2">
                      {`${Object.entries(value)[0][1][`${childSurName}`]}`}
                    </span>
                  </td>
                </tr>
              )}
              {`${Object.entries(value)[0][1][`${childDateOfBirth}`]}` && (
                <tr>
                  <td className="ps-4">
                    Date of birth :{" "}
                    <span className="ps-2">
                      {`${Object.entries(value)[0][1][`${childDateOfBirth}`]}`}
                    </span>
                  </td>
                </tr>
              )}
              {`${Object.entries(value)[0][1][`${childGender}`]}` && (
                <tr>
                  <td className="ps-4">
                    Gender :{" "}
                    <span className="ps-2">
                      {`${Object.entries(value)[0][1][`${childGender}`]}`}
                    </span>
                  </td>
                </tr>
              )}
              {`${Object.entries(value)[0][1][`${childLivingWith}`]}` && (
                <tr>
                  <td className="ps-4">
                    Living mostly with :{" "}
                    <span className="ps-2">
                      {`${Object.entries(value)[0][1][`${childLivingWith}`]}`}
                    </span>
                  </td>
                </tr>
              )}
              {`${
                Object.entries(value)[0][1][`${isChildHaveSpecialNeeds}`]
              }` && (
                <tr>
                  <td className="ps-4">
                    Special needs/health issues :{" "}
                    <span className="ps-2">
                      {`${
                        Object.entries(value)[0][1][
                          `${isChildHaveSpecialNeeds}`
                        ]
                      }` === "Yes"
                        ? "Yes" +
                          " ( " +
                          `${
                            Object.entries(value)[0][1][`${childSpecialNeeds}`]
                          }` +
                          " )"
                        : "No"}
                    </span>
                  </td>
                </tr>
              )}
              {`${Object.entries(value)[0][1][`${childResponsibility}`]}` && (
                <tr>
                  <td className="ps-4">
                    Parental responsibility of the child :{" "}
                    <span className="ps-2">
                      {`${
                        Object.entries(value)[0][1][`${childResponsibility}`]
                      }`}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      })}
    </div>
  );
};

export default ChildDetails;
