import { Button, Modal } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";

import style from "./Case.module.css";

export const DisplayAllCaseLogs = ({
  close,
  DisplayCaseBodyLog,
  CaseData,
  id,
}) => {
  return (
    <Modal
      show={DisplayCaseBodyLog}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className=" border-0 d-flex justify-content-end">
        <div className={`  pb-0  `}>
          <Button
            onClick={close}
            type="button"
            className={`bg-transparent border-0`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="#040848"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </Button>
        </div>
      </div>
      <div className="px-4 pb-4 ">
        <div className="d-flex align-items-end justify-content-center">
          <div className={`${style.line} w-50 me-3`}></div>
          <FaQuoteLeft className=" m-auto fs-4" />
          <div className={`${style.line} w-50 ms-3`}></div>
        </div>
        {CaseData?.caseLogs.map((caseLog,i) => {
          const date = caseLog?.date.split("T")[0];
          const dateComponents = date?.split("-");
          const year = dateComponents && dateComponents[0];
          const month = dateComponents && dateComponents[1];
          const day = dateComponents && dateComponents[2];

          const newDateFormat = day + "/" + month + "/" + year;
          return (
            <div key={i}>
              <p className={`${style.logBody} my-4`} > {caseLog.logBody}</p>
              <div className="d-flex align-items-start flex-column ">
                <p>
                  <span className={style.CaseLogBySpan}>by :</span>{" "}
                  {caseLog?.by}
                </p>
                <p>
                  <span className={`${style.CaseLogBySpan}`}>at :</span>{" "}
                  {newDateFormat}
                </p>
              </div>
              <div className={style.line}></div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
