import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import style from "../Case/Case.module.css";
import MoreDetails from "./MoreDetails";

const DetailsC2 = ({ CaseData }) => {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ only show element on click
    setIsShown((current) => !current);
  };

  return (
    <div>
    <div className="mx-lg-4 mx-1 mt-5 position-relative">
      <Table className={`${style.tableDetails} `} style={{zIndex:120}}>
        <tbody>
          <tr className={style.first_td}>
            <td className={`${style.first_td} ps-4 pt-4`}>
              First Name :{" "}
              <span className="ps-2">
                {CaseData ? CaseData?.majorDataC2FromM1?.otherPartyFirstName : ""}
              </span>
            </td>
          </tr>

          {CaseData?.majorDataC2FromM1?.otherPartySurname && (
            <tr>
              <td className="ps-4">
                Surname : <span className="ps-2">{CaseData?.majorDataC2FromM1?.otherPartySurname}</span>
              </td>
            </tr>
          )}
          {/* {CaseData?.typeOfFunding && (
            <tr>
              <td className="ps-4">
                Type of funding :{" "}
                <span className={`${style.funding} ms-2`}>
                  {CaseData.typeOfFunding}
                </span>
              </td>
            </tr>
          )}
          {CaseData?.otherFunding && (
            <tr>
              <td className="ps-4">
                Other funding :{" "}
                <span className="ps-2">{CaseData.otherFunding}</span>
              </td>
            </tr>
          )}
          {CaseData?.miamSutability && (
            <tr>
              <td className="ps-4">
                MIAM suitability :{" "}
                <span className="ps-2">{CaseData.miamSutability}</span>
              </td>
            </tr>
          )}
          {CaseData?.documents && (
            <tr>
              <td className="ps-4">
                Documents on file : <span className="ps-2">(List)</span>
              </td>
            </tr>
          )} */}

          <tr>
            <td
              className={`${style.last_td} text-center text-decoration-underline border-bottom-0`}
              role="button"
              onClick={handleClick}
            >
              More Details
            </td>
          </tr>
          <tr className={`${style.header}`}>
            <td>C2 Details : {CaseData ? CaseData?.majorDataC2FromM1?.otherPartyFirstName:""}</td>
          </tr>
        </tbody>
      </Table>
      {isShown && <MoreDetails isShown={isShown} data={CaseData} />}
    </div>
  </div>
  );
};

export default DetailsC2;
