import { createSlice } from "@reduxjs/toolkit";
import {
  addClient
} from "./authActions";

const initialState = {
  loading: false,
  caseInfo: null, // for user object
  error: null,
  success: false,
};

const caseSlice = createSlice({
  name: "Case",
  initialState,
  reducers: {},
  extraReducers: {
    // add client
    [addClient.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [addClient.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.caseInfo = payload;
      state.success = true;
    },
    [addClient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default caseSlice.reducer;
