import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { addMediator } from "../../features/auth/authActions";
import Styles from "./Addmediator.module.css";

function Addmediator(props) {
  const [errorMsg, setErrorMsg] = useState("");
  const { mediatorLoading } = useSelector((state) => state.auth);
  const defaultValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(addMediator(data))
      .then((response) => {
        if (response?.error?.message === "Rejected") {
          setErrorMsg(response.payload);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container fluid className={`${Styles.container} mt-5`}>
      <h4 className={Styles.form_header}>Add Mediator</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className={Styles.inputs_container}>
          <Col lg="4" sm="8">
            <label className={`form-label ${Styles.form_label}`}>
              First Name:
            </label>
            <input
              className="form-input"
              type="text"
              placeholder="First Name"
              {...register("firstName", {
                required: true,
              })}
            />
            {errors.firstName && errors.firstName.type === "required" && (
              <p className="errorMsg">First name is required</p>
            )}
          </Col>
          <Col lg="4" sm="8">
            <label className={`form-label ${Styles.form_label}`}>
              Last Name:
            </label>
            <input
              className="form-input"
              placeholder="Last Name"
              {...register("lastName", {
                required: true,
              })}
            />
            {errors.lastName && errors.lastName.type === "required" && (
              <p className="errorMsg">Last name is required</p>
            )}
          </Col>
        </Row>
        <Row className={Styles.inputs_container}>
          <Col lg="4" sm="8">
            <label className={`form-label ${Styles.form_label}`}>
              Email address:
            </label>
            <input
              className="form-input"
              type="email"
              placeholder="E-mail"
              {...register("email", {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
            />
            {errors.email && errors.email.type === "required" && (
              <p className="errorMsg">Email is required</p>
            )}
          </Col>
          <Col lg="4" sm="8">
            <label className={`form-label ${Styles.form_label}`}>
              Phone Number:
            </label>
            <p className="text-primary fs-6 m-0 p-0">
              value match UK phone number format +447123456789, 07123456789
            </p>
            <input
              className="form-input"
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              {...register("phoneNumber", {
                required: true,
                pattern: {
                  value: /^0[1-9]\d{8}$/,
                  message:
                    "Please enter the phone number with the following format: 0712345678",
                },
              })}
            />

            {errors.phoneNumber && errors.phoneNumber.type === "required" && (
              <p className="errorMsg">Phone number is required</p>
            )}
            {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (
              <p className="errorMsg">{errors.phoneNumber.message}</p>
            )}
          </Col>
        </Row>
        <div className={Styles.AddmediatorButtonContainer}>
          {errorMsg !== "" && <p className="text-danger">{errorMsg}</p>}
          <div className={Styles.warning_Box}>
            <button className={Styles.AddmediatorButton} type="submit">
              {mediatorLoading ? <Spinner animation="border" /> : "Add"}
            </button>
            <NavLink className={Styles.cancel_Button} to="/">
              <div className={Styles.cancel_Button_Text}>Cancel</div>
            </NavLink>
          </div>
        </div>
      </form>
    </Container>
  );
}
export default Addmediator;
