import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CaseDrive from "../components/Case/CaseDrive";
import CaseBtns from "../components/Case/CaseBtns";
import MiamDetails from "../components/AccessMIAM1/MaimDetails";
import { useParams } from "react-router-dom";
import { Splitter, SplitterPanel } from "primereact/splitter";
import Calendar from "../components/Calendar/calendar";

const AccessMiamPart1 = () => {
  const { id } = useParams();
  return (
    <>
    <Container fluid className="mt-5 caseSpliter d-lg-block d-none">
        <Row>
          <Col xl={2} className="py-3">
            <CaseBtns />
          </Col>
          <Col className="mt-3" xl={10}>
            <Splitter style={{minHeight:"700px",backgroundColor:"transparent",border:"none"}}>
              <SplitterPanel  style={{overflow:"auto"}}>
                <div
                  className="SplitterPanel d-flex"
               
                >
                   <MiamDetails id={id}/>
                  <CaseDrive />{" "}
                </div>
              </SplitterPanel>
              <SplitterPanel 
              style={{ overflow: "auto", maxWidth: "400px" }}
              >
                <div
                  className="split-calendar-table"
                  style={{ width: "400px" }}
                >
                  <Calendar isSplitter={true} />
                </div>
              </SplitterPanel>
            </Splitter>
          </Col>
        </Row>
      </Container>
      <Container fluid className=" mt-5 d-lg-none">
        <Row>
          <Col xl={2} className="py-3">
            <CaseBtns />
          </Col>
          <Col xl={8}>
            <MiamDetails id={id}/>
            
          </Col>
          <Col xl={2} className='py-3'> <CaseDrive/> </Col>
        </Row>
      </Container>
    </>
  );
};

export default AccessMiamPart1;