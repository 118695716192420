import { createSlice } from "@reduxjs/toolkit";
import {
  addNewCompany,
    adminLogin
} from "./authActions";

// initialize userToken from local storage
const token = localStorage.getItem("adminLoginToken") 
        ? localStorage.getItem("adminLoginToken")  : null

const initialState = {
  loading: false,
  caseInfo: null, // for user object
  error: null,
  success: false,
  token
};

const adminLoginSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("adminLoginToken"); // deletes token from storage
      state.loading = false;
      state.caseInfo = null;
      state.success = false;
      state.error = null;
      state.token = null
    },
  },
  extraReducers: {
  
    [adminLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [adminLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.caseInfo = payload;
      state.success = true;
      state.token = payload.accessToken
    },
    [adminLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    //add company
    [addNewCompany.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [addNewCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.caseInfo = payload;
      state.success = true;
    },
    [addNewCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { logout } = adminLoginSlice.actions;
export default adminLoginSlice.reducer;