import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);

  // show unauthorized screen if no user is found in redux store
  if (!token) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-1 fw-bold">401</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span> Unauthorized.
          </p>

          <NavLink to="/login" className="btn btn-primary">
            Login
          </NavLink>
        </div>
      </div>
    );
  }

  // returns child route elements
  return <Outlet />;
};
export default ProtectedRoute;
