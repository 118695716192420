import React, { useState } from "react";
import Table from "react-bootstrap/Table";

import style from "../Case/Case.module.css";
import DisplayMoreDetails from "./DisplayMoreDetails";

const DetailsC1 = ({ MIAM1_C1 }) => {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ only show element on click
    setIsShown((current) => !current);
  };

  return (
    <div>
      <div className="mx-lg-4 mx-1 mt-5 position-relative">
        <Table className={`${style.tableDetails} `} style={{ zIndex: 120 }}>
          <tbody>
            <tr className={style.first_td}>
              <td className={`${style.first_td} ps-4 pt-4`}>
                First Name :{" "}
                <span className="ps-2">
                  {MIAM1_C1 ? MIAM1_C1.firstName : ""}
                </span>
              </td>
            </tr>
            {MIAM1_C1?.surName && (
              <tr>
                <td className="ps-4">
                  Surname : <span className="ps-2">{MIAM1_C1.surName}</span>
                </td>
              </tr>
            )}
            {MIAM1_C1?.typeOfFunding && (
              <tr>
                <td className="ps-4">
                  Type of funding :{" "}
                  <span className={`${style.funding} ms-2`}>
                    {MIAM1_C1.typeOfFunding}
                  </span>
                </td>
              </tr>
            )}
            {MIAM1_C1?.otherFunding && (
              <tr>
                <td className="ps-4">
                  Other funding :{" "}
                  <span className="ps-2">{MIAM1_C1.otherFunding}</span>
                </td>
              </tr>
            )}
            {MIAM1_C1?.miamSutability && (
              <tr>
                <td className="ps-4">
                  MIAM suitability :{" "}
                  <span className="ps-2">{MIAM1_C1.miamSutability}</span>
                </td>
              </tr>
            )}
            {MIAM1_C1?.documents && (
              <tr>
                <td className="ps-4">
                  Documents on file : <span className="ps-2">(List)</span>
                </td>
              </tr>
            )}

            <tr>
              <td
                className={`${style.last_td} text-center text-decoration-underline border-bottom-0`}
                role="button"
                onClick={handleClick}
              >
                More Details
              </td>
            </tr>
            <tr className={`${style.header}`}>
              <td>C1 Details : {MIAM1_C1 ? MIAM1_C1.firstName : ""}</td>
            </tr>
          </tbody>
        </Table>
        {isShown && <DisplayMoreDetails isShown={isShown} data={MIAM1_C1} />}
      </div>
    </div>
  );
};

export default DetailsC1;
