import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";

import MaimInfo2 from "./maim";
import Details from "../Case/Details";
import NoDataComponent from "../NoDataComponent/NoDataComponent";

import style from "./Case.module.css";

import useScrollTo from "../../hooks/useScrollTo";

import { useGetCaseDetailsQuery } from "../../services/auth/authService";

const MiamDetails = ({ id }) => {
  useScrollTo(0, 0, "smooth");
  const [CaseData, SetCaseData] = useState(null);
  const { data: CaseDetails, isFetching: fetchingCaseDetails } =
    useGetCaseDetailsQuery(id, {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true, // 15mins
    });
  useEffect(() => {
    if (CaseDetails) {
      SetCaseData(CaseDetails);
    }
  }, [CaseDetails, CaseData]);

  if (fetchingCaseDetails) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <>
      <Row className="mt-3 d-flex justify-content-center">
        <div className="d-flex justify-content-end">
          <Link
            className={`${style.BackBtn}  my-3 text-white text-decoration-none `}
            to={{ pathname: `/case/${id}` }}
          >
            <div className=" rounded">
              <TiArrowBack className="fs-3" />{" "}
              <span
                style={{
                  color: "white",
                  fontWeight: "bold",
                  paddingTop: "20px",
                }}
                className="hideTheElement"
              >
                Back
              </span>
            </div>
          </Link>{" "}
        </div>
        <Details
          status={CaseData?.status}
          startDate={CaseData?.MIAMDates}
          Reference={CaseData?.Reference}
          isSuitable={CaseData?.caseSuitable}
        />
        <Col>
          <div className={`${style.parent} mt-5`}>
            <div className="d-flex align-items-center justify-content-between ps-1 w-100 mb-4">
              <p className={`${style.titleMiam} ms-3 mb-0`}>C2 MIAM Part 2</p>

              {CaseData?.MIAM2_C2 === "Data didn't added yet" ? (
                <Link
                  disabled
                  className={`${style.EditBtn} me-3 text-white text-decoration-none bg-secondary`}
                >
                  <div className=" rounded">
                    <AiFillEdit className="fs-5 me-2" />{" "}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        paddingTop: "20px",
                      }}
                      className="hideTheElement"
                    >
                      Edit
                    </span>
                  </div>
                </Link>
              ) : (
                <Link
                  className={`${style.EditBtn} me-3 text-white text-decoration-none `}
                  to={{ pathname: `/editMIAM/C2/${id}` }}
                  state={{ data: CaseData?.MIAM2_C2 }}
                >
                  <AiFillEdit className="fs-5 me-2" />{" "}
                  <span
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      paddingTop: "20px",
                    }}
                    className="hideTheElement"
                  >
                    Edit
                  </span>
                </Link>
              )}
            </div>
            {CaseDetails?.MIAM2_C2 === "Data didn't added yet" ? (
              <NoDataComponent />
            ) : (
              <MaimInfo2 data={CaseData?.MIAM2_C2} />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MiamDetails;
