import { combineReducers } from "redux";
import { authApi } from "../services/auth/authService";
import authSlice from "./auth/authSlice";
import caseSlice from "./auth/caseSlice";
import adminLoginSlice from "./auth/adminLoginSlice";
import { adminAuth } from "../services/auth/companyService";

export default combineReducers({
  auth: authSlice,
  Case: caseSlice,
  admin: adminLoginSlice,
  [authApi.reducerPath]: authApi.reducer,
  [adminAuth.reducerPath]:adminAuth.reducer
});
