import React, { useEffect, useState } from "react";
import styles from "../AdminLogin/admin.module.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Error from "../Error";
import { useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import { adminLogin } from "../../features/auth/authActions";

const AdminLoginD = () => {
  const { loading, error, success } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordValue, setpasswordValue] = useState("");
  const [PasswordError, setPasswordError] = useState("");

  useEffect(() => {
    if (success) {
      navigate("/admin/dashboard");
    } else {
      navigate("/admin/login");
    }
  }, [navigate, success]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const registerOptions = {
    email: { required: "Email is required" },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
  };

  const onSubmit = (data) => {
    const email = data.email;
    const password = passwordValue;
    if (passwordValue?.length === 0) {
      setPasswordError("Password is required");
    }
    if (passwordValue?.length !== 0) {
      if (success) {
        navigate("/admin/dashboard");
      }
      dispatch(adminLogin({ email, password }));
    }
  };

  return (
    <div className={`${styles.container} w-lg-50`}>
      <div className={`${styles.bgLight} w-100`}>
        <h4 className="text-center">Login</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-label">Email:</label>
          <input
            className="form-input"
            type="email"
            data-test-mediator-email="name"
            placeholder="email"
            {...register("email", registerOptions.email)}
          />
          <small className="text-danger">
            {errors?.email && errors.email.message}
          </small>
          <div className="d-flex justify-content-between">
            <div>
              {" "}
              <label className="form-label">Password:</label>
            </div>
          </div>

          <Password
            className="form-input AvailabilityForm ps-0 w-100"
            placeholder="Password"
            toggleMask
            feedback={false}
            value={passwordValue}
            onChange={(e) => setpasswordValue(e.target.value)}
          />
          {passwordValue?.length === 0 && (
            <small className="text-danger">
              <span>{PasswordError}</span>
            </small>
          )}
          <div className="pt-1">
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />{" "}
            <span>Remember me</span>
          </div>

          {error && <Error>{error}</Error>}
          <button className="login-button py-2 mt-2" type="submit">
            {loading ? <Spinner animation="border" /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginD;
