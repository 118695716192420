import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CloseCase from "./Dialog/CloseCase";
import DataTableRecord from "./DataTableRecord/DataTableRecord";
import AccessBtn from "./AccessBtn/AccessBtn";
import CloseBtn from "./CloseBtn/CloseBtn";
import AddClient from "./Dialog/AddClient";
import {
  getSeverity,
  hidecloseCaseDialog,
  hideDialog,
  openNew,
} from "./DataTableFN/DataTableFN";
import {
  useGetAllMediatorsQuery,
  useGetCaseListQuery,
} from "../../services/auth/authService";
import { closeTheCase } from "../../features/auth/authActions";

const DataTableCases = () => {
  const { success, error } = useSelector((state) => state.Case);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "added successfully",
        life: 2000,
      });
      setProductDialog(false);
    } else if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 2000,
      });
      setProductDialog(false);
    }
  }, [success, error]);

  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "",
    caseStatus: "",
  };
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [closeCaseDialog, setCloseCaseDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [filter, setFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const option = ["Closed", "Open"];
  const [closeOrOpen, setcloseOrOpen] = useState();

  const colorForOption = [
    "MIAM Part 1-C2",
    "MIAM Part 2-C2",
    "MIAM Part 1-C1",
    "MIAM Part 2-C1",
    "Not proceeding with mediation",
    "Proceeding with mediation",
    "Mediation Session N",
    "Broken",
    "Agreed",
    "Invitation to C2 sent",
    "Not suitable for mediation",
    "Closed",
    "Successful",
  ];

  const changeColor = (colorForOption) => {
    switch (colorForOption) {
      case "MIAM Part 1-C2":
        return "light-blue";
      case "MIAM Part 2-C2":
        return "light-blue";
      case "MIAM Part 1-C1":
        return "dark-blue";
      case "MIAM Part 2-C1":
        return "dark-blue";
      case "Not proceeding with mediation":
        return "brown";
      case "Proceeding with mediation":
        return "light-green";
      case "Mediation Session N":
        return "black";
      case "Broken":
        return "red";
      case "Agreed":
        return "green";
      case "Invitation to C2 sent":
        return "#f097a6";
      case "Not suitable for mediation":
        return "burgundy";
      case "Closed":
        return "grey";
      case "Successful":
        return "green";
      default:
        return null;
    }
  };

  const changeColorforCase = (colorForCloseOrOpen) => {
    switch (colorForCloseOrOpen) {
      case "Open":
        return "red";
      case "Closed":
        return "grey";
      default:
        return null;
    }
  };

  const { token, userInfo } = useSelector((state) => state.auth);
  const { data: allMediatorsData, refetch } = useGetAllMediatorsQuery(
    "MediatorDetails",
    {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: CaseList,
    isFetching: fetchingCaseList,
    refetch: refetchData,
  } = useGetCaseListQuery("products", {
    pollingInterval: 900000,
    refetchOnMountOrArgChange: true,
  });

  const revealRef = useRef([]);
  revealRef.current = [];
  const userInfoRole = userInfo?.role;
  const addToRef = (el) => {
    if (el.id && !revealRef.current.includes(el.id)) {
      revealRef.current.push(el);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    localStorage.setItem("globalFilter", value);
  };

  useEffect(() => {
    const storedFilter = localStorage.getItem("globalFilter");
    if (storedFilter) {
      setFilter(storedFilter);
    }

    if (CaseList) {
      setProducts(CaseList.data);
    }
  }, [token, userInfoRole, CaseList]);

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setCloseCaseDialog(true);
  };

  const confirmClosedCaseDialog = (id, setCloseCaseDialog, revealRef) => {
    dispatch(closeTheCase({ id }));
    setCloseCaseDialog(false);
    let curentRevealRef = revealRef.current;
    curentRevealRef.find(
      (prod) => prod.id === id && prod.classList.add("btn_disabled")
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <CloseBtn
          addToRef={addToRef}
          rowData={rowData}
          confirmDeleteProduct={confirmDeleteProduct}
        />
      </>
    );
  };

  const actionBody = (rowData) => {
    return <AccessBtn rowData={rowData} />;
  };

  const closeCaseDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => hidecloseCaseDialog(setCloseCaseDialog)}
      />
      <Button
        id={product._id}
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => {
          confirmClosedCaseDialog(product._id, setCloseCaseDialog, revealRef);
        }}
      />
    </React.Fragment>
  );

  const statusBodyItem = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <Tag
          className="dots"
          style={{ backgroundColor: getSeverity(rowData) }}
        ></Tag>
        <Tag value={rowData.status} className="dots_text"></Tag>
      </div>
    );
  };

  const statusItemTemplateItem = (colorForOption) => {
    return (
      <Tag
        value={colorForOption}
        style={{ backgroundColor: changeColor(colorForOption) }}
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={colorForOption}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplateItem}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const statusItemTemplate = (colorForCloseOrOpen) => {
    return (
      <Tag
        value={colorForCloseOrOpen}
        style={{ backgroundColor: changeColorforCase(colorForCloseOrOpen) }}
      />
    );
  };

  const statusRowFilter = (options) => {
    function handleFilter(e, options) {
      if (e.value === "Open") {
        options.filterApplyCallback("false");
        setcloseOrOpen("Open");
      } else {
        options.filterApplyCallback("true");
        setcloseOrOpen("Closed");
      }
    }

    return (
      <Dropdown
        value={closeOrOpen}
        options={option}
        onChange={(e) => handleFilter(e, options)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  if (fetchingCaseList) {
    return (
      <>
        <div>
          <Toast ref={toast} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "1rem",
            }}
          >
            <Spinner animation="border" />
          </div>
          <AddClient
            refetchData={refetchData}
            productDialog={productDialog}
            hideDialog={() => hideDialog(setProductDialog)}
            classNames={classNames}
            allMediatorsData={allMediatorsData}
            refetch={refetch}
          />
          <CloseCase
            closeCaseDialog={closeCaseDialog}
            closeCaseDialogFooter={closeCaseDialogFooter}
            hidecloseCaseDialog={() => hidecloseCaseDialog(setCloseCaseDialog)}
          />
        </div>
      </>
    );
  }

  if (CaseList) {
    return (
      <>
        <div>
          <Toast ref={toast} />
          <DataTableRecord
            dt={dt}
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            filter={filter}
            handleInputChange={handleInputChange}
            openNew={() => openNew(setProduct, emptyProduct, setProductDialog)}
            statusFilterTemplate={statusFilterTemplate}
            statusBodyItem={statusBodyItem}
            actionBody={actionBody}
            actionBodyTemplate={actionBodyTemplate}
            statusRowFilter={statusRowFilter}
            refetchData={refetchData}
          />
          <AddClient
            refetchData={refetchData}
            productDialog={productDialog}
            hideDialog={() => hideDialog(setProductDialog)}
            classNames={classNames}
            allMediatorsData={allMediatorsData}
            refetch={refetch}
          />
          <CloseCase
            closeCaseDialog={closeCaseDialog}
            closeCaseDialogFooter={closeCaseDialogFooter}
            hidecloseCaseDialog={() => hidecloseCaseDialog(setCloseCaseDialog)}
          />
        </div>
      </>
    );
  }
};

export default DataTableCases;
