import React from "react";
import { Dialog } from "primereact/dialog";
function CloseCase({
  closeCaseDialog,
  closeCaseDialogFooter,
  hidecloseCaseDialog,
}) {
  return (
    <>
      <Dialog
        visible={closeCaseDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={closeCaseDialogFooter}
        onHide={hidecloseCaseDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3 me-2 text-danger"
            style={{ fontSize: "2rem" }}
          />
          <span>Are you sure you want to close this case?</span>
        </div>
      </Dialog>
    </>
  );
}

export default CloseCase;
