import React from "react";
import { Link } from "react-router-dom";
import style from './notFound.module.css'
const PageNotFound = () => {
  return (
    <div className={style.background}>
      <div className="ps-3 ">
        <div className="d-sm-flex">
          <p className={style.from_pink}>
            404
          </p>
          <div>
            <div >
              <h1 className="fw-bolder">
                Page not found
              </h1>
              <p className="mt-1 ">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="mt-5 d-flex px-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link to="/login" className={`${style.btn} btn border-0 text-white p-2`}>Go back home</Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
