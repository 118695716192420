import React from "react";
import style from "./NoDataComponent.module.css";
import img from "../../images/no-data.png";
const NoDataComponent = () => {
  return (
    <div
      className={`container ${style.container} rounded p-3 shadow text-center`}
    >
      <div>
        <img src={img} alt="no-data" />
        <h2>Data has not been added yet</h2>
        <p>Please check back later.</p>
      </div>
    </div>
  );
};

export default NoDataComponent;
