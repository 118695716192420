import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import style from "./Date.module.css";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  MIAM1_Confirmation_C1,
  MIAM1_Confirmation_C2,
} from "../../features/auth/authActions";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

const DateForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showZoomLink, setShowZoomLink] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const watchZoomLink = watch("location");
  useEffect(() => {
    if (watchZoomLink === "Zoom") {
      setShowZoomLink(true);
    } else {
      setShowZoomLink(false);
    }
  }, [setShowZoomLink, showZoomLink, watchZoomLink]);

  const onSubmit = (data) => {
    let location = data.location;
    let date = data.date;
    let zoomLink = data.zoomLink;
    setIsLoading(true);

    if (data.type === "C1") {
      dispatch(MIAM1_Confirmation_C1({ location, date, zoomLink, id })).then(
        (res) => {
          setIsLoading(false);
          if (res.payload.status === 200) {
            setIsLoading(false);
            if (res.payload.status === 200) {
              setIsLoading(false);
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: res.payload.data.meesage,
                life: 1500,
              });
            } else {
              setIsLoading(false);
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 1500,
              });
            }
          }
        }
      );
    } else if (data.type === "C2") {
      dispatch(MIAM1_Confirmation_C2({ location, date, zoomLink, id })).then(
        (res) => {
          setIsLoading(false);
          if (res.payload.status === 200) {
            setIsLoading(false);
            if (res.payload.status === 200) {
              setIsLoading(false);
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: res.payload.data.meesage,
                life: 1500,
              });
            } else {
              setIsLoading(false);
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 1500,
              });
            }
          }
        }
      );
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="w-100">
        <div className={`${style.containerForTitle}  w-100 mt-5`}>
          <Link
            className={`${style.BackBtn} me-3 text-white text-decoration-none `}
            to={{ pathname: `/case/${id}` }}
          >
            Back
          </Link>{" "}
          <p className={style.titleMiam}>Date for MIAM</p>
        </div>
        <div className={`${style.containerForForm} mt-5 px-1 `}>
          <div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="AvailabilityForm"
            >
              <label className="form-label mt-3">Date :</label>
              <input
                type="datetime-local"
                className="select-input mt-3"
                {...register("date", { required: true })}
              />
              <small className="text-danger">
                {errors.date && <span>date is required</span>}
              </small>
              <label className="form-label">Select Client:</label>
              <select
                defaultValue=""
                className="select-input mt-3"
                {...register("type", { required: true })}
              >
                <option value="" disabled>
                  Select client
                </option>
                <option>C1</option>
                <option>C2 </option>
              </select>
              <small className="text-danger">
                {errors.type && <span>type is required</span>}
              </small>
              <label className="form-label">Location:</label>
              <select
                defaultValue=""
                className="select-input mt-3"
                {...register("location", { required: true })}
              >
                <option value="" disabled>
                  Select location
                </option>
                <option>Online via WhatsApp</option>
                <option>Zoom </option>
              </select>
              <small className="text-danger">
                {errors.location && <span>location is required</span>}
              </small>
              {showZoomLink && (
                <>
                  <label className="form-label">Zoom link:</label>
                  <input
                    className="select-input"
                    {...register("zoomLink", { required: true })}
                  />
                  <small className="text-danger">
                    {errors.zoomLink && <span>zoomLink is required</span>}
                  </small>
                </>
              )}

              <button className="login-button py-2 mt-3" type="submit">
                {isLoading ? <Spinner animation="border" /> : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateForm;
