import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../dashboard.module.css";
import { useForm } from "react-hook-form";
import { Button, Spinner } from "react-bootstrap";
import { addNewCompany } from "../../../features/auth/authActions";
import { Toast } from "primereact/toast";
import Modal from "react-bootstrap/Modal";
import { Password } from "primereact/password";

const AddCompany = ({ setIsOpen, handleDeleteRefetch }) => {
  const { loading } = useSelector((state) => state.admin);
  const [passwordValue, setpasswordValue] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [conirmPasswordValue, setconirmPasswordValue] = useState("");
  const [conirmPasswordError, setconirmPasswordError] = useState("");
  const [drive, setDrive] = useState(false);
  const [twilio, setTwilio] = useState(false);
  const [modalView, setModalView] = useState(false);
  const handleShow = () => setModalView(true);
  const handleClose = () => setModalView(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useRef(null);
  const registerOptions = {
    email: { required: "Email is required" },
    // password: {
    //   required: "Password is required",
    //   minLength: {
    //     value: 8,
    //     message: "Password must have at least 8 characters",
    //   },
    // },
    // confirmPassword: {
    //   required: "Confirm password is required",
    //   minLength: {
    //     value: 8,
    //     message: "Password must have at least 8 characters",
    //   },
    // },
  };

  const onSubmit = (data) => {
    if (passwordValue?.length === 0) {
      setPasswordError("Password is required");
    }
    if (conirmPasswordValue?.length === 0) {
      setconirmPasswordError("confirm Password is required");
    }
    if (data) {
      if (passwordValue !== conirmPasswordValue) {
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: "confirm password value is incorrect",
          life: 1500,
        });
      } else {
        const regx = /^AC/;
        if (regx.test(data.TwilioId)) {
          const companyData = {
            companyName: data.name,
            email: data.email,
            password: passwordValue,
            uploadLogo: data.uploadLogo,
            sharingGmail: data.driveAccount || "",
            twillioData: {
              twillioSID: data.TwilioId,
              twillioToken: data.token,
              twillioNumber: data.phoneNumber,
            },
          };

          if (passwordValue === conirmPasswordValue) {
            dispatch(addNewCompany(companyData)).then(({ data }) => {
              if (data.meta.requestStatus === "rejected") {
                toast.current.show({
                  severity: "error",
                  summary: "error",
                  detail: data.payload,
                  life: 1500,
                });
              } else {
                toast.current.show({
                  severity: "success",
                  summary: "Success",
                  detail: data.payload.message,
                  life: 1500,
                });
                handleClose();
                setTimeout(() => {
                  handleDeleteRefetch();
                }, 1600);
              }
            });
          }
        } else {
          const companyData = {
            companyName: data.name,
            email: data.email,
            password: passwordValue,
            uploadLogo: data.uploadLogo,
            sharingGmail: data.driveAccount || "",
          };
          dispatch(addNewCompany(companyData)).then((data) => {
            if (data.meta.requestStatus === "rejected") {
              toast.current.show({
                severity: "error",
                summary: "error",
                detail: data.payload,
                life: 1500,
              });
            } else {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: data.payload.message,
                life: 1500,
              });
              handleClose();
              setTimeout(() => {
                handleDeleteRefetch();
              }, 1600);
            }

            return data;
          });
        }
      }
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className={`${styles.card}`}>
        <Button
          variant="primary"
          onClick={handleShow}
          className="bg-transparent border-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="#040848 "
            className="bi bi-plus-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
          </svg>
        </Button>
        <h5>Add Company</h5>
      </div>
      <Modal show={modalView} onHide={handleClose}>
        <Modal.Header closeButton className={`${styles.header}`}>
          <h4 className="text-center">Add Company</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Name:</label>
            <input
              className="form-input"
              type="name"
              data-test-mediator-email="name"
              placeholder="enter your name"
              {...register("name", registerOptions.email)}
            />
            <label className="form-label">Email:</label>
            <input
              className="form-input"
              type="email"
              data-test-mediator-email="name"
              placeholder="email"
              {...register("email", registerOptions.email)}
            />
            <small className="text-danger">
              {errors?.email && errors.email.message}
            </small>
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <label className="form-label">Password:</label>
              </div>
            </div>

            <Password
              className="form-input AvailabilityForm ps-0"
              placeholder="Password"
              toggleMask
              feedback={false}
              value={passwordValue}
              onChange={(e) => setpasswordValue(e.target.value)}
            />
            {passwordValue?.length === 0 && (
              <small className="text-danger">
                <span>{PasswordError}</span>
              </small>
            )}
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <label className="form-label">Confirm Password:</label>
              </div>
            </div>
            <Password
              className="form-input AvailabilityForm ps-0"
              placeholder="confirm password"
              toggleMask
              feedback={false}
              value={conirmPasswordValue}
              onChange={(e) => setconirmPasswordValue(e.target.value)}
            />
            {conirmPasswordValue?.length === 0 && (
              <small className="text-danger">
                <span>{conirmPasswordError}</span>
              </small>
            )}
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <label className="form-label">Upload logo:</label>
              </div>
            </div>
            <input
              className="form-input"
              type="text"
              placeholder="enter your logo link"
              {...register("uploadLogo")}
            />
            {twilio && (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    {" "}
                    <label className="form-label">Twilio ID:</label>
                  </div>
                </div>
                <input
                  className="form-input"
                  type="text"
                  placeholder="enter your id"
                  {...register("TwilioId")}
                />
                <div className="d-flex justify-content-between">
                  <div>
                    {" "}
                    <label className="form-label">Enter Token:</label>
                  </div>
                </div>
                <input
                  className="form-input"
                  type="text"
                  placeholder="enter your token"
                  {...register("token")}
                />
                <div className="d-flex justify-content-between">
                  <div>
                    {" "}
                    <label className="form-label">Enter Phone Number:</label>
                  </div>
                </div>
                <input
                  className="form-input"
                  type="text"
                  placeholder="enter your phone number"
                  {...register("phoneNumber")}
                />
              </>
            )}
            {drive && (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    {" "}
                    <label className="form-label">Enter Gmail account:</label>
                  </div>
                </div>
                <input
                  className="form-input"
                  type="text"
                  placeholder="enter your gmail account"
                  {...register("driveAccount")}
                />
              </>
            )}
            <div className={styles.btnCard}>
              <Button
                className={`${styles.twilioBtn}`}
                onClick={() => setTwilio(!twilio)}
              >
                <img src="/images/Twilio-logo.png" alt="twilio icon" />{" "}
                Configure Twilio
              </Button>
              <Button
                className={styles.driveBtn}
                onClick={() => setDrive(!drive)}
              >
                <img src="/images/Drive-logo.png" alt="drive icon" /> Add google
                Drive
              </Button>
            </div>
            <button
              className="login-button py-2 mt-2"
              type="submit"
              data-bs-dismiss="modal"
            >
              {loading ? <Spinner animation="border" /> : "Add Company"}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCompany;
