import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import SecondNav from "../pages/SecondNav";

const Layout = () => {
  const token = localStorage.getItem("userToken");

  const res = window.location.pathname;
  if (res.includes("admin")) {
    return (
      <>
      <SecondNav />
        <Outlet />
      </>
    );
  } else {
    return (
      <>
      {token? <NavBar />:''}
         
        <Outlet />
      </>
    );
  }
};

export default Layout;
