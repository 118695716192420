import React, { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Header } from "../Header/Header";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { deleteCase } from "../../../features/auth/authActions";
function DataTableRecord({
  dt,
  products,
  selectedProducts,
  setSelectedProducts,
  globalFilter,
  exportCSV,
  openNew,
  statusFilterTemplate,
  statusBodyItem,
  actionBody,
  actionBodyTemplate,
  statusRowFilter,
  handleInputChange,
  filter,
  refetchData,
}) {
  const [clientId, setClientId] = useState(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={(e) => {
          setVisible(false);
          const data = {
            id: clientId,
          };
          dispatch(deleteCase(data)).then((res) => {
            if (res.payload.status === 200) {
              refetchData();
            }
          });
        }}
        autoFocus
        severity="danger"
      />
    </div>
  );

  const actionDeleteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            setVisible(true);
            confirmDeleteProduct(rowData);
          }}
        />
      </React.Fragment>
    );
  };

  const confirmDeleteProduct = (product) => {
    setClientId(product._id);
  };

  const dateBodyTemplate = (rowDate) => {
    console.log({ rowDate });
    const date = rowDate?.startDate?.split("T")[0];
    const dateObject = new Date(date);
    const ukDateFormat = dateObject.toLocaleDateString("en-GB");
    return (
      <div>
        {ukDateFormat === "Invalid Date" ? "No meeting created" : ukDateFormat}
      </div>
    );
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <p className="m-0">Are you sure you want to delete?</p>
      </Dialog>
      <div className="card ">
        <DataTable
          className="x"
          removableSort
          ref={dt}
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="_id"
          paginator
          rows={10}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last}"
          rowsPerPageOptions={[5, 10, 25]}
          globalFilter={filter}
          header={
            <Header
              exportCSV={exportCSV}
              openNew={openNew}
              filter={filter}
              handleInputChange={handleInputChange}
            />
          }
        >
          <Column
            field="Reference"
            header="Reference"
            sortable
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            filter
            filterElement={statusFilterTemplate}
            body={statusBodyItem}
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            field="startDate"
            header="Starting date"
            body={dateBodyTemplate}
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            field="actions"
            header="Actions"
            body={actionBody}
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            header="Case type"
            body={actionBodyTemplate}
            exportable={true}
            style={{ minWidth: "8rem" }}
            field="closed"
            filter
            filterElement={statusRowFilter}
          ></Column>
          <Column
            header="Mediator"
            field="mediatorOfTheCase"
            sortable
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column body={actionDeleteBodyTemplate} exportable={false}></Column>
        </DataTable>
      </div>
    </>
  );
}

export default DataTableRecord;
