import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";

import "primeicons/primeicons.css";

import EntryFormInfo from "../EntryFormInfo/EntryFormInfo";
import CSV from "./CSV";
import LegalAidForm from "./LegalAidForm";

export const Header = ({ openNew, filter, handleInputChange }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const userData = userInfo?.role;

  const [showModal, setShowModal] = useState(false);
  const [legalAidModal, setLegalAidModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  return (
    <>
      <CSV close={() => setShowModal(false)} showModal={showModal} />
      <LegalAidForm
        close={() => setLegalAidModal(false)}
        legalAidModal={legalAidModal}
        setLegalAidModal={setLegalAidModal}
      />
      <EntryFormInfo
        close={() => setShowInfoModal(false)}
        showInfoModal={showInfoModal}
      />
      <div className="d-flex justify-content-center align-items-center justify-content-md-between flex-column flex-lg-row">
        <span className="p-input-icon-left ms-lg-0 ms-5  mb-lg-0 mb-3">
          <i className="pi pi-search" />
          <InputText
            value={filter}
            onChange={handleInputChange}
            placeholder="Search"
          />
        </span>
        {userData === "company" && (
          <div className="d-flex">
            <div>
              <Button
                label="Save CSV"
                icon="pi pi-download"
                className="p-button-help bg-success me-2 switchBtn"
                onClick={() => setShowModal(true)}
              />
            </div>
            <div>
              <Button
                label="Apply for Legal Aid"
                icon="pi pi-file-edit"
                className="p-button-help bg-success me-2 switchBtn"
                onClick={() => setLegalAidModal(true)}
              />
            </div>
            <div>
              <Button
                label="Book a Private MIAM"
                icon="pi pi-plus"
                onClick={openNew}
                className="p-button-help switchBtn"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
