import styles from "./Case.module.css";
import { Button, Modal } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendSMSinvitation } from "../../features/auth/authActions";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";

const InvitationAsSMS = ({ invitationSMS, close, MIAM1_C1, MIAM1_C2 }) => {
  const { id } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const regex = /(C1|C2)\s*\|/;
    const match = data?.Client?.match(regex);
    if (match) {
      const output = { Client: match[1], id };

      dispatch(sendSMSinvitation(output)).then((res) => {
        if (res.payload.status === 200) {
          setIsLoading(false);
          close();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.payload.data.message,
            life: 1500,
          });
        }else{
          setIsLoading(false);
          close();
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 1500,
          });
        }
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={invitationSMS}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader}  pb-0`}>
            <h4 className="text-center pt-4">Send SMS Invitation</h4>
            <Button
              type="button"
              className={`bg-transparent border-0`}
              onClick={close}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Phone:</label>
            <select
              defaultValue=""
              className="select-input"
              {...register("Client", { required: true })}
            >
              <option value="" disabled>
                Select Phone Number
              </option>
              <option>
                C1 | {MIAM1_C1?.phoneNumber}
              </option>
              <option>
                C2 | {MIAM1_C2?.phoneNumber}
              </option>
            </select>
            <small className="text-danger">
              {errors.Client && <span>This field is required</span>}
            </small>
            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "Send"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default InvitationAsSMS;
