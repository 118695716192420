import { createAsyncThunk } from "@reduxjs/toolkit";

import { instance } from "../../axios/axios-instance";

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ companyName, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await instance.post(
        "/auth/company-signup",
        { companyName, email, password },
        config
      );
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const loginCompany = createAsyncThunk(
  "auth/loginCompany",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.post(
        "/auth/company-login",
        { email, password },
        config
      );

      // store user's token in local storage
      localStorage.setItem("userToken", data.accessToken);

      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.post(
        "/auth/forgot-password",
        { email },
        config
      );
      return { data, status };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async ({ resetToken, newPassword }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.post(
        "/auth/reset-password",
        { resetToken, newPassword },
        config
      );
      return { data, status };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// add drive account to company
export const addDrive = createAsyncThunk(
  `addDrive`,
  async ({ id, sharingGmail }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("adminLoginToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await instance.patch(
        `/update-gmail/${id}`,
        { sharingGmail },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// update company
export const updateCompany = createAsyncThunk(
  `updateCompany`,
  async ({ id, companyData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("adminLoginToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await instance.patch(
        `/update-company/${id}`,
        companyData,
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// config twilio data to a company
export const twilioConfig = createAsyncThunk(
  `twilioConfig`,
  async ({ email, twillioData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("adminLoginToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await instance.patch(
        `/addTwillio`,
        { email, twillioData },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addNewCompany = createAsyncThunk(
  `addNewCompany`,
  async (
    { companyName, email, password, uploadLogo, sharingGmail, twillioData },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("adminLoginToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await instance.post(
        `/auth/add-company`,
        {
          companyName,
          email,
          password,
          logo: uploadLogo,
          sharingGmail,
          twillioData,
        },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteCompany = createAsyncThunk(
  `deleteCompany`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("adminLoginToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await instance.delete(`company/${id}`, config);

      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const loginMediator = createAsyncThunk(
  "auth/loginMediator",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.post(
        "/auth/mediator-login",
        { email, password },
        config
      );
      // store user's token in local storage
      localStorage.setItem("userToken", data.accessToken);
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const adminLogin = createAsyncThunk(
  "/auth/admin-login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.post(
        "/auth/admin-login",
        { email, password },
        config
      );

      // store user's token in local storage
      localStorage.setItem("adminLoginToken", data.accessToken);
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addMediator = createAsyncThunk(
  "auth/add-mediator",
  async (
    { firstName, lastName, email, password, phoneNumber },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.post(
        "/auth/add-mediator",
        { firstName, lastName, email, password, phoneNumber },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllMediator = async () => {
  const config = {
    headers: { Authorization: "Bearer " + localStorage.getItem("userToken") },
  };
  const { data } = await instance.get("/getMediators", config);
  return data;
};

export const addClient = createAsyncThunk(
  "Case/addClient",
  async (
    {
      firstName,
      surName,
      phoneNumber,
      email,
      dateOfMAIM,
      location,
      mediatorMail,
      zoomLink,
      caseType,
      legalAidType,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.post(
        "/creatCase",
        {
          firstName,
          surName,
          phoneNumber,
          email,
          dateOfMAIM,
          zoomLink,
          location,
          mediatorMail,
          caseType,
          legalAidType,
        },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const addReminder = createAsyncThunk(
  "/addRemider",
  async ({ reminderTitle, startDate }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        "/addRemider",
        { reminderTitle, startDate },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateReminder = createAsyncThunk(
  "/updateReminder",
  async ({ _id, reminderTitle, startDate }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.patch(
        "/updateReminder",
        { _id, reminderTitle, startDate },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteReminder = createAsyncThunk(
  `deleteReminder`,
  async (_id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data } = await instance.delete(`/deleteReminder/${_id}`, config);
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendMailInvitation = createAsyncThunk(
  `sendMailInvitation`,
  async ({ Client, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/sendMailInvitation/${id}`,
        { Client },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendSMSinvitation = createAsyncThunk(
  `sendSMSinvitation`,
  async ({ Client, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/sendSMSinvitation/${id}`,
        { Client },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendSMSmediationUpdate = createAsyncThunk(
  `sendSMSmediationUpdate`,
  async ({ Client, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/sendSMSmediationUpdate/${id}`,
        { Client },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const BOOK_MEDIATION_SESSION = createAsyncThunk(
  `BOOK_MEDIATION_SESSION`,
  async ({ dates, location, textBody, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/BOOK_MEDIATION_SESSION/${id}`,
        { dates, location, textBody },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const MIAM1_Confirmation_C1 = createAsyncThunk(
  `MIAM1_Confirmation_C1`,
  async ({ location, date, zoomLink, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/MIAM1_Confirmation_C1/${id}`,
        { location, date, zoomLink },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const MIAM1_Confirmation_C2 = createAsyncThunk(
  `MIAM1_Confirmation_C2`,
  async ({ location, date, zoomLink, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/MIAM1_Confirmation_C2/${id}`,
        { location, date, zoomLink },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendMIAM1Link = createAsyncThunk(
  `sendMIAM1Link`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/sendMIAM1Link/${id}`,
        {},
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendSMS = createAsyncThunk(
  `sendSMS`,
  async ({ clientNumber, messageBodyData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/sendSMS`,
        { clientNumber, messageBodyData },
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const sendRecordFormToMediator = createAsyncThunk(
  `sendRecordFormToMediator`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { status, data } = await instance.post(
        `/sendRecordFormToMediator/${id}`,
        {},
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendCIM_Mail = createAsyncThunk(
  `sendCIM_Mail`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.post(
        `/sendCIM_Mail/${id}`,
        {},
        config
      );
      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendProperty_Mail = createAsyncThunk(
  `sendProperty_Mail`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.post(
        `/sendProperty_Mail/${id}`,
        {},
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendAgreementForm = createAsyncThunk(
  `sendAgreementForm`,
  async ({ id, targetClient }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.post(
        `/sendAgreementForm/${id}`,
        { targetClient },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const sendAppointment = createAsyncThunk(
//   `sendAppointment`,
//   async (
//     { id, targetClient, caseType, eventType, dates, location, textBody },
//     { rejectWithValue }
//   ) => {
//     try {
//       const config = {
//         headers: {
//           Authorization: "Bearer " + localStorage.getItem("userToken"),
//           "Content-Type": "application/json",
//         },
//       };
//       const { data, status } = await instance.post(
//         `/sendAppointment/${id}`,
//         { targetClient, caseType, eventType, dates, location, textBody },
//         config
//       );
//

//       return { status, data };
//     } catch (error) {
//       // return custom error message from backend if present
//       if (error.response && error.response.data.message) {
//
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );

export const updateC1M2 = createAsyncThunk(
  `updateC1M2`,
  async ({ patchObject, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/updateC1M2/${id}`,
        patchObject,
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateC2M2 = createAsyncThunk(
  `updateC2M2`,
  async ({ patchObject, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/updateC2M2/${id}`,
        patchObject,
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateC2M1 = createAsyncThunk(
  `updateC2M1`,
  async ({ patchEditMIAMOne, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/updateC2M1/${id}`,
        patchEditMIAMOne,
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateC1M1 = createAsyncThunk(
  `updateC1M1`,
  async ({ patchEditMIAMOne, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/updateC1M1/${id}`,
        patchEditMIAMOne,
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const closeTheCase = createAsyncThunk(
  `closeTheCase`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/closeTheCase/${id}`,
        {},
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCaseLog = createAsyncThunk(
  `addCaseLog`,
  async ({ id, logBody, subName }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/addCaseLog/${id}`,
        { logBody, subName },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCaseLog = createAsyncThunk(
  `updateCaseLog`,
  async ({ id, logBody, _id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `/updateCaseLog/${id}`,
        { logBody, _id },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteCaseLog = createAsyncThunk(
  `deleteCaseLog`,
  async ({ id, _id }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.delete(
        `/deleteCaseLog/${id}/${_id}`,
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendM1_mail_C1 = createAsyncThunk(
  `sendM1_mail_C1`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/sendM1_mail_C1/${id}`,
        {},
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const sendM1_mail_C2 = createAsyncThunk(
  `sendM1_mail_C2`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/sendM1_mail_C2/${id}`,
        {},
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getStatistics = createAsyncThunk(
  `getStatistics`,
  async ({ month, year }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/getStatistics`,
        { month, year },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const CONFIRM_MEDIATION_SESSION = createAsyncThunk(
  `CONFIRM_MEDIATION_SESSION`,
  async ({ date, type, location, id, zoomLink }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/CONFIRM_MEDIATION_SESSION/${id}`,
        { date, type, location, zoomLink },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendCourtForm = createAsyncThunk(
  `sendCourtForm`,
  async ({ id, TargetClient, pdfData }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data ",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/sendCourtForm/${id}`,
        { TargetClient, pdfData },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeMediator = createAsyncThunk(
  `/remove-mediator`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await instance.delete(`remove-mediator/${id}`, config);

      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateMediatorData = createAsyncThunk(
  `update-mediator-data`,
  async ({ id, firstName, lastName, email }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await instance.patch(
        `update-mediator-data/${id}`,
        { firstName, lastName, email },
        config
      );

      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadFiles = createAsyncThunk(
  `uploadFiles`,
  async ({ id, pdfData }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data ",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/uploadFiles/${id}`,
        { pdfData },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const replaceMediator = createAsyncThunk(
  `replaceMediator`,
  async ({ id, mediatorMail }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.patch(
        `/replaceMediator/${id}`,
        { mediatorMail },
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editTypeOfApplication = createAsyncThunk(
  `editTypeOfApplication`,
  async ({ id, legalAidStatus, reasonsList }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        `/changeLegalAidStatus/${id}`,
        { legalAidStatus, reasonsList },
        config
      );

      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const googleAuth = createAsyncThunk(
  "googleAuth",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.get(`/googleAuth`, config);

      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createPDF = createAsyncThunk(
  "createPDF",
  async ({ id }, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.get(
        `/generate-pdf-Invitation-Letter/${id}`,
        config
      );

      return { status, data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const furtherEvidenceRequired = createAsyncThunk(
  "furtherEvidenceRequired",
  async (
    { id, legalAidStatus, paragraphOne, paragraphTwo, FurtherEvidenceIMG },
    { rejectWithValue }
  ) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
      };

      const formData = new FormData();
      formData.append("Paragraph1", paragraphOne);
      formData.append("Paragraph2", paragraphTwo);
      formData.append("legalAidStatus", legalAidStatus);
      formData.append("FurtherEvidenceIMG", FurtherEvidenceIMG);

      const { data, status } = await instance.post(
        `/changeLegalAidStatus/${id}`,
        formData,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCase = createAsyncThunk(
  `deleteCase`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.delete(
        `/case/delete/${id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addLegalAidClients = createAsyncThunk(
  "addLegalAidClients",
  async (
    { firstname, surname, phone, email, typeOfApplication },
    { rejectWithValue }
  ) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data, status } = await instance.post(
        "/legalAidClients/",
        { firstname, surname, phone, email, typeOfApplication },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteLegalAidClient = createAsyncThunk(
  "deleteLegalAidClient",
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.delete(
        `/legalAidClients/${id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const bookMIAM = createAsyncThunk(
  "bookMIAM",
  async (
    { mediatorID, id, location, meetingStartDate },
    { rejectWithValue }
  ) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      // Assuming you want to send some data in the request body, so I've added the missing parameters
      const requestData = {
        mediatorID,
        location,
        meetingStartDate,
      };

      const { data, status } = await instance.patch(
        `/bookMIAM/${id}`,
        requestData,
        config
      );

      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendMIAM2 = createAsyncThunk(
  "send/MIAM2",
  async ({ clientType, mediatorEmail, caseId }, { rejectWithValue }) => {
    try {
      const { data, status } = await instance.post(
        `MIAM2/notify-mediator/${caseId}`,
        {
          clientType,
          mediatorEmail,
        }
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
