import { Button, Modal } from "react-bootstrap";
import styles from "./Case.module.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { sendCIM_Mail } from "../../features/auth/authActions";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

const InformationCIMEmail = ({ CIMInformation, close }) => {
  const toast = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = () => {
    setIsLoading(true);
    dispatch(sendCIM_Mail({ id })).then((res) => {
      if (res.payload.status === 200) {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.message,
          life: 1500,
        });
      }else{
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 1500,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={CIMInformation}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader}  pb-0`}>
            <h4 className="text-center pt-4">CIM Information</h4>
            <Button
              type="button"
              className={`bg-transparent border-0`}
              onClick={close}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4 d-flex justify-content-center align-items-center mt-5 ">
          <button
            className="btn btn-info w-100 border-0 p-2"
            onClick={() => onSubmit()}
            style={{ backgroundColor: "#040848", color: "white" }}
          >
            {" "}
            {isLoading ? <Spinner animation="border" /> : "Send to both client"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default InformationCIMEmail;
