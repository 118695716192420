import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { BsCheckAll } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { TiArrowBack } from "react-icons/ti";

import style from "../casemaim/Case.module.css";

import TenItemsTable from "./TenItemsTable";

import useScrollTo from "../../hooks/useScrollTo";
import { useGetCaseDetailsQuery } from "../../services/auth/authService";
import { sendRecordFormToMediator } from "../../features/auth/authActions";
import { Link } from "react-router-dom";

const MSRTable = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);

  useScrollTo(0, 0, "smooth");

  const { data: CaseDetails, isFetching: fetchingCaseDetails } =
    useGetCaseDetailsQuery(id, {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true,
    });
  const onSubmit = () => {
    setIsLoading(true);

    dispatch(sendRecordFormToMediator({ id })).then((res) => {
      if (res.payload.status === 200) {
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.message,
          life: 1500,
        });
      }
    });
  };

  if (fetchingCaseDetails) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (CaseDetails) {
    return (
      <>
        <Toast ref={toast} />
        <div className="w-100 ">
          <div className="d-flex justify-content-end">
            <Link
              className={` text-white text-decoration-none mt-3`}
              to={`/case/${id}`}
            >
              <div
                className=" px-3 rounded py-2"
                style={{ backgroundColor: "#0337a4" }}
              >
                <TiArrowBack className="fs-3" />{" "}
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    paddingTop: "20px",
                  }}
                >
                  Back
                </span>
              </div>
            </Link>{" "}
          </div>
          <div className="ms-2 mt-4 mx-4 ">
            <div>
              <p className={`${style.titleMiam} py-2 `}>Agreement To Mediate</p>
              <div className="d-flex justify-content-around">
                <div>
                  Agreement To Mediate (Client One):
                  <span>
                    <BsCheckAll
                      color={CaseDetails?.C1Agreement === "" ? "grey" : "green"}
                      size={50}
                    />
                  </span>
                </div>
                <div>
                  Agreement To Mediate (Client Two):
                  <span>
                    <BsCheckAll
                      color={CaseDetails?.C2Agreement === "" ? "grey" : "green"}
                      size={50}
                    />
                  </span>
                </div>
              </div>
              <div>
                <p className={`${style.titleMiam} py-2 w-100 mt-2`}>
                  Mediation Session Record
                </p>
                <div className="px-4 pb-4 d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-info border-0 py-1"
                    onClick={() => onSubmit()}
                    style={{
                      backgroundColor: "#0337A4",
                      color: "white",
                      minWidth: "50%",
                    }}
                  >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Send form to mediator"
                    )}
                  </button>
                </div>
                {CaseDetails?.mediationRecords?.length !== 0 ? (
                  CaseDetails?.mediationRecords.map((item, idx) => {
                    return <TenItemsTable item={item} idx={idx} key={idx} />;
                  })
                ) : (
                  <p className={`${style.NotFoundMSR}`}>
                    No Mediate Session Record Filled
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default MSRTable;
