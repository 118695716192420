import { useState } from "react";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetchatSMSC1Query } from "../../services/auth/authService";
import style from "./chatSMS.module.css";
import user from "../../images/user.png";
import { Spinner } from "react-bootstrap";

const ChatDetails = () => {
  const { id } = useParams();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const { data: chatSMSC1, isFetching: fetchingchatSMSC1 } =
    useGetchatSMSC1Query(id, {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true, // 15mins
    });


  useEffect(() => {
    if (chatSMSC1) {
      setFrom(chatSMSC1[0]?.from);
      setTo(chatSMSC1[0]?.to)
     
    }
  }, [chatSMSC1,from,to]);

  if (fetchingchatSMSC1) {
    return (
      <>
        <div className={style.spinner}>
          <Spinner animation="border" />
        </div>
      </>
    );
  } else {
    return (
      <>
       <div className={`${style.containerForTitle}  w-100 mt-5`}>
    <Link
      className={`${style.BackBtn} me-3 text-white text-decoration-none `}
      to={{ pathname: `/case/${id}` }}
    >
      Back
    </Link>{" "}
    <p className={style.titleMiam}>Chat SMS</p>
  </div>
  <div className={style.chatContainer}>
    {chatSMSC1?.map((ele, i) => {
      const inputDate = ele?.time; // Assuming the timestamp property contains the date string
      const date = new Date(inputDate);
    
      const options = {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
    
      const formattedTime = date.toLocaleTimeString('en-US', options);
    
      return (
        <div key={i}>
          {ele?.from === from && (
            <div className="position-relative mb-4">
              <div className={style.firstPerson}>
                <p>
                  <img src={user} alt="user img" className={style.userImg} /> {ele?.from}
                </p>
                <p>
                  {ele?.body}
                </p>
              </div>
              <span className={style.displayTime}>{formattedTime}</span>
            </div>
          )}
          {ele?.from === to && (
            <div className="position-relative d-flex justify-content-end mb-4">
              <div className={style.secondPerson}>
                <p>
                  <img src={user} alt="user img" className={style.userImg} />
                  {ele?.from}
                </p>
                <p>
                  {ele?.body}
                </p>
              </div>
              <span className={style.displayTime2}>{formattedTime}</span>
            </div>
          )}
        </div>
      );
    })}
  </div>
      </>
    );
  }


};

export default ChatDetails;
// chatSMS
