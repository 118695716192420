import React, { useEffect, useRef, useState } from "react";
import styles from "./Case.module.css";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CONFIRM_MEDIATION_SESSION } from "../../features/auth/authActions";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";

const ConfirmationJointMediationSession = ({
  close,
  ConfirmationJointSession,
}) => {
  const { id } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showZoomLink, setShowZoomLink] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchZoomLink = watch("location");

  useEffect(() => {
    if (watchZoomLink === "Zoom") {
      setShowZoomLink(true);
    } else {
      setShowZoomLink(false);
    }
  }, [setShowZoomLink, showZoomLink, watchZoomLink]);

  const onSubmit = async (data) => {
    let dateobj = new Date(data.date);
    let date = dateobj.toISOString();
    let type = data.type;
    let location = data.location;
    let zoomLink = data.zoomLink;

    setIsLoading(true);
    dispatch(
      CONFIRM_MEDIATION_SESSION({ date, type, location, id, zoomLink })
    ).then((res) => {
      if (res.payload.status === 200) {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Confirmation send successfully",
          life: 1500,
        });
      } else {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 1500,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={ConfirmationJointSession}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader}  pb-0`}>
            <h4 className="text-center pt-4">Confirm mediation session</h4>
            <Button
              type="button"
              className={`bg-transparent border-0`}
              onClick={close}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Date:</label>
            <input
              type="datetime-local"
              className="select-input"
              {...register("date", { required: true })}
            />
            <small className="text-danger">
              {errors.date && <span>date is required</span>}
            </small>
            <label className="form-label">Type:</label>
            <select
              defaultValue=""
              className="select-input"
              {...register("type", { required: true })}
            >
              <option value="" disabled>
                Select Type
              </option>
              <option>Shuttle </option>
              <option>Face to face</option>
            </select>
            <small className="text-danger">
              {errors.type && <span>type is required</span>}
            </small>
            <label className="form-label">Location:</label>
            <select
              defaultValue=""
              className="select-input"
              {...register("location", { required: true })}
            >
              <option value="" disabled>
                Select location
              </option>
              <option>Online via WhatsApp</option>
              <option>Zoom </option>
            </select>
            <small className="text-danger">
              {errors.location && <span>location is required</span>}
            </small>
            {showZoomLink && (
              <>
                <label className="form-label">Zoom link:</label>
                <input
                  className="select-input"
                  {...register("zoomLink", { required: true })}
                />
                <small className="text-danger">
                  {errors.zoomLink && <span>zoomLink is required</span>}
                </small>
              </>
            )}

            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "Confirm"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationJointMediationSession;
