import React from "react";
import Table from "react-bootstrap/Table";
import style from "../casemaim/Case.module.css";

const MaimInfo = ({ data }) => {
  return (
    <div className={`${style.casew} ms-2`}>
      <Table className={`${style.table} `}>
        <tbody>
          {data?.personalContactAndCaseInfo && (
            <tr>
              <td className={`${style.title} ps-4`}>Personal Details</td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.firstName && (
            <tr>
              <td className={`ps-4`}>
                First Name :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.firstName}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.surName && (
            <tr>
              <td className="ps-4">
                Surname :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.surName}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.dateOfBirth && (
            <tr>
              <td className="ps-4">
                Date of birth :{" "}
                <span className="ps-2">
                  {(data?.personalContactAndCaseInfo.dateOfBirth).split("T")[0]}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.phoneNumber && (
            <tr>
              <td className="ps-4">
                Telephone :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.phoneNumber}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.email && (
            <tr>
              <td className="ps-4">
                Email :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.email}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.street && (
            <tr>
              <td className="ps-4">
                Address :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.street +
                    " , " +
                    data?.personalContactAndCaseInfo.city +
                    " , " +
                    data?.personalContactAndCaseInfo.postCode +
                    " , " +
                    data?.personalContactAndCaseInfo.country}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.gender && (
            <tr>
              <td className="ps-4">
                Gender :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.gender}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.doesOtherPartyKnow && (
            <tr>
              <td className="ps-4">
                Other participant know contact details :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.doesOtherPartyKnow}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.makeDetailsConfidential && (
            <tr>
              <td className="ps-4">
                Keep details confidential :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.makeDetailsConfidential}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.disabilityRegistered && (
            <tr>
              <td className="ps-4">
                Registered disability :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.disabilityRegistered}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.isWillingToTryMediation && (
            <tr>
              <td className="ps-4">
                Willing to try mediation :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.isWillingToTryMediation}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.areChangesToServicesRequired && (
            <tr>
              <td className="ps-4">
                Make any changes to our services :{" "}
                <span className="ps-2">
                  {
                    data?.personalContactAndCaseInfo
                      .areChangesToServicesRequired
                  }
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.changesRequired && (
            <tr>
              <td className="ps-4">
                Do to assist :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.changesRequired}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.willSupporterAttendMediation && (
            <tr>
              <td className="ps-4">
                Bring support to mediation :{" "}
                <span className="ps-2">
                  {
                    data?.personalContactAndCaseInfo
                      .willSupporterAttendMediation
                  }
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.supporterNameAndRelation && (
            <tr>
              <td className="ps-4">
                Supporters Names :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.supporterNameAndRelation}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.ethnicOrigin && (
            <tr>
              <td className="ps-4">
                Ethnic origin :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.ethnicOrigin}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.hasBritishPassport && (
            <tr>
              <td className="ps-4">
                Have B.nationality/passport/P.R :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.hasBritishPassport}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.immigrationStatus && (
            <tr>
              <td className="ps-4">
                Immigration status in UK :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.immigrationStatus}{" "}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.howClientFoundDMS && (
            <tr>
              <td className="ps-4">
                Found out about our services :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.howClientFoundDMS}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.facedIssue && (
            <tr>
              <td className="ps-4">
                Issues that facing the client :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.facedIssue}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.isThereDaysCanNotAttend && (
            <tr>
              <td className="ps-4">
                Day/Dates that cannot make :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.isThereDaysCanNotAttend}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo?.appointmentTime && (
            <tr>
              <td className="ps-4">
                Appointment time :{" "}
                <span className="ps-2">
                  {data?.personalContactAndCaseInfo.appointmentTime}
                </span>
              </td>
            </tr>
          )}
          {data?.courtProceedings?.maritalStatus && (
            <tr>
              <td className="ps-4">
                Marital status :{" "}
                <span className="ps-2">
                  {data?.courtProceedings.maritalStatus}
                </span>
              </td>
            </tr>
          )}
          {data?.courtProceedings?.isFacingLegalProceedings && (
            <tr>
              <td className="ps-4">
                Aware of any legal proceedings :{" "}
                <span className="ps-2">
                  {data?.courtProceedings.isFacingLegalProceedings}
                </span>
              </td>
            </tr>
          )}
          {data?.courtProceedings?.courtApplicationKnown && (
            <tr>
              <td className="ps-4">
                Any court applications :{" "}
                <span className="ps-2">
                  {data?.courtProceedings.courtApplicationKnown}
                </span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo && (
            <tr>
              <td className="ps-4">
                Confirm that he/her read care letter :{" "}
                <span className="ps-2">Yes</span>
              </td>
            </tr>
          )}
          {data?.personalContactAndCaseInfo && (
            <tr>
              <td className="ps-4">
                Confirm that he/her watch the MIAM video :{" "}
                <span className="ps-2">Yes</span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MaimInfo;
