import React, { useEffect } from 'react'
import style from "./Cases.module.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {logout} from "../features/auth/adminLoginSlice"
const SecondNav = () => {
  const token = localStorage.getItem("adminLoginToken");
  const dispatch = useDispatch();
  useEffect(()=>{
  },[token])
  const navigate = useNavigate();
  const handleLogOut =(e)=>{
    e.preventDefault();
    // localStorage.removeItem("adminLoginToken");
    dispatch(logout());
    navigate("admin/login");
  }
    return (
        <nav className={`navbar navbar-expand-lg bg-white ${style.nav}`}>
          <div className="container-fluid bg-white">
            <div className={`${style.logo} `}>
              <img src="/images/dms-logo.png" alt="direct mediation logo" />
            </div>
            {token ? <div>
              <button onClick={handleLogOut} className="login-button py-2 px-4" >Logout</button>
            </div>: <></>}
          </div>
        </nav>
    )
}

export default SecondNav