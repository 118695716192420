import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../components/Layout";
import FormCard from "../components/FormCard";
import { forgetPassword } from "../features/auth/authActions";

const ForgetPassword = () => {
  const { forgetPasswordLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(forgetPassword(data)).then((res) => {
      if (res.payload.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail:
            res.payload.data.message === "Password reset email sent"
              ? "Password reset email sent, Please check your email"
              : "",
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: res.payload,
          life: 1500,
        });
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Layout />
      <Container fluid>
        <Row>
          <FormCard />
          <Col lg={8} className="p-0">
            <Container className="card-container-form ">
              <Container fluid className="form-container-authentication">
                <Col lg={6}>
                  <h1 className="form-header-authentication">
                    Forget Password
                  </h1>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label className="form-label">Enter email address:</label>
                    <input
                      className="form-input"
                      type="email"
                      data-test-mediator-email="name"
                      placeholder="email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <p className="errorMsg">Email is required</p>
                    )}
                    <button className="login-button py-2 mt-3" type="submit">
                      {forgetPasswordLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </Col>
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgetPassword;
