import { useEffect, useState } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";
import { Link } from "react-router-dom";
import DetailsC1 from "./DetailsC1";
import DetailsC2 from "./DetailsC2";
import Details from "./Details";
import { useGetCaseDetailsQuery } from "../../services/auth/authService";

const CaseDetails = ({ id }) => {
  const [CaseData, SetCaseData] = useState(null);
  const { data: CaseDetails, isFetching: fetchingCaseDetails } =
    useGetCaseDetailsQuery(id, {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true, // 15mins
    });
  useEffect(() => {
    if (CaseDetails) {
      SetCaseData(CaseDetails);
    }
  }, [CaseDetails, CaseData]);

  if (fetchingCaseDetails) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }
  if (CaseDetails)
    return (
      <>
        <div className="case-Details">
          <div className="d-flex justify-content-end">
            <Link
              className={`BackBtnDetails text-white text-decoration-none mb-4 px-3 py-3 d-flex me-md-5`}
              style={{
                backgroundColor: "#0337a4",
                alignItems: "center",
                justifyContent: "center",
              }}
              to={{ pathname: `/` }}
            >
              <div className=" rounded">
                <TiArrowBack className="fs-3" />{" "}
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    paddingTop: "20px",
                  }}
                  className="hideTheElement"
                >
                  Back
                </span>
              </div>
            </Link>{" "}
          </div>

          <Row
            className=" d-flex justify-content-center case-Details"
            style={{ width: "100%" }}
          >
            <Details
              status={CaseData?.status}
              startDate={CaseData?.MIAMDates}
              Reference={CaseData?.Reference}
              isSuitable={CaseData?.caseSuitable}
            />
            <Col md={6}>
              <DetailsC1
                MIAM1_C1={CaseData?.MIAM1_C1?.personalContactAndCaseInfo}
              />{" "}
            </Col>
            <Col md={6}>
              <DetailsC2 CaseData={CaseData} />
            </Col>
          </Row>
        </div>
      </>
    );
};

export default CaseDetails;
