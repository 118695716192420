import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { useGetCaseDetailsQuery } from "../../services/auth/authService";
import { deleteCaseLog } from "../../features/auth/authActions";
import style from "../Case/Case.module.css";

import AddNote from "./AddNote";
import EditCaseLogBody from "./EditCaseLogBody";
import { CaselogBody } from "./CaselogBody";
import { DisplayAllCaseLogs } from "./DisplayAllCaseLogs";

const CaseLog = () => {
  const [ShowAddNote, setShowAddNote] = useState(false);
  const [showCaseBodyLog, setshowCaseBodyLog] = useState(false);
  const [DisplayCaseBodyLog, setDisplayCaseBodyLog] = useState(false);
  const [showEditCaseBodyLog, setshowEditCaseBodyLog] = useState(false);
  const [_id, setId] = useState();
  const [CaseData, SetCaseData] = useState(null);

  const { id } = useParams();

  const dispatch = useDispatch();

  const toast = useRef(null);

  const accept = (idd) => {
    dispatch(deleteCaseLog({ _id: idd, id })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "you have deleted this case log",
          life: 3000,
        });
      }
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm2 = (id) => {
    confirmDialog({
      message: "Do you want to delete this case log?",
      header: "Delete Case Log",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(id),
      reject,
    });
  };

  const {
    data: CaseDetails,
    isFetching: fetchingCaseDetails,
    refetch,
  } = useGetCaseDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (CaseDetails) {
      SetCaseData(CaseDetails);
    }
  }, [CaseDetails, CaseData, setId]);

  function showBody(id) {
    setId(id);
  }

  return (
    <>
      {" "}
      <Toast ref={toast} />
      <ConfirmDialog />
      <CaselogBody
        showCaseBodyLog={showCaseBodyLog}
        close={() => setshowCaseBodyLog(false)}
        CaseData={CaseData}
        id={_id}
      />
      <EditCaseLogBody
        refetch={refetch}
        showEditCaseBodyLog={showEditCaseBodyLog}
        close={() => setshowEditCaseBodyLog(false)}
        CaseData={CaseData}
        idd={_id}
      />
      <DisplayAllCaseLogs
        refetch={refetch}
        DisplayCaseBodyLog={DisplayCaseBodyLog}
        close={() => setDisplayCaseBodyLog(false)}
        CaseData={CaseData}
        idd={_id}
      />
      {!fetchingCaseDetails && (
        <div
          className={` ${style.bg_containerDrive} rounded p-3 mt-3 ${style.CaseDrive} `}
        >
          <div>
            <div className={`${style.btnDrive} p-2 `}>
              <div className="d-flex justify-content-center mb-2">
                <Button
                  className="text-center bg-transparent"
                  onClick={() => setDisplayCaseBodyLog(true)}
                >
                  {" "}
                  Case Log
                </Button>
              </div>
              <div>
                <>
                  {CaseDetails?.caseLogs?.length === 0 ? (
                    <>
                      <div className=" text-center pt-3 mt-5">
                        There is no caseLog added yet
                      </div>
                    </>
                  ) : (
                    !fetchingCaseDetails &&
                    CaseDetails?.caseLogs.map((ele) => (
                      <div
                        className="d-flex justify-content-between"
                        key={ele._id}
                      >
                        <div>
                          <p
                            className={style.bodyCaseLog}
                            onClick={() => {
                              showBody(ele._id);
                              setshowCaseBodyLog(true);
                            }}
                          >
                            {ele?.logBody}
                          </p>
                        </div>
                        <div className=" ">
                          <AiFillDelete
                            className={style.CaseLogIcons}
                            onClick={() => {
                              confirm2(ele._id);
                            }}
                          />
                          <AiFillEdit
                            className={`${style.CaseLogIcons} ms-3`}
                            onClick={() => {
                              showBody(ele._id);
                              setshowEditCaseBodyLog(true);
                            }}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </>
              </div>
            </div>
            <Button
              className={`${style.btnGoToDrive} mt-3`}
              onClick={() => setShowAddNote(true)}
            >
              <AiOutlinePlus /> Add Note
            </Button>{" "}
            <AddNote
              ShowAddNote={ShowAddNote}
              close={() => setShowAddNote(false)}
              refetch={refetch}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CaseLog;
