import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import {
  useGetUserDetailsQuery,
  useGetAllMediatorsQuery,
} from "../services/auth/authService";
import { setCredentials } from "../features/auth/authSlice";
import DataTableCases from "../components/DataTable/DataTableCases";
import Styles from "./Cases.module.css";
import { googleAuth, removeMediator } from "../features/auth/authActions";
import { EditMediator } from "../components/DialogMediator/EditMediator";

const Cases = () => {
  const { token, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [_id, setId] = useState();
  const [showEditMediator, setshowEditMediator] = useState(false);
  const isGoogleLogged =
    userInfo?.user?.googleAccessToken === null ||
    userInfo?.user?.googleAccessToken === undefined;

  const toast = useRef(null);

  const { data, isFetching } = useGetUserDetailsQuery("userDetails", {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: allMediatorsData,
    isFetching: isFetchingGetAllMediators,
    refetch,
  } = useGetAllMediatorsQuery("allMediators", {
    refetchOnMountOrArgChange: true,
  });

  const accept = (idd) => {
    dispatch(removeMediator({ id: idd })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "mediator data has been removed",
          life: 3000,
        });
        setTimeout(() => {
          refetch();
        }, 1500);
      }
    });
  };
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm2 = (id) => {
    confirmDialog({
      message: "Do you want to delete this mediator?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(id),
      reject,
    });
  };

  const googleAuthHandler = () => {
    if (isGoogleLogged)
      dispatch(googleAuth())
        .then((res) => {
          window.open(res.payload.data, "_Self");
        })
        .catch((err) => {
          console.log({ err });
        });
  };

  // automatically authenticate user if token is found
  useEffect(() => {
    if (token) {
      if (data) {
        dispatch(setCredentials(data));
      }
    }
  }, [
    data,
    isFetching,
    isFetchingGetAllMediators,
    allMediatorsData,
    dispatch,
    token,
  ]);

  useEffect(() => {}, [isGoogleLogged]);

  if (isFetching || isFetchingGetAllMediators) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "1rem",
          }}
        >
          <Spinner animation="border" />
        </div>
      </>
    );
  }

  function showBody(id) {
    setId(id);
  }

  return (
    <>
      <EditMediator
        refetch={refetch}
        showEditMediator={showEditMediator}
        close={() => setshowEditMediator(false)}
        allMediatorsData={allMediatorsData}
        idd={_id}
      />
      <Toast ref={toast} />
      <ConfirmDialog />
      <Container fluid className="pt-4 cases_container mt-5">
        <Row>
          <Col xl={2} lg={12} md={12} sm={12}>
            <NavLink className={Styles.calendar_Button} to="/calendar">
              <div className={Styles.calendar_Button_Text}>Calendar</div>
            </NavLink>
            <NavLink className={Styles.legalAid} to="/legalAidTable">
              <div className={Styles.calendar_Button_Text}>Legal Aid Table</div>
            </NavLink>
            {userInfo?.role === "company" && (
              <div className={Styles.mediators_Container}>
                <div className={Styles.list_Mediators_Header_Container}>
                  <p className={Styles.list_Mediators_Header}>
                    List of Mediators
                  </p>
                </div>
                <div>
                  <div className={Styles.list_Of_Mediators}>
                    <>
                      {allMediatorsData?.length === 0 ? (
                        <>
                          <div className=" text-center pt-3 mt-5">
                            There is no mediator added yet
                          </div>
                        </>
                      ) : (
                        !isFetchingGetAllMediators &&
                        allMediatorsData?.map((ele) => (
                          <div
                            className="d-flex justify-content-between p-0"
                            key={ele.id}
                          >
                            <div>
                              <p className={Styles.bodyCaseLog}>{ele?.name}</p>
                            </div>
                            <div className=" ">
                              <AiFillDelete
                                className={Styles.CaseLogIcons}
                                onClick={() => {
                                  confirm2(ele.id);
                                }}
                              />
                              <AiFillEdit
                                className={`${Styles.CaseLogIcons} ms-3`}
                                onClick={() => {
                                  showBody(ele.id);
                                  setshowEditMediator(true);
                                }}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  </div>
                </div>
                <NavLink className={Styles.add_Mediator_Text} to="/addmediator">
                  <div className={`${Styles.add_Mediator_Button} mt-3`}>
                    Add Mediator
                  </div>
                </NavLink>
              </div>
            )}
            {userInfo?.role !== "mediator" && (
              <div
                className={`${Styles.googleAuth} mt-3`}
                style={
                  isGoogleLogged ? { cursor: "pointer" } : { cursor: "auto" }
                }
                onClick={googleAuthHandler}
              >
                <span className="d-flex justify-content-center align-items-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="25"
                    height="25"
                    viewBox="0 0 48 48"
                    className="me-2"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                  <p className="fs-6 fw-light mb-0">
                    {isGoogleLogged ? "Login With Google" : "Already Logged In"}
                  </p>
                </span>
              </div>
            )}
          </Col>
          <Col xl={10} lg={12} md={12} sm={12}>
            <DataTableCases />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Cases;
