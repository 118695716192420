import { Card, Col } from "react-bootstrap";

const FormCard = () => {
  return (
    <>
      <Col lg={4} className="p-0 d-lg-block d-none">
        <Card className="text-center card-container">
          <Card.Body className="text-center">
            <Card.Title className="card-container-title">
              Your Dashboard
            </Card.Title>
            <Card.Text className="card-container-text">
              Your dashboard is the place to see an overview of your cases. You
              can see a list of your active cases and when selected, view and
              edit client information, documentation, document and the MoU.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default FormCard;
