import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";

const LeaderAidClientHeader = ({
  legalAidFilterClient,
  handleLegalAidClientInputChange,
  dt,
}) => {
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <span className="p-input-icon-left ms-lg-0 ms-5  mb-lg-0 mb-3">
          <i className="pi pi-search" />
          <InputText
            value={legalAidFilterClient}
            onChange={handleLegalAidClientInputChange}
            placeholder="Search"
          />
        </span>
      </div>
      <div>
        <Button
          type="button"
          icon="pi pi-file-export"
          label="Download CSV"
          onClick={() => exportCSV(false)}
          data-pr-tooltip="CSV"
          className="me-3"
        />
        <Link
          className={`text-white text-decoration-none`}
          to={{ pathname: `/legalAidTable` }}
        >
          <Button
            label="Back"
            icon="pi pi-arrow-left"
            style={{ backgroundColor: "#0337a4" }}
          />
        </Link>
      </div>
    </div>
  );
};

export default LeaderAidClientHeader;
