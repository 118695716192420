import { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../features/auth/authActions";
import FormCard from "../components/FormCard";
import Error from "../components/Error";

const RegisterForm = ({ toggle }) => {
  const { loading, error, success } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitRegisterForm = (data) => {
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(registerUser(data));
  };

  useEffect(() => {
    // redirect user to login page if registration was successful
    if (success) navigate("/login");
  }, [success, navigate, toggle]);

  return (
    <Container fluid>
      <Row>
        <FormCard />
        <Col lg={8} className="p-0">
          <Container className="card-container-form">
            <Container fluid className="form-container-authentication">
              <Col lg={6}>
                <h1 className="form-header-authentication">Sign Up</h1>
                <form onSubmit={handleSubmit(submitRegisterForm)}>
                  <label className="form-label">Company Name:</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="company name"
                    {...register("companyName", { required: true })}
                  />
                  {errors.companyName &&
                    errors.companyName.type === "required" && (
                      <p className="errorMsg">Company Name is required</p>
                    )}
                  <label className="form-label">Email:</label>
                  <input
                    className="form-input"
                    type="email"
                    placeholder="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <p className="errorMsg">Email is required</p>
                  )}
                  <label className="form-label">Password:</label>
                  <input
                    className="form-input mb-3"
                    type="password"
                    placeholder="Password"
                    {...register("password", { required: true })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <p className="errorMsg">Password is required</p>
                  )}
                  {error && <Error>{error}</Error>}
                  <button className="login-button py-2 mt-1" type="submit">
                    {loading ? <Spinner animation="border" /> : "Register"}
                  </button>
                  <p className="mt-2">
                    already have an account.{" "}
                    <Link className="text-decoration-none " to="/login">
                      Sign in
                    </Link>
                  </p>
                </form>
              </Col>
            </Container>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterForm;
