import { useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import style from "../Case/Case.module.css";
import { sendMIAM2 } from "../../features/auth/authActions";

const Details = ({ status, startDate, Reference, isSuitable }) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  function changeColor(status) {
    switch (status) {
      case "MIAM Part 1-C2":
        return "lightblue";
      case "MIAM Part 2-C2":
        return "lightblue";
      case "MIAM Part 1-C1":
        return "darkblue";
      case "MIAM Part 2-C1":
        return "darkblue";
      case "Not proceeding with mediation":
        return "brown";
      case "Proceeding with mediation":
        return "lightgreen";
      case "Mediation Session 1":
        return "black";
      case "Mediation Session 2":
        return "black";
      case "Mediation Session 3":
        return "black";
      case "Mediation Session 4":
        return "black";
      case "Mediation Session 5":
        return "black";
      case "Broken":
        return "red";
      case "Agreed":
        return "green";
      case "Invitation to C2 sent":
        return "#f097a6";
      case "Not suitable for mediation":
        return "burgundy";
      case "Closed":
        return "grey";
      case "Successful":
        return "green";
      default:
        return "white";
    }
  }

  const sendMIAM2Email = (client) => {
    dispatch(sendMIAM2({ clientType: client, caseId: id })).then((res) => {
      console.log({ res });
      if (res?.payload?.data?.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else if (res.payload.status === "fail") {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: res.payload.message,
          life: 3000,
        });
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className={`${style.DetailsContainer} flexCenterElement `}>
        <div className={`${style.DetailsChild}`}>
          <p>Case Reference : </p>
          <div className={`${style.details_bg_light} w-50`}>
            {Reference ? Reference : ""}
          </div>
        </div>
        <div className={`${style.DetailsChild}`}>
          <p>Case Status : </p>
          <div
            className={`${style.status_bg_color} w-50`}
            style={{ backgroundColor: changeColor(status) }}
          >
            {status ? status : ""}
          </div>
        </div>
        <div className={`${style.DetailsChild}`}>
          <p>Date of MIAM C1: </p>
          <div className={`${style.details_bg_light} w-50`}>
            {startDate?.MIAM_C1_Date ? startDate?.MIAM_C1_Date : ""}
          </div>
        </div>
        <div className={`${style.DetailsChild}`}>
          <p>Date of MIAM C2: </p>
          <div className={`${style.details_bg_light} w-50`}>
            {startDate?.MIAM_C2_Date ? startDate?.MIAM_C2_Date : ""}
          </div>
        </div>
        <div
          className={`${style.DetailsChild} d-flex justify-content-center align-items-center`}
        >
          <Button
            label="Complete MIAM Part 2 - C1"
            onClick={() => {
              sendMIAM2Email("c1");
            }}
          />
        </div>
        <div
          className={`${style.DetailsChild} d-flex justify-content-center align-items-center`}
        >
          <Button
            label="Complete MIAM Part 2 - C2"
            onClick={() => {
              sendMIAM2Email("c2");
            }}
          />
        </div>
        <div className={`${style.DetailsChild} `}>
          <p className={`${style.last_p1}`}>Assessed for mediation : </p>
          <p className={style.last_p}> {isSuitable} </p>
        </div>
      </div>
    </>
  );
};

export default Details;
