import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

const LegalAidTableHeader = ({ legalAidFilter, handleLegalAidInputChange }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <span className="p-input-icon-left ms-lg-0 ms-5  mb-lg-0 mb-3">
          <i className="pi pi-search" />
          <InputText
            value={legalAidFilter}
            onChange={handleLegalAidInputChange}
            placeholder="Search"
          />
        </span>
      </div>
      <div className="d-flex justify-content-end">
        <Link to={"/legalAidDataTable"}>
          <Button
            label="Previous enquiries"
            icon="pi pi-table"
            className="me-2"
          />
        </Link>
        <Link
          className={`text-white text-decoration-none`}
          to={{ pathname: `/` }}
        >
          <Button
            label="Back"
            icon="pi pi-arrow-left"
            style={{ backgroundColor: "#0337a4" }}
          />
        </Link>
      </div>
    </div>
  );
};

export default LegalAidTableHeader;
