import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import OutsideClickHandler from "react-outside-click-handler";
import { Toast } from "primereact/toast";

import style from "../Case/Case.module.css";

import { useGetCaseDetailsQuery } from "../../services/auth/authService";

import RemenderBtn from "./RemenderBtn";
import SmsInfo from "./SmsInfo";
import UploadFiles from "./UploadFiles";
import SendCourt from "./SendCourt";
import SendAgreementToMediate from "./SendAgreementToMediate";
import InvitationAsSMS from "./InvitationAsSMS";
import InvitationAsEmail from "./InvitationAsEmail";
import SendMiamOne from "./SendMiamOne";
import SendMediationUpdate from "./SendMediationUpdate";
import MediationSessionRecords from "./MediationSessionRecords";
import InformationCIMEmail from "./CIMInformation";
import PropertyFianceTemplate from "./PropertyFianceTemplate";
import SendMIAM1Link from "./SendMIAM1Link";
import ConfirmationJointMediationSession from "./ConfirmationJointMediationSession.jsx";
import ReplaceMediator from "./ReplaceMediatorMail";
import { createPDF } from "../../features/auth/authActions.js";
import BookMiamOne from "./BookMiamOne";

const CaseBtns = () => {
  const { id } = useParams();
  const [ShowRemender, setShowRemender] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const [showEmailInvitation, setShowEmailInvitation] = useState(false);
  const [sendAgreement, setSendAgreementToMediate] = useState(false);
  const [ShowSmsModal, setShowSmsModal] = useState(false);
  const [showUploadFiles, setshowUploadFiles] = useState(false);
  const [c1, setC1] = useState(false);
  const [c2, setC2] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [sendForm, setsendForm] = useState(false);
  const [CaseData, SetCaseData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [sendMiamLink, setSendMiamLink] = useState(false);
  const [CIMInformation, setCIMInformation] = useState(false);
  const [mediationUpdate, setMediationUpdate] = useState(false);
  const [mediationSessionRecord, setMediationSessionRecord] = useState(false);
  const [propertyFiance, setPropertyFiance] = useState(false);
  const [showSendMIAM1Link, setshowSendMIAM1Link] = useState(false);
  const [showBookMiamOne, setshowBookMiamOne] = useState(false);
  const [showReplaceMediator, setshowReplaceMediator] = useState(false);
  const [ConfirmationJointSession, setConfirmationJointSession] =
    useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const userData = userInfo?.role;
  const { data: CaseDetails } = useGetCaseDetailsQuery(id, {
    pollingInterval: 900000,
    refetchOnMountOrArgChange: true, // 15mins
  });
  const toast = useRef(null);

  useEffect(() => {
    if (CaseDetails) {
      SetCaseData(CaseDetails);
    }
  }, [CaseDetails, CaseData]);

  // const handleClickOutside = (event) => {
  //   setMiam1(!maim1);
  //   // if (boxRef.current && !boxRef.current.contains(event.target)) {
  //   //   setMiam1(false);
  //   // }
  //   //  document.addEventListener('click', handleClickOutside);
  //   // return () => {
  //   //  document.removeEventListener('click', handleClickOutside);
  //   // };
  // };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmationJointMediationSession
        ConfirmationJointSession={ConfirmationJointSession}
        close={() => setConfirmationJointSession(false)}
      />

      <SendMIAM1Link
        showSendMIAM1Link={showSendMIAM1Link}
        close={() => setshowSendMIAM1Link(false)}
      />
      <InvitationAsSMS
        invitationSMS={showSms}
        close={() => setShowSms(false)}
        MIAM1_C1={CaseData?.MajorDataC1}
        MIAM1_C2={CaseData?.MajorDataC2}
      />
      <InvitationAsEmail
        showEmailInvitation={showEmailInvitation}
        close={() => setShowEmailInvitation(false)}
        MIAM1_C1={CaseData?.MajorDataC1}
        MIAM1_C2={CaseData?.MajorDataC2}
      />
      <SendMiamOne
        sendMiamLink={sendMiamLink}
        close={() => setSendMiamLink(false)}
        CaseData={CaseData}
      />
      <SendMediationUpdate
        mediationUpdate={mediationUpdate}
        close={() => setMediationUpdate(false)}
        MIAM1_C1={CaseData?.MIAM1_C1}
        MIAM1_C2={CaseData?.MIAM1_C2}
      />

      <PropertyFianceTemplate
        propertyFiance={propertyFiance}
        close={() => setPropertyFiance(false)}
      />
      <MediationSessionRecords
        mediationSessionRecord={mediationSessionRecord}
        close={() => setMediationSessionRecord(false)}
      />
      <InformationCIMEmail
        CIMInformation={CIMInformation}
        close={() => setCIMInformation(false)}
      />

      <ul className={` ${style.bg_container} rounded p-3`}>
        <OutsideClickHandler
          onOutsideClick={() => {
            // setMiam1(false);
          }}
        >
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns} ${style.hamada}`}
              onClick={() => {
                dispatch(createPDF({ id }))
                  .then((res) => {
                    if (res.payload.status === 200) {
                      toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: "PDF uploaded successfully",
                        life: 1500,
                      });
                    } else {
                      toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Address data is missing",
                        life: 1500,
                      });
                    }
                  })
                  .catch((err) => console.log({ err }));
              }}
            >
              C2 Invitation Letter
            </Button>{" "}
          </li>
        </OutsideClickHandler>

        {userData === "company" && (
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns} ${style.hamada}`}
              onClick={() => setshowBookMiamOne(true)}
            >
              Book a MIAM One
            </Button>
            <BookMiamOne
              showBookMiamOne={showBookMiamOne}
              close={() => setshowBookMiamOne(false)}
            />
          </li>
        )}

        <OutsideClickHandler
          onOutsideClick={() => {
            setC1(false);
          }}
        >
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns}  ${style.hamada}`}
              onClick={() => {
                setC1(!c1);
                setC2(false);
                setShowEmail(false);
                setShowDropdown(false);
              }}
            >
              <span>Access MIAM C1 </span>
              {c1 && (
                <ul className={`${style.dropList}`}>
                  <li>
                    <Link to={`/case/maim1/${id}`} className={`${style.Linkk}`}>
                      MIAM part 1/2
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/case/maim2/${id}`}
                      className={`${style.Linkk} ${style.BtnBorder}`}
                    >
                      MIAM part 2/2
                    </Link>
                  </li>
                </ul>
              )}
              <span className="ps-1">
                <AiFillCaretRight />
              </span>
            </Button>{" "}
          </li>
        </OutsideClickHandler>
        <OutsideClickHandler
          onOutsideClick={() => {
            setC2(false);
          }}
        >
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns} ${style.hamada}`}
              onClick={() => {
                setC1(false);
                setC2(!c2);
                setShowEmail(false);
                setShowDropdown(false);
              }}
            >
              <span> Access MIAM C2</span>{" "}
              {c2 && (
                <ul className={style.dropList}>
                  <li>
                    <Link to={`/case/MIAM1/${id}`} className={`${style.Linkk}`}>
                      MIAM part 1/2
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/case/MIAM2/${id}`}
                      className={`${style.BtnBorder} ${style.Linkk} `}
                    >
                      MIAM part 2/2
                    </Link>
                  </li>
                </ul>
              )}
              <span className="ps-1">
                <AiFillCaretRight />
              </span>
            </Button>{" "}
          </li>
        </OutsideClickHandler>
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowDropdown(false);
          }}
        >
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns} px-0 ${style.hamada}`}
              onClick={() => {
                setC1(false);
                setC2(false);
                setShowEmail(false);
                setShowDropdown(!showDropdown);
              }}
            >
              <span> Send reminder to client</span>
              {showDropdown && (
                <ul
                  className={`${style.dropList} ${style.BtnSendReminder}`}
                  style={{
                    zIndex: 10000,
                  }}
                >
                  <li>
                    <Link
                      className={`${style.Linkk}`}
                      onClick={(e) => setShowSms(true)}
                    >
                      Send sms invitation
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${style.Linkk} ${style.BtnBorder}`}
                      onClick={(e) => setShowEmailInvitation(true)}
                    >
                      Send email invitation
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className={`${style.Linkk} ${style.BtnBorder}`}
                      onClick={(e) => setSendMiamLink(true)}
                    >
                      Send MIAM Part 1 link
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className={`${style.Linkk} ${style.BtnBorder}`}
                      onClick={(e) => setMediationUpdate(true)}
                    >
                      Send mediation update
                    </Link>
                  </li>
                </ul>
              )}
              <span>
                <AiFillCaretRight />
              </span>
            </Button>{" "}
          </li>
        </OutsideClickHandler>
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowEmail(false);
          }}
        >
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns} ${style.hamada}`}
              onClick={() => {
                setC1(false);
                setC2(false);
                setShowEmail(!showEmail);
                setShowDropdown(false);
              }}
            >
              Send via email{" "}
              {showEmail && (
                <ul className={`${style.dropList} ${style.BtnSendViaEmail}`}>
                  <li>
                    <Link
                      to={`/datesForMediation/${id}`}
                      className={`${style.Linkk}`}
                    >
                      Dates for mediation session
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${style.Linkk} ${style.BtnBorder}`}
                      onClick={() => setPropertyFiance(true)}
                    >
                      Property & Finance templates
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${style.Linkk} ${style.BtnBorder}`}
                      onClick={() => setCIMInformation(true)}
                    >
                      CIM information
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${style.Linkk} ${style.BtnBorder}`}
                      to={`/case/MediationSessionRecordItems/${id}`}
                    >
                      Mediation session records
                    </Link>
                  </li>
                  <li>
                    <div
                      className={`${style.Linkk} ${style.BtnBorder} rounded-0`}
                      onClick={() => setshowSendMIAM1Link(true)}
                    >
                      Send MIAM - 1 link
                    </div>
                  </li>
                  <li>
                    <div
                      className={`${style.Linkk} ${style.BtnBorder} text-truncate rounded-0`}
                      onClick={() => setConfirmationJointSession(true)}
                    >
                      Confirmation-Joint Mediation Session
                    </div>
                  </li>
                </ul>
              )}
              <span className="ps-1">
                <AiFillCaretRight />
              </span>
            </Button>
          </li>
        </OutsideClickHandler>

        <li className={`${style.x} ${style.btns_style} `}>
          <Button
            className={`${style.Btns} ${style.paddingY} `}
            onClick={() => setSendAgreementToMediate(true)}
          >
            Send agreement to mediate
          </Button>{" "}
          <SendAgreementToMediate
            sendAgreement={sendAgreement}
            close={() => setSendAgreementToMediate(false)}
          />
        </li>

        <li className={`${style.x} ${style.btns_style}`}>
          <Link
            to={`/sentAppointmentConfirmation/${id}`}
            state={{ data: CaseData }}
            className={`${style.Btns} ${style.paddingY}  text-center rounded text-light text-decoration-none`}
          >
            Availability - Joint Mediation Session
          </Link>
        </li>

        <li className={style.btns_style}>
          <Button
            className={`${style.Btns} `}
            onClick={() => setshowUploadFiles(true)}
          >
            Upload files
          </Button>{" "}
          <UploadFiles
            showUploadFiles={showUploadFiles}
            close={() => setshowUploadFiles(false)}
          />
        </li>
        <li className={style.btns_style}>
          <Button
            className={`${style.Btns} `}
            onClick={() => setsendForm(true)}
          >
            Send court form
          </Button>{" "}
          <SendCourt sendForm={sendForm} close={() => setsendForm(false)} />{" "}
        </li>
        <li className={style.btns_style}>
          <Button
            className={`${style.Btns} `}
            onClick={() => setShowSmsModal(true)}
          >
            Compose a SMS
          </Button>{" "}
          <SmsInfo
            ShowSmsModal={ShowSmsModal}
            close={() => setShowSmsModal(false)}
            MIAM1_C1={CaseData?.MIAM1_C1}
            MIAM1_C2={CaseData?.MIAM1_C2}
          />
        </li>
        <li className={style.btns_style}>
          <Button
            className={`${style.Btns} `}
            onClick={() => setShowRemender(true)}
          >
            Set a reminder
          </Button>{" "}
          <RemenderBtn
            ShowRemender={ShowRemender}
            close={() => setShowRemender(false)}
          />
        </li>
        {userData === "company" && (
          <li className={style.btns_style}>
            <Button
              className={`${style.Btns} `}
              onClick={() => setshowReplaceMediator(true)}
            >
              Replace Mediator
            </Button>{" "}
            <ReplaceMediator
              showReplaceMediator={showReplaceMediator}
              close={() => setshowReplaceMediator(false)}
            />
          </li>
        )}
      </ul>
    </>
  );
};

export default CaseBtns;
