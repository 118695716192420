import { Button, Modal } from "react-bootstrap";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { addReminder } from "../../features/auth/authActions";
import styles from "../Case/Case.module.css";

const AddReminder = ({ close, showModal }) => {
  const dispatch = useDispatch();
  const toasttt = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const registerOptions = {
    title: {
      required: "Title is required",
    },
    time: {
      required: "Time is required",
    },
    date: {
      required: "Date is required",
    },
  };
  const onSubmit = (data) => {
    const reminderData = {
      reminderTitle: `${data.title}`,
      startDate: `${data.date}T${data.time}`,
    };
    dispatch(addReminder(reminderData)).then((res) => {
      if (res.payload.status === 200) {
        close();

        toasttt.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.res,
          life: 1500,
        });
      }
    });
  };

  return (
    <>
      <Toast ref={toasttt} />
      <Modal
        show={showModal}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0">
          <div className={`${styles.ReminderHeader} modal-header pb-0`}>
            <h4 className="text-center pt-4">Add Reminder</h4>
            <Button
              type="button"
              className={`bg-transparent border-0`}
              onClick={close}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Title:</label>
            <input
              className="form-input"
              type="text"
              {...register("title", registerOptions.title)}
            />
            <small className="text-danger">
              {errors?.title && errors.title.message}
            </small>
            <label className="form-label">Date:</label>
            <input
              className="form-input"
              type="date"
              {...register("date", registerOptions.date)}
            />
            <small className="text-danger">
              {errors?.date && errors.date.message}
            </small>
            <label className="form-label">Time:</label>
            <input
              className="form-input"
              type="time"
              {...register("time", registerOptions.time)}
            />
            <small className="text-danger">
              {errors?.time && errors.time.message}
            </small>
            <button className="login-button py-2 mt-3" type="submit">
              Add
              {/* {loading ? <Spinner animation="border" /> : "Add Company"} */}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddReminder;
