import React from 'react';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

function AccessBtn(rowData) {
  const navigate = useNavigate();
  const accessBtn=()=>{
    navigate(`/case/${rowData.rowData._id}`);
  }
  return (
    <>   <React.Fragment>
    <Button
      className="btn_access mr-2 me-2"
      style={{ backgroundColor: "#0337A4", border: 0 }}
      onClick={()=> accessBtn()}
    >
      Access the case
    </Button>
  </React.Fragment></>
  )
}

export default AccessBtn