import React from "react";
import Table from "react-bootstrap/Table";
import style from "../casemaim2/Case.module.css";

const MaimInfo2 = ({ data }) => {
  return (
    <div className={`w-100`}>
      <Table className={`${style.tableCase}  `} responsive>
        <tbody>
          {data?.mediationDetails?.MediatorName && (
            <tr>
              <td className={`${style.left_td} ps-4`}>Name of Mediator :</td>
              <td className={` ${style.right_td} ps-4`}>
                <span className="ps-2">
                  {data?.mediationDetails?.MediatorName}
                </span>
              </td>
            </tr>
          )}
          {data?.mediationDetails?.MediatorEmail && (
            <tr>
              <td className="ps-4">Mediator's email :</td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.mediationDetails?.MediatorEmail}
                </span>
              </td>
            </tr>
          )}
          {data?.mediationDetails?.DateOfMIAM && (
            <tr>
              <td className="ps-4">Date of MIAM :</td>
              <td className="ps-4">
                <span className={`ps-2`}>
                  {data?.mediationDetails?.DateOfMIAM}
                </span>
              </td>
            </tr>
          )}

          {data?.mediationDetails?.Location && (
            <tr>
              <td className="ps-4">Location :</td>
              <td className="ps-4">
                <span className="ps-2">
                  {" "}
                  {data?.mediationDetails?.Location}
                </span>
              </td>
            </tr>
          )}
          {data?.mediationDetails?.clientFirstName && (
            <tr>
              <td className="ps-4">Client's first name :</td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.mediationDetails?.clientFirstName}
                </span>
              </td>
            </tr>
          )}
          {data?.mediationDetails?.clientSurName && (
            <tr>
              <td className="ps-4">Client's surname :</td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.mediationDetails?.clientSurName}
                </span>
              </td>
            </tr>
          )}

          {data?.mediationDetails?.clientEmail && (
            <tr>
              <td className="ps-4">Client's email :</td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.mediationDetails?.clientEmail}
                </span>
              </td>
            </tr>
          )}
          {data?.mediationDetails?.otherPartySurname && (
            <tr>
              <td className="ps-4">Other party's surname :</td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.mediationDetails?.otherPartySurname}
                </span>
              </td>
            </tr>
          )}

          {data?.caseDetails?.privateOrLegalAid && (
            <tr>
              <td className="ps-4">Private/LA : </td>
              <td className="ps-4">
                <span className={`${style.funding} ms-2`}>
                  {data?.caseDetails.privateOrLegalAid}
                </span>
              </td>
            </tr>
          )}
          {data?.caseDetails?.privateOrLegalAid && (
            <tr>
              <td className="ps-4">Appointment's payment : </td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.caseDetails?.privateOrLegalAid}
                </span>
              </td>
            </tr>
          )}
          {data?.caseDetails?.whyPaymentNotMade && (
            <tr>
              <td className="ps-4">Why Payment not paid : </td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.caseDetails?.whyPaymentNotMade}
                </span>
              </td>
            </tr>
          )}
          {data?.caseDetails?.advancePayment && (
            <tr>
              <td className="ps-4">Payment in advance explained : </td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.caseDetails?.advancePayment}
                </span>
              </td>
            </tr>
          )}
          {data?.comments?.MediatorComments && (
            <tr>
              <td className="ps-4">Comments from the mediator :</td>
              <td className="ps-4">
                <span className="ps-2">{data?.comments?.MediatorComments}</span>
              </td>
            </tr>
          )}
          {data?.comments?.isDomesticAbuseOrViolence && (
            <tr>
              <td className="ps-4">Domestic abuse / violence : </td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.comments?.isDomesticAbuseOrViolence === "Yes"
                    ? "Yes" +
                      " ( " +
                      data?.comments.domesticAbuseOrViolenceDetails +
                      " )"
                    : "No"}

                  {data?.comments?.isDomesticAbuseOrViolence}
                </span>
              </td>
            </tr>
          )}
          {data?.comments?.isPoliceInvolve && (
            <tr>
              <td className="ps-4">Police involvement : </td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.comments?.isPoliceInvolve === "Yes"
                    ? "Yes" + " ( " + data?.comments.policeInvolveDetails + " )"
                    : "No"}
                </span>
              </td>
            </tr>
          )}
          {data?.comments?.isSocialServiceInvolve && (
            <tr>
              <td className="ps-4">Social Services involvement : </td>
              <td className="ps-4">
                <span className="ps-2">
                  {data?.comments?.isSocialServiceInvolve === "Yes"
                    ? "Yes" +
                      " ( " +
                      data?.comments.socialServiceInvolveDetails +
                      " )"
                    : "No"}
                </span>
              </td>
            </tr>
          )}
          {data?.comments?.isSafeguardingIssues && (
            <tr>
              <td className={`${style.right_td} ${style.left_td} ps-4`}>
                Safeguarding issues :{" "}
              </td>
              <td className={`${style.right_td} ${style.left_td} ps-4`}>
                <span className="ps-2">
                  {data?.comments?.isSafeguardingIssues}
                </span>
              </td>
            </tr>
          )}
          {data?.comments?.isCourtOrders && (
            <tr>
              <td className={`ps-4`}>Court orders in place : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.comments?.isCourtOrders === "Yes"
                    ? "Yes" + " ( " + data?.comments.courtOrdersDetails + " )"
                    : "No"}
                </span>
              </td>
            </tr>
          )}
          {data?.comments?.isClientRequireSignposting && (
            <tr>
              <td className={`ps-4`}>Client require signposting : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.comments?.isClientRequireSignposting === "Yes"
                    ? "Yes" +
                      " ( " +
                      data?.comments.clientRequireSignpostingDetails +
                      " )"
                    : "No"}
                </span>
              </td>
            </tr>
          )}
          {data && (
            <tr>
              <td className={`ps-4`}>Willing to go ahead with mediation : </td>
              <td className={`ps-4`}>
                <span className="ps-2">YES</span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.reasonsForRefusal && (
            <tr>
              <td className={`ps-4`}>Reasons for refusal : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.reasonsForRefusal}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.confirmLegalDispute && (
            <tr>
              <td className={`ps-4`}>Confirm legal dispute : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.confirmLegalDispute}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.isChildInclusiveAppropriate && (
            <tr>
              <td className={`ps-4`}>
                Child Inclusive Mediation appropriate :{" "}
              </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.isChildInclusiveAppropriate}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.doesClientWishForChildInclusive && (
            <tr>
              <td className={`ps-4`}>
                Client wish for Child Inclusive Mediation :{" "}
              </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.doesClientWishForChildInclusive}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.informationGivenToClient && (
            <tr>
              <td className={`ps-4`}>
                Information about other forms of ADR :{" "}
              </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.informationGivenToClient}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.clientPreference && (
            <tr>
              <td className={`ps-4`}>Mediation online/in person : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.clientPreference}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes?.areSeparateWaitingAreasRequired && (
            <tr>
              <td className={`ps-4`}>Separate waiting areas required : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.MediationTypes?.areSeparateWaitingAreasRequired}
                </span>
              </td>
            </tr>
          )}
          {data?.MediationTypes
            ?.areSeparateArrivalAndDepartureTimesRequired && (
            <tr>
              <td className={`ps-4`}>
                Separate arrival and departure times required :{" "}
              </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {
                    data?.MediationTypes
                      ?.areSeparateArrivalAndDepartureTimesRequired
                  }
                </span>
              </td>
            </tr>
          )}
          {data?.FinalComments?.isSuitable && (
            <tr>
              <td className={`ps-4`}>Case suitable for mediation : </td>
              <td className={`ps-4`}>
                <span className="ps-2">{data?.FinalComments?.isSuitable}</span>
              </td>
            </tr>
          )}
          {data?.FinalComments?.uploadCourtForm && (
            <tr>
              <td className={`ps-4`}>Upload a court form : </td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.FinalComments?.uploadCourtForm}
                </span>
              </td>
            </tr>
          )}
          {data?.FinalComments?.CommentsToDMS && (
            <tr>
              <td className={`ps-4`}>Comments to DMS admin team :</td>
              <td className={`ps-4`}>
                <span className="ps-2">
                  {data?.FinalComments?.CommentsToDMS}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MaimInfo2;
