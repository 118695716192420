import React, { useRef } from 'react'
import { Button, Modal } from "react-bootstrap";
import { Toast } from "primereact/toast";
import styles from "../../Case/Case.module.css";


const EntryFormInfo = ({ showInfoModal, close }) => {
    const toast = useRef(null);

    return (
        <>
          <Toast ref={toast} />
          <Modal
            show={showInfoModal}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className=" border-0">
              <div className={`${styles.ReminderHeader}   pb-0`}>
                <h4 className="text-center pt-4">Header</h4>
                <Button
                  type="button"
                  className={`bg-transparent border-0`}
                  onClick={close}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    fill="#040848"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="px-4 pb-4">
              fxchjbnlm
            </div>
          </Modal>
        </>
      );
}

export default EntryFormInfo