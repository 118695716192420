import React, { useEffect, useRef, useState } from "react";
import styles from "./Case.module.css";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendM1_mail_C1, sendM1_mail_C2 } from "../../features/auth/authActions";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";

const SendMIAM1Link = ({  showSendMIAM1Link,
    close}) => {
    const { id } = useParams();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [selectedOption,setselectedOption]=useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch
    } = useForm();
  const SelectedClient=watch("Client")
  useEffect(()=>{
    setselectedOption(SelectedClient)


  },[SelectedClient,selectedOption])

    const onSubmit = async (data) => {
      setIsLoading(true);
  
      if (selectedOption==='C1') {
       
        dispatch(sendM1_mail_C1({id})).then((res) => {
          if (res.payload.status === 200) {
            setIsLoading(false);
            close();
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res.payload.data.message,
              life: 1500,
            });
          }
        });
      }else if(selectedOption==='C2'){
        dispatch(sendM1_mail_C2({id})).then((res) => {
            if (res.payload.status === 200) {
              setIsLoading(false);
              close();
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: res.payload.data.message,
                life: 1500,
              });
            }
            else {
              setIsLoading(false);
              close();
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 1500,
              });
            }
          });
      }
    };
    return (
        <>
          <Toast ref={toast} />
          <Modal
            show={showSendMIAM1Link}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className=" border-0">
              <div className={`${styles.ReminderHeader}  pb-0`}>
                <h4 className="text-center pt-4">Send MIAM - 1 link</h4>
                <Button
                  type="button"
                  className={`bg-transparent border-0`}
                  onClick={close}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    fill="#040848"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <select
                  defaultValue=""
                  className="select-input"
                  {...register("Client", { required: true })}
                >
                  <option value="" disabled>
                    Select one option
                  </option>
                  <option>
                    C1 
                  </option>
                  <option>
                    C2
                  </option>
                </select>
                <small className="text-danger">
                  {errors.Client && <span>This field is required</span>}
                </small>
    
                <button className="login-button py-2 mt-3" type="submit">
                  {isLoading ? <Spinner animation="border" /> : "Send"}
                </button>
              </form>
            </div>
          </Modal>
        </>
      );
}

export default SendMIAM1Link