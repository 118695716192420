import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CaseBtns from "../Case/CaseBtns";
import CaseDrive from "../Case/CaseDrive";
import ChatDetails from "./ChatDetails";

function ChatSMS() {
  return (
    <Container fluid className="mt-5">
      <Row>
        <Col xl={2} className="py-3">
          <CaseBtns />
        </Col>
        <Col xl={8}>
          <ChatDetails />
        </Col>
        <Col xl={2} className="py-3">
          {" "}
          <CaseDrive />{" "}
        </Col>
      </Row>
    </Container>
  );
}

export default ChatSMS;
