import React, {  useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  updateReminder,
} from "../../features/auth/authActions";
import Style from "./addReminder.module.css";

const UpdateReminder = (props) => {
  const dispatch = useDispatch();
  const [modalUpdate, setModalUpdate] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const close = () => {
    props.close();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const reminderData = {
      _id: props.eventData.event._def.publicId,
      reminderTitle: `${data.title}`,
      startDate: `${data.date}T${data.time}`,
    };
  
    dispatch(updateReminder(reminderData))
      .then((response) => {
        if (response.payload.res === "you don't have access on this reminder") {
          setErrorMsg(response.payload.res);
        } else {
          window.location.reload();
          close();
        }
      })
      .catch((error) => {
      });
  };

  let date;

  const handleShowUpdateModal = () => {
    setModalUpdate(true);
  };

  const deleteReminder = () => {
    props.delete();
  };

  useEffect(() => {}, [modalUpdate]);

  const UpdateModal = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={Style.modalContainer}>
            <div className={Style.backdrop} onClick={close}></div>
            <div className={Style.modal}>
              <div className={Style.closeIconContainer}>
                <i className="pi pi-times" onClick={close}></i>
              </div>
              <div className={Style.inputsGroup}>
                <h3 className={Style.header}>Update Reminder</h3>
                <div>
                  <label className={`form-label ${Style.form_label}`}>
                    Title
                  </label>
                  <input
                    className={`form-input ${Style.form_input}`}
                    type="text"
                    placeholder={props.eventData.event._def.title}
                    {...register("title", {
                      required: true,
                    })}
                  />
                  {errors.title && errors.title.type === "required" && (
                    <p className="errorMsg">Title is required</p>
                  )}
                </div>
                <div>
                  <label className={`form-label ${Style.form_label}`}>
                    Enter new date
                  </label>
                  <input
                    className={`form-input ${Style.form_input}`}
                    type="date"
                    placeholder={date}
                    defaultValue={date}
                    {...register("date", {
                      required: true,
                    })}
                  />
                  {errors.date && errors.date.type === "required" && (
                    <p className="errorMsg">Date is required</p>
                  )}
                </div>
                <div>
                  <label className={`form-label ${Style.form_label}`}>
                    Enter new time
                  </label>
                  <input
                    className={`form-input ${Style.form_input}`}
                    type="time"
                    placeholder="Time"
                    {...register("time", {
                      required: true,
                    })}
                  />
                  {errors.time && errors.time.type === "required" && (
                    <p className="errorMsg">Time is required</p>
                  )}
                </div>
                {errorMsg === "you don't have access on this reminder" && (
                  <p className="errorMsg">{errorMsg}</p>
                )}
              </div>
              <div className={Style.addButtonContainer}>
                <button className={Style.addButton}>Update</button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };
  const questionModal = () => {
    return (
      <div className={Style.modalContainer}>
        <div className={Style.backdrop} onClick={close}></div>
        <div className={Style.modal}>
          <div className={Style.closeIconContainer}>
            <i className="pi pi-times" onClick={close}></i>
          </div>
          <div className={Style.inputsGroup}>
            <p className={Style.header}>
              Do you want to update or delete this reminder?
            </p>
          </div>
          <div className={Style.buttonsContainer}>
            <button
              className={Style.updateButton}
              onClick={handleShowUpdateModal}
            >
              Update
            </button>
            <button className={Style.deleteButton} onClick={deleteReminder}>
              Delete
            </button>
          </div>
          {props.erorrs === "you don't have access on this reminder" && (
            <p className="errorMsg">{props.erorrs}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {modalUpdate ? <div>{UpdateModal()}</div> : <div>{questionModal()}</div>}
    </>
  );
};

export default UpdateReminder;
