export const hideDialog = (setProductDialog) => {
  setProductDialog(false);
};

export const saveProduct = (
  product,
  products,
  setProducts,
  toast,
  createId,
  findIndexById,
  setProductDialog,
  setProduct,
  emptyProduct
) => {
  if (product.name.trim()) {
    let _products = [...products];
    let _product = { ...product };

    if (product.id) {
      const index = findIndexById(product.id);

      _products[index] = _product;
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Product Updated",
        life: 3000,
      });
    } else {
      _product.id = createId();
      _product.image = "product-placeholder.svg";
      _products.push(_product);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Product Created",
        life: 3000,
      });
    }

    setProducts(_products);
    setProductDialog(false);
    setProduct(emptyProduct);
  }
};

export const hidecloseCaseDialog = (setCloseCaseDialog) => {
  setCloseCaseDialog(false);
};

export const openNew = (setProduct, emptyProduct, setProductDialog) => {
  setProduct(emptyProduct);
  setProductDialog(true);
};

export const editProduct = (product, setProduct, setProductDialog) => {
  setProduct({ ...product });
  setProductDialog(true);
};

export const findIndexById = (id, products) => {
  let index = -1;

  for (let i = 0; i < products.length; i++) {
    if (products[i].id === id) {
      index = i;
      break;
    }
  }

  return index;
};

export const createId = () => {
  let id = "";
  let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return id;
};

export const getSeverity = (product) => {
  switch (product.status) {
    case "MIAM Part 1-C2":
      return "light-blue";

    case "MIAM Part 2-C2":
      return "light-blue";

    case "MIAM Part 1-C1":
      return "dark-blue";

    case "MIAM Part 2-C1":
      return "dark-blue";

    case "Not proceeding with mediation":
      return "brown";
    case "Proceeding with mediation":
      return "light-green";
    case "Mediation Session N":
      return "black";
    case "Broken":
      return "red";
    case "Agreed":
      return "green";
    case "Invitation to C2 sent":
      return "#f097a6";
    case "Not suitable for mediation":
      return "burgundy";
    case "Closed":
      return "grey";
    case "Successful":
      return "green";

    default:
      return null;
  }
};

export const exportCSV = (dt) => {
  dt.current.exportCSV();
};

export const getItem = (product) => {
  switch (product.Closed) {
    case "closed":
      return "success";

    case "open":
      return "danger";

    default:
      return null;
  }
};
