import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { useParams } from "react-router-dom";

import CaseBtns from "../components/Case/CaseBtns";
import CaseDrive from "../components/Case/CaseDrive";
import CaseDetails from "../components/Case/CaseDetails";
import { useGetCaseDetailsQuery } from "../services/auth/authService";
import Calendar from "../components/Calendar/calendar";

const Case = () => {
  const { id } = useParams();
  const [CaseData, SetCaseData] = useState(null);
  const { data: details } = useGetCaseDetailsQuery(id, {
    pollingInterval: 900000,
    refetchOnMountOrArgChange: true, // 15mins
  });

  useEffect(() => {
    if (details) {
      SetCaseData(details);
    }
  }, [details, CaseData]);

  return (
    <>
      <Container fluid className="mt-5 caseSpliter d-lg-block d-none">
        <Row>
          <Col xl={2} className="py-3">
            <CaseBtns />
          </Col>
          <Col className="mt-3" xl={10}>
            <Splitter
              style={{
                minHeight: "700px",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <SplitterPanel style={{ overflow: "auto" }}>
                <div className="SplitterPanel d-flex w-100 ">
                  <CaseDetails id={id} />
                  <CaseDrive />{" "}
                </div>
              </SplitterPanel>
              <SplitterPanel style={{ overflow: "auto", maxWidth: "400px" }}>
                <div
                  className="split-calendar-table "
                  style={{ width: "400px" }}
                >
                  <Calendar isSplitter={true} />
                </div>
              </SplitterPanel>
            </Splitter>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5 d-lg-none">
        <Row>
          <Col xl={2} className="py-3">
            <CaseBtns />
          </Col>
          <Col xl={8} className="mt-3">
            <CaseDetails id={id} />
          </Col>
          <Col xl={2} className="py-3">
            {" "}
            <CaseDrive />{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Case;
