import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addCaseLog } from "../../features/auth/authActions";
import { Toast } from "primereact/toast";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGetUserDetailsQuery } from "../../services/auth/authService";
import { setCredentials } from "../../features/auth/authSlice";

const AddNote = ({ ShowAddNote, close, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { id } = useParams();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data } = useGetUserDetailsQuery("userDetails", {
    refetchOnMountOrArgChange: true,
  });

  const { token, userInfo } = useSelector((state) => state.auth);
  const userData = userInfo?.role;

  useEffect(() => {
    if (token) {
      if (data) dispatch(setCredentials(data));
    }
  }, [data, dispatch, token]);


  const onSubmit = (data) => {
    const logBody = data.logBody;
    const subName = data.subName;
    setIsLoading(true);
    dispatch(addCaseLog({ id, logBody, subName })).then((res) => {
      refetch();
      if (res.payload.status === 200) {
        setIsLoading(false);
        close();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: res.payload.data.res,
          life: 1500,
        });
        reset();
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={ShowAddNote}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" border-0 d-flex justify-content-end">
          <div className={`  pb-0  `}>
            {/* <h4 className="text-center pt-4">Send Court Form</h4> */}
            <Button
              onClick={close}
              type="button"
              className={`bg-transparent border-0`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#040848"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="form-label">Add Note:</label>
            <textarea
              rows={5}
              className="h-100 select-input  mt-3"
              {...register("logBody", { required: true })}
              type="text"
            ></textarea>
            <small className="text-danger">
              {errors.logBody && <span>This field is required</span>}
            </small>

            {userData === "company" && (
              <>
                <label className="form-label">Author Name:</label>
                <input
                  className="h-100 form-input py-2  mt-3"
                  {...register("subName", { required: true })}
                  type="text"
                />
                <small className="text-danger">
                  {errors.subName && <span>Author Name</span>}
                </small>
              </>
            )}
            <button className="login-button py-2 mt-3" type="submit">
              {isLoading ? <Spinner animation="border" /> : "Add"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddNote;
