
import React from "react";
import style from "../Case/Case.module.css";
import Table from "react-bootstrap/Table";

const DisplayMoreDetails = ({data}) => {
  return (
    <div className={`${style.moreDetails} mt-0 pt-2`} style={{zIndex:99}}>
    <Table className={`${style.tableDetails} mt-0 pt-0 `}>
      <tbody>
        {data?.dateOfbirth && (
          <tr>
          <td className={`ps-4 `}>
            Date of birth : <span className="ps-2">{data.dateOfbirth}</span>
          </td>
        </tr>
        )}
        {data?.gender && (
          <tr>
          <td className="ps-4">
            Gender : <span className="ps-2">{data.gender}</span>
          </td>
        </tr>
        )}
        {data?.phoneNumber && (
          <tr>
          <td className="ps-4">
            Phone number : <span className="ms-2">{data.phoneNumber}</span>
          </td>
        </tr>
        )}
        {data?.email && (
          <tr>
          <td className="ps-4">
            Email : <span className="ps-2">{data.email}</span>
          </td>
        </tr>
        )}
        {data?.suitability && (
          <tr>
          <td className="ps-4">
            MIAM suitability : <span className="ps-2">YES / NO</span>
          </td>
        </tr>
        )}

      {data?.street && (
            <tr>
              <td className="ps-4">
                Address :{" "}
                <span className="ps-2">
                  {data?.street +
                    " , " +
                    data?.city +
                    " , " +
                    data?.postCode +
                    " , " +
                    data?.country}
                </span>
              </td>
            </tr>
          )}     

      </tbody>
    </Table>
  </div>
  )
}

export default DisplayMoreDetails