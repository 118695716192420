import { createSlice } from "@reduxjs/toolkit";

import {
  addMediator,
  forgetPassword,
  googleAuth,
  loginCompany,
  loginMediator,
  registerUser,
  resetPassword,
} from "./authActions";

// initialize userToken from local storage
const token = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;

const initialState = {
  loading: false,
  userInfo: null, // for user object
  token, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
  forgetPasswordLoading: false,
  forgetPasswordError: null,
  forgetPasswordSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: false,
  resetPasswordSuccess: false,
  googleAuthLink: null,
  mediatorLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.token = null;
      state.error = null;
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: {
    // login Company
    [loginCompany.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.token = payload.accessToken;
      state.userInfo = payload;
    },
    [loginCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addMediator.pending]: (state) => {
      state.mediatorLoading = true;
    },
    [addMediator.fulfilled]: (state) => {
      state.mediatorLoading = false;
    },
    [addMediator.rejected]: (state) => {
      state.mediatorLoading = false;
    },
    // login Mediator
    [loginMediator.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginMediator.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.token = payload.accessToken;
    },
    [loginMediator.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // forget user
    [forgetPassword.pending]: (state) => {
      state.forgetPasswordLoading = true;
    },
    [forgetPassword.fulfilled]: (state) => {
      state.forgetPasswordLoading = false;
      state.forgetPasswordSuccess = true;
    },
    [forgetPassword.rejected]: (state, { payload }) => {
      state.forgetPasswordLoading = false;
      state.forgetPasswordError = payload;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordLoading = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.resetPasswordSuccess = true;
    },
    [googleAuth.fulfilled]: (state, { payload }) => {
      state.googleAuthLink = payload.data;
    },
  },
});

export const { logout, setCredentials } = authSlice.actions;
export default authSlice.reducer;
