import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";

import styles from "./form.module.css";
import { addLegalAidClients } from "../../../features/auth/authActions";

const LegalAidForm = ({ close, legalAidModal, setLegalAidModal }) => {
  const [firstname, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [typeOfApplication, setTypeOfApplication] = useState("");
  const dispatch = useDispatch();
  const toast = useRef(null);

  function convertToLocalFormat(phoneNumber) {
    // Remove any non-digit characters from the input
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    // Check if the number starts with "07"
    if (cleanedNumber.startsWith("07")) {
      // Replace "07" with "+44"
      return "+44" + cleanedNumber.slice(2);
    } else {
      // If it doesn't start with "07", return the number as is
      return phoneNumber;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const twilioNumber = convertToLocalFormat(phone);

    const Obj = {
      firstname,
      surname,
      phone: twilioNumber,
      email,
      typeOfApplication,
    };

    dispatch(addLegalAidClients(Obj))
      .then((res) => {
        if (res.payload.status === 200) {
          setLegalAidModal(false);
          setFirstName("");
          setSurname("");
          setPhone("");
          setEmail("");
          setTypeOfApplication("");
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Legal Aid client added successfully",
            life: 3000,
          });
        } else if (res.error.message === "Rejected") {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong",
            life: 3000,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Apply for Legal Aid"
        visible={legalAidModal}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={close}
      >
        <form className="m-2" onSubmit={(e) => onSubmit(e)}>
          <Message
            severity="warn"
            text="Make sure that you are login with google, and config twilio data, and add a Sharing Gmail"
            style={{
              width: "100%",
              textAlign: "center",
            }}
          />
          <div className="d-flex flex-column m-2 pop_up_label">
            <label htmlFor="first-name" className="fw-bold fs-5">
              First Name
            </label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              className={`${styles.textInput} form-control`}
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="d-flex flex-column m-2 pop_up_label">
            <label htmlFor="second-name" className="fw-bold fs-5">
              Second Name
            </label>
            <input
              type="text"
              name="second-name"
              id="second-name"
              className={`${styles.textInput} form-control`}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              required
            />
          </div>
          <div className="d-flex flex-column m-2 pop_up_label">
            <label htmlFor="phone" className="fw-bold fs-5">
              Phone
            </label>
            <p className="text-primary fs-6 m-0 p-0">
              Enter your mobile phone without spaces which you use for WhatsApp.
              Example 07123456789
            </p>
            <input
              type="text"
              name="phone"
              id="phone"
              className={`${styles.textInput} form-control`}
              value={phone}
              pattern="^(07[\d]{8,12}|447[\d]{7,11})$"
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="d-flex flex-column m-2 pop_up_label">
            <label htmlFor="email" className="fw-bold fs-5">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={`${styles.textInput} form-control`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="d-flex flex-column m-2 pop_up_label">
            <label className="fw-bold fs-5">Type of application</label>
            <div className="d-flex flex-column fs-5 my-2">
              <div>
                <input
                  type="radio"
                  name="typeOfApplication"
                  value="Passport Benefits"
                  id="Passport Benefits"
                  checked={typeOfApplication === "Passport Benefits"}
                  onChange={(e) => setTypeOfApplication(e.target.value)}
                />
                <label htmlFor="Passport Benefits" className="ms-1">
                  Passport Benefits
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="typeOfApplication"
                  value="Low Income"
                  id="Low Income"
                  checked={typeOfApplication === "Low Income"}
                  onChange={(e) => setTypeOfApplication(e.target.value)}
                />
                <label htmlFor="Low Income" className="ms-1">
                  Low Income
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              label="Apply"
              severity="info"
              icon="pi pi-check"
              type="submit"
            />
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default LegalAidForm;
