// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.directmediationservices.co.uk/`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: "/auth/user-info",
        method: "GET",
      }),
    }),
    getAllMediators: builder.query({
      query: () => ({
        url: "/getMediators",
        method: "GET",
      }),
    }),
    getCaseList: builder.query({
      query: () => ({
        url: "/getCasesList",
        method: "GET",
      }),
    }),
    getCaseDetails: builder.query({
      query: (id) => ({
        url: `/getCasesDetails/${id}`,
        method: "GET",
      }),
    }),
    getAllReminders: builder.query({
      query: () => ({
        url: "/getReminders",
        method: "GET",
      }),
    }),
    getchatSMSC1: builder.query({
      query: (id) => ({
        url: `/chatSMSC1/${id}`,
        method: "GET",
      }),
    }),
    getLegalAidClients: builder.query({
      query: () => ({
        url: "/legalAidClients/?submited=true",
        method: "GET",
      }),
    }),
    legalAidClients: builder.query({
      query: () => ({
        url: "/legalAidClients",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useGetAllMediatorsQuery,
  useGetCaseListQuery,
  useGetCaseDetailsQuery,
  useGetAllRemindersQuery,
  useGetchatSMSC1Query,
  useGetLegalAidClientsQuery,
  useLegalAidClientsQuery,
} = authApi;
