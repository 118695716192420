import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner, Modal } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import { useGetLegalAidClientsQuery } from "../../services/auth/authService";
import {
  deleteLegalAidClient,
  editTypeOfApplication,
  furtherEvidenceRequired,
} from "../../features/auth/authActions";

import LegalAidTableHeader from "./LegalAidTableHeader";
import styles from "./legalAidTable.module.css";

const LegalAidTable = () => {
  const toast = useRef(null);
  const [refusalOptions, setRefusalOptions] = useState([]);
  const [loader, setIsLoading] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState("");
  const [Option1, setOption1] = useState("");
  const [Option2, setOption2] = useState("");
  const [Option3, setOption3] = useState("");
  const [Option4, setOption4] = useState("");
  const [Option5, setOption5] = useState("");
  const [Option6, setOption6] = useState("");
  const [isThereOption2, setIsThereOption2] = useState("No");
  const [isThereOption3, setIsThereOption3] = useState("No");
  const [isThereOption4, setIsThereOption4] = useState("No");
  const [isThereOption5, setIsThereOption5] = useState("No");
  const [isThereOption6, setIsThereOption6] = useState("No");
  const [applicationType, setApplicationType] = useState("");
  const [dataValue, setDataValue] = useState();
  const [legalAidFilter, setLegalAidFilter] = useState("");
  const [show, setShow] = useState(false);
  const [refusalShow, setRefusalShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [statuses] = useState([
    "Application Received",
    "Approved",
    "Further evidence required",
    "Refused",
    "Closed",
  ]);
  const [TypeOfApplicationStatuses] = useState([
    "Passport Benefits",
    "Low Income",
  ]);
  const [caseTypes] = useState([
    "Child arrangements",
    "Financial arrangements",
    "Both issues",
  ]);
  const [findStatuses] = useState([
    "Google",
    "Support Through Court",
    "Legal Aid website",
    "Solicitor",
    "Mediator - Rachel Marsden",
    "FMC website",
    "Community Centre",
    "Facebook",
    "Friend/Family",
  ]);
  const [image, setImage] = useState(null);
  const [paragraphOne, setParagraphOne] = useState(
    "We could see that you are receiving funding for Universal Credit."
  );
  const [paragraphTwo, setParagraphTwo] = useState(
    "Would you be able to send us an image VIA EMAIL of your last Universal Credit entitlement as per the attached image? We need to see your FULL NAME, ADDRESS date of the last payment, and the amount you received or will receive."
  );

  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useGetLegalAidClientsQuery(
    "LegalAdDetails",
    {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    const legalAidFilter = localStorage.getItem("legalAidFilter");
    if (legalAidFilter) {
      setLegalAidFilter(legalAidFilter);
    }
  }, []);

  useEffect(() => {
    let optionsOfRefusal = [];
    setRefusalOptions(optionsOfRefusal);
    if (Option1 !== "") optionsOfRefusal.push(Option1);
    if (Option1 !== "" && isThereOption2 === "Yes" && Option2 !== "")
      optionsOfRefusal.push(Option2);
    if (
      Option1 !== "" &&
      isThereOption2 === "Yes" &&
      Option2 !== "" &&
      isThereOption3 === "Yes" &&
      Option3 !== ""
    )
      optionsOfRefusal.push(Option3);
    if (
      Option1 !== "" &&
      isThereOption2 === "Yes" &&
      Option2 !== "" &&
      isThereOption3 === "Yes" &&
      Option3 !== "" &&
      isThereOption4 === "Yes" &&
      Option4 !== ""
    )
      optionsOfRefusal.push(Option4);
    if (
      Option1 !== "" &&
      isThereOption2 === "Yes" &&
      Option2 !== "" &&
      isThereOption3 === "Yes" &&
      Option3 !== "" &&
      isThereOption4 === "Yes" &&
      Option4 !== "" &&
      isThereOption5 === "Yes" &&
      Option5 !== ""
    )
      optionsOfRefusal.push(Option5);
    if (
      Option1 !== "" &&
      isThereOption2 === "Yes" &&
      Option2 !== "" &&
      isThereOption3 === "Yes" &&
      Option3 !== "" &&
      isThereOption4 === "Yes" &&
      Option4 !== "" &&
      isThereOption5 === "Yes" &&
      Option5 !== "" &&
      isThereOption6 === "Yes" &&
      Option6 !== ""
    )
      optionsOfRefusal.push(Option6);
  }, [
    Option1,
    Option2,
    Option3,
    Option4,
    Option5,
    Option6,
    isThereOption2,
    isThereOption3,
    isThereOption4,
    isThereOption5,
    isThereOption6,
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRefusalClose = () => setRefusalShow(false);
  const handleRefusalShow = () => setRefusalShow(true);

  const getSeverity = (value) => {
    switch (value) {
      case "Application Received":
        return "#6366f1";
      case "Approved":
        return "#22c55e";
      case "Further evidence required":
        return "#f59e0b";
      case "Refused":
        return "#ef4444";
      case "Closed":
        return "grey";
      default:
        return null;
    }
  };

  const getTypeOfApplicationSeverity = (value) => {
    switch (value) {
      case "Passport Benefits":
        return "#FFD700";
      case "Low Income":
        return "#808080";
      default:
        return null;
    }
  };

  const getCaseTypeSeverity = (value) => {
    switch (value) {
      case "Child Arrangements  ":
        return "#00FF00";
      case "Financial Arrangements  ":
        return "#0000FF";
      case "Both":
        return "#800080";
      default:
        return null;
    }
  };

  const getFindSeverity = (value) => {
    switch (value) {
      case "Google":
        return "#4285F4";
      case "Support Through Court":
        return "#FF9933";
      case "Legal Aid website":
        return "#336699";
      case "Solicitor":
        return "#800000";
      case "Mediator - Rachel Marsden":
        return "#FF00FF";
      case "FMC website":
        return "#663399";
      case "Facebook":
        return "#1877F2";
      case "Community Centre":
        return "#FFA500";
      case "Friend/Family":
        return "#FFC0CB";
      default:
        return null;
    }
  };

  const handleLegalAidInputChange = (e) => {
    const value = e.target.value;
    setLegalAidFilter(value);
    localStorage.setItem("legalAidFilter", value);
  };

  const statusItemTemplate = (option) => {
    return (
      <Tag
        value={option}
        severity={getSeverity(option)}
        style={{ backgroundColor: getSeverity(option) }}
      />
    );
  };

  const findStatusItemTemplate = (option) => {
    return (
      <Tag
        value={option}
        severity={getFindSeverity(option)}
        style={{ backgroundColor: getFindSeverity(option) }}
      />
    );
  };

  const TypeOfApplicationStatusesItemTemplate = (option) => {
    return (
      <Tag
        value={option}
        style={{ backgroundColor: getTypeOfApplicationSeverity(option) }}
      />
    );
  };

  const TypeOfCasesItemTemplate = (option) => {
    return (
      <Tag
        value={option}
        style={{ backgroundColor: getCaseTypeSeverity(option) }}
      />
    );
  };

  const findStatusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={findStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={findStatusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const TypeOfApplicationStatusesFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={TypeOfApplicationStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={TypeOfApplicationStatusesItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const typeOfCasesFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={caseTypes}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={TypeOfCasesItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusEditor = () => {
    return (
      <Dropdown
        value={applicationType}
        options={statuses}
        onChange={(e) => setApplicationType(e.value)}
        placeholder="Select a Status"
        itemTemplate={(option) => {
          return (
            <Tag
              value={option}
              style={{ backgroundColor: getSeverity(option) }}
            ></Tag>
          );
        }}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        style={{ backgroundColor: getSeverity(rowData.status) }}
      ></Tag>
    );
  };

  const TypeOfApplicationStatusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.typeOfApplication}
        style={{
          backgroundColor: getTypeOfApplicationSeverity(
            rowData.typeOfApplication
          ),
        }}
      ></Tag>
    );
  };

  const findStatusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.howFoundUs}
        style={{
          backgroundColor: getFindSeverity(rowData.howFoundUs),
        }}
      ></Tag>
    );
  };

  const TypeOfCaseStatusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.caseAbout}
        style={{
          backgroundColor: getCaseTypeSeverity(rowData.caseAbout),
        }}
      ></Tag>
    );
  };

  const actionDeleteBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            setVisible(true);
            confirmDeleteProduct(rowData);
          }}
        />
      </>
    );
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={(e) => {
          setVisible(false);
          const data = {
            id: clientId,
          };
          dispatch(deleteLegalAidClient(data)).then((res) => {
            if (res.payload.status === 200) {
              refetch();
            }
          });
        }}
        autoFocus
        severity="danger"
      />
    </div>
  );

  const confirmDeleteProduct = (product) => {
    setClientId(product._id);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  const onRowEditComplete = (e) => {
    const rowData = e.newData;
    let data = {
      id: rowData._id,
      legalAidStatus: applicationType,
    };
    setDataValue(data);
    if (applicationType === "Further evidence required") {
      handleShow();
    } else if (applicationType === "Refused") {
      handleRefusalShow();
    } else {
      dispatch(editTypeOfApplication(data))
        .then((res) => {
          if (res.payload.status === 200) {
            refetch();
            setApplicationType("");
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res.payload.data.message,
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: res.payload,
              life: 3000,
            });
          }
        })
        .catch((err) => console.log({ err }));
    }
  };

  function onRefusalSubmit() {
    setIsLoading(true);
    if (refusalOptions.length === 0) {
      setErrorOnSubmit("Please enter option");
      setIsLoading(false);
    } else {
      dispatch(
        editTypeOfApplication({ ...dataValue, reasonsList: refusalOptions })
      )
        .then((res) => {
          if (res.payload.status === 200) {
            setRefusalShow(false);
            setRefusalOptions([]);
            setIsLoading(false);
            handleRefusalClose();
            setErrorOnSubmit("");
            setOption1("");
            setOption2("");
            setOption3("");
            setOption4("");
            setOption5("");
            setOption6("");
            setIsThereOption2("No");
            setIsThereOption3("No");
            setIsThereOption4("No");
            setIsThereOption5("No");
            setIsThereOption6("No");
            refetch();
            setApplicationType("");
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res?.payload.data.message,
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: res.payload,
              life: 3000,
            });
          }
        })
        .catch((err) => console.log({ err }));
    }
  }

  function onFurtherEvidenceSubmit() {
    setIsLoading(true);
    const body = {
      ...dataValue,
      paragraphOne: paragraphOne,
      paragraphTwo: paragraphTwo,
      FurtherEvidenceIMG: image,
    };
    dispatch(furtherEvidenceRequired(body))
      .then((res) => {
        if (res.payload.status === 200) {
          setIsLoading(false);
          refetch();
          setApplicationType("");
          handleClose();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res?.payload.data.message,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res?.payload,
            life: 3000,
          });
        }
      })
      .catch((err) => console.log({ err }));
  }

  if (data)
    return (
      <>
        <Toast ref={toast} />
        <Dialog
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
          footer={footerContent}
        >
          <p className="m-0">Are you sure you want to delete?</p>
        </Dialog>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Further evidence required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`${styles.fullForm}`}>
              <div className="d-flex flex-column resize">
                <label htmlFor="body" className="pb-2">
                  Paragraph One
                </label>
                <textarea
                  name="body"
                  id="body"
                  cols="30"
                  rows="2"
                  className={`${styles.textAreaEmailBody}`}
                  value={paragraphOne}
                  onChange={(e) => setParagraphOne(e.target.value)}
                ></textarea>
              </div>
              <div className="d-flex flex-column resize mt-4 mb-4">
                <label htmlFor="body" className="pb-2">
                  Paragraph Two
                </label>
                <textarea
                  name="body"
                  id="body"
                  cols="30"
                  rows="5"
                  className={`${styles.textAreaEmailBody}`}
                  value={paragraphTwo}
                  onChange={(e) => setParagraphTwo(e.target.value)}
                ></textarea>
              </div>
              <div className={`${styles.ImageFileDiv}`}>
                <p className={`${styles.ImageFileTitle} mb-2`}>Image</p>
                <label htmlFor="ImageFile" className={`${styles.ImageFile}`}>
                  Upload an image - {image?.name}
                </label>
                <input
                  type="file"
                  name="ImageFile"
                  id="ImageFile"
                  className={`${styles.ImageFileInput}`}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={`${styles.sendButton}`}
              onClick={onFurtherEvidenceSubmit}
            >
              Send
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={refusalShow}
          onHide={handleRefusalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reasons for refusal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="AvailabilityForm">
              <label className="form-label mt-3">Reason 1:</label>
              <input
                type="text"
                name="Options"
                value={Option1}
                onChange={(e) => setOption1(e.target.value)}
                className="select-input"
              />
              <label className="form-label">
                Is there another reason for refusal?
              </label>
              <div className="d-flex justify-content-start flex-column align-items-start">
                <div className="mb-2">
                  <input
                    type="radio"
                    value="Yes"
                    name="option2"
                    id="Yes-One-option"
                    checked={isThereOption2 === "Yes"}
                    onChange={(e) => {
                      setIsThereOption2(e.target.value);
                    }}
                    required
                  />
                  <label htmlFor="Yes-One-option" className="ms-1">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="No"
                    name="option2"
                    id="No-One-Option"
                    checked={isThereOption2 === "No"}
                    onChange={(e) => {
                      setIsThereOption2(e.target.value);
                    }}
                    required
                  />
                  <label htmlFor="No-One-Option" className="ms-1">
                    No
                  </label>
                </div>
              </div>
              {isThereOption2 === "Yes" && (
                <>
                  <label className="form-label mt-3">Reason 2:</label>
                  <input
                    type="text"
                    name="Options"
                    value={Option2}
                    onChange={(e) => setOption2(e.target.value)}
                    className="select-input"
                    required
                  />
                  <label className="form-label">
                    Is there another reason for refusal?
                  </label>
                  <div className="d-flex justify-content-start flex-column align-items-start">
                    <div className="mb-2">
                      <input
                        type="radio"
                        value="Yes"
                        name="option3"
                        id="Yes-Option-Three"
                        checked={isThereOption3 === "Yes"}
                        onChange={(e) => {
                          setIsThereOption3(e.target.value);
                        }}
                        required
                      />
                      <label htmlFor="Yes-Option-Three" className="ms-1">
                        Yes
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="No"
                        name="option3"
                        id="No-Option-three"
                        checked={isThereOption3 === "No"}
                        onChange={(e) => {
                          setIsThereOption3(e.target.value);
                        }}
                      />
                      <label htmlFor="No-Option-three" className="ms-1">
                        No
                      </label>
                    </div>
                  </div>
                </>
              )}
              {isThereOption2 === "Yes" && isThereOption3 === "Yes" && (
                <>
                  <label className="form-label mt-3">Reason 3:</label>
                  <input
                    type="text"
                    name="Options"
                    value={Option3}
                    onChange={(e) => setOption3(e.target.value)}
                    className="select-input"
                    required
                  />
                  <label className="form-label">
                    Is there another reason for refusal?
                  </label>
                  <div className="d-flex justify-content-start flex-column align-items-start">
                    <div className="mb-2">
                      <input
                        type="radio"
                        value="Yes"
                        name="option4"
                        id="Yes-Option-Four"
                        checked={isThereOption4 === "Yes"}
                        onChange={(e) => {
                          setIsThereOption4(e.target.value);
                        }}
                        required
                      />
                      <label htmlFor="Yes-Option-Four" className="ms-1">
                        Yes
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="No"
                        name="option4"
                        id="No-Option-Four"
                        checked={isThereOption4 === "No"}
                        onChange={(e) => {
                          setIsThereOption4(e.target.value);
                        }}
                      />
                      <label htmlFor="No-Option-Four" className="ms-1">
                        No
                      </label>
                    </div>
                  </div>
                </>
              )}
              {isThereOption2 === "Yes" &&
                isThereOption3 === "Yes" &&
                isThereOption4 === "Yes" && (
                  <>
                    <label className="form-label mt-3">Reason 4:</label>
                    <input
                      type="text"
                      name="Options"
                      value={Option4}
                      onChange={(e) => setOption4(e.target.value)}
                      className="select-input"
                      required
                    />
                    <label className="form-label">
                      Is there another reason for refusal?
                    </label>
                    <div className="d-flex justify-content-start flex-column align-items-start">
                      <div className="mb-2">
                        <input
                          type="radio"
                          value="Yes"
                          name="option5"
                          id="Yes-Option-Five"
                          checked={isThereOption5 === "Yes"}
                          onChange={(e) => {
                            setIsThereOption5(e.target.value);
                          }}
                          required
                        />
                        <label htmlFor="Yes-Option-Five" className="ms-1">
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          name="option5"
                          id="No-Option-Five"
                          checked={isThereOption5 === "No"}
                          onChange={(e) => {
                            setIsThereOption5(e.target.value);
                          }}
                        />
                        <label htmlFor="No-Option-Five" className="ms-1">
                          No
                        </label>
                      </div>
                    </div>
                  </>
                )}
              {isThereOption2 === "Yes" &&
                isThereOption3 === "Yes" &&
                isThereOption4 === "Yes" &&
                isThereOption5 === "Yes" && (
                  <>
                    <label className="form-label mt-3">Reason 5:</label>
                    <input
                      type="text"
                      name="Options"
                      value={Option5}
                      onChange={(e) => setOption5(e.target.value)}
                      className="select-input"
                      required
                    />
                    <label className="form-label">
                      Is there another reason for refusal?
                    </label>
                    <div className="d-flex justify-content-start flex-column align-items-start">
                      <div className="mb-2">
                        <input
                          type="radio"
                          value="Yes"
                          name="option6"
                          id="Yes-Option-Six"
                          checked={isThereOption6 === "Yes"}
                          onChange={(e) => {
                            setIsThereOption6(e.target.value);
                          }}
                          required
                        />
                        <label htmlFor="Yes-Option-Six" className="ms-1">
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          name="option6"
                          id="No-Option-Six"
                          checked={isThereOption6 === "No"}
                          onChange={(e) => {
                            setIsThereOption6(e.target.value);
                          }}
                        />
                        <label htmlFor="No-Option-Six" className="ms-1">
                          No
                        </label>
                      </div>
                    </div>
                  </>
                )}
              {isThereOption2 === "Yes" &&
                isThereOption3 === "Yes" &&
                isThereOption4 === "Yes" &&
                isThereOption5 === "Yes" &&
                isThereOption6 === "Yes" && (
                  <>
                    <label className="form-label mt-3">Reason 6:</label>
                    <input
                      type="text"
                      name="Options"
                      value={Option6}
                      onChange={(e) => setOption6(e.target.value)}
                      className="select-input"
                      required
                    />
                  </>
                )}
              {refusalOptions.length === 0 ? (
                <small className="text-danger">
                  <span>{errorOnSubmit}</span>
                </small>
              ) : (
                ""
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={`${styles.sendButton}`}
              onClick={onRefusalSubmit}
            >
              {loader ? <Spinner animation="border" /> : "Send"}
            </button>
          </Modal.Footer>
        </Modal>
        <div className="mt-5 pt-3">
          <div className="x">
            <DataTable
              removableSort
              className="m-auto mx-lg-3"
              value={data.data}
              editMode="row"
              dataKey="_id"
              onRowEditComplete={onRowEditComplete}
              globalFilter={legalAidFilter}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              header={
                <LegalAidTableHeader
                  legalAidFilter={legalAidFilter}
                  handleLegalAidInputChange={handleLegalAidInputChange}
                  data={data.data}
                />
              }
            >
              <Column
                field="firstname"
                header="Name"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="surname"
                header="Surname"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="typeOfApplication"
                header="Type of application"
                filter
                body={TypeOfApplicationStatusBodyTemplate}
                filterElement={TypeOfApplicationStatusesFilterTemplate}
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                field="status"
                header="Status"
                filter
                body={statusBodyTemplate}
                filterElement={statusFilterTemplate}
                editor={(options) => statusEditor(options)}
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                rowEditor
                header="Edit Status"
                headerStyle={{ width: "10%", minWidth: "12rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
              <Column
                field="dob"
                header="Date of birth"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="postCode"
                header="Post Code"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="phone"
                header="Phone number"
                sortable
                style={{ minWidth: "14rem" }}
              ></Column>
              <Column
                field="email"
                header="Email address"
                sortable
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                field="caseAbout"
                header="Type of case"
                body={TypeOfCaseStatusBodyTemplate}
                filterElement={typeOfCasesFilterTemplate}
                filter
                style={{ minWidth: "14rem" }}
              ></Column>
              <Column
                field="howFoundUs"
                header="How they found us"
                filterElement={findStatusFilterTemplate}
                body={findStatusBodyTemplate}
                filter
                style={{ minWidth: "20rem" }}
              ></Column>
              <Column
                field="surNameOftheOtherPerson"
                header="Surname of other person"
                sortable
                style={{ whiteSpace: "noWrap" }}
              ></Column>
              <Column
                body={actionDeleteBodyTemplate}
                exportable={false}
                style={{ minWidth: "4rem" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </>
    );
};

export default LegalAidTable;
