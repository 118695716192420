import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";

import MaimInfo from "./maim";
import Details from "../Case/Details";
import OtherPersonData from "./otherpersondata";
import FriendDetails from "./friendDetails";
import style from "./Case.module.css";
import PrevRelations from "./prevRelations";
import ChildDetails from "./childDetails";
import useScrollTo from "../../hooks/useScrollTo";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { useGetCaseDetailsQuery } from "../../services/auth/authService";

const MiamDetails = ({ id }) => {
  useScrollTo(0, 0, "smooth");

  const [CaseData, SetCaseData] = useState(null);

  const { data: CaseDetails, isFetching: fetchingCaseDetails } =
    useGetCaseDetailsQuery(id, {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true, // 15mins
    });

  useEffect(() => {
    if (CaseDetails) {
      SetCaseData(CaseDetails);
    }
  }, [CaseDetails, CaseData]);

  if (fetchingCaseDetails) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <Row className="mt-3 d-flex justify-content-center w-100">
        <div className="d-flex justify-content-end">
          <Link
            className={`${style.BackBtn} me-3 my-3 text-white text-decoration-none `}
            to={{ pathname: `/case/${id}` }}
          >
            <div className=" rounded" style={{ backgroundColor: "#0337a4" }}>
              <TiArrowBack className="fs-3" />{" "}
              <span
                style={{
                  color: "white",
                  fontWeight: "bold",
                  paddingTop: "20px",
                }}
              >
                Back
              </span>
            </div>
          </Link>{" "}
        </div>
        <Details
          status={CaseData?.status}
          startDate={CaseData?.MIAMDates}
          Reference={CaseData?.Reference}
          isSuitable={CaseData?.caseSuitable}
        />
        <Col lg={12}>
          <div className={`${style.parentTable} mb-4`}>
            <div className="d-flex align-items-center justify-content-between ps-1 w-100">
              <p className={style.titleMiam}>C2 MIAM Part 1</p>

              {CaseData?.MIAM1_C2 === "Data didn't added yet" ? (
                <Link
                  disabled
                  className={`${style.EditBtn} me-3 text-white text-decoration-none bg-secondary`}
                >
                  <div className=" rounded">
                    <AiFillEdit className="fs-5 me-2" />{" "}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        paddingTop: "20px",
                      }}
                    >
                      Edit
                    </span>
                  </div>
                </Link>
              ) : (
                <Link
                  className={`${style.EditBtn} me-3 text-white text-decoration-none `}
                  to={{ pathname: `/edit/C2/${id}` }}
                  state={{ data: CaseData?.MIAM1_C2 }}
                >
                  <div className=" rounded">
                    <AiFillEdit className="fs-5 me-2" />{" "}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        paddingTop: "20px",
                      }}
                    >
                      Edit
                    </span>
                  </div>
                </Link>
              )}
            </div>
            {CaseDetails?.MIAM1_C2 === "Data didn't added yet" ? (
              <NoDataComponent />
            ) : (
              <div className={`${style.tablesDev} d-flex `}>
                <div>
                  <MaimInfo data={CaseData?.MIAM1_C2} />
                  <PrevRelations data={CaseData?.MIAM1_C2} />
                </div>
                <div className={`${style.tablesDevChildren}`}>
                  <OtherPersonData data={CaseData?.MIAM1_C2} />
                  <ChildDetails data={CaseData?.MIAM1_C2?.children} />
                  <FriendDetails data={CaseData?.MIAM1_C2} />
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MiamDetails;
