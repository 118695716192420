import { configureStore } from "@reduxjs/toolkit";

import { authApi } from "../services/auth/authService";
import reducer from "./reducer";
import { adminAuth } from "../services/auth/companyService";

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([authApi.middleware, adminAuth.middleware]),
});

export default store;
