import { useEffect } from "react";

function useScrollTo(x, y, behavior) {
  useEffect(() => {
    window.scrollTo({
      behavior: behavior || "smooth",
      left: y || 0,
      top: x || 0,
    });
  }, [x, y, behavior]);
}

export default useScrollTo;