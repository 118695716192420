import styles from "./legalAidTable.module.css";

import { useLegalAidClientsQuery } from "../../services/auth/authService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Spinner } from "react-bootstrap";
import { useRef, useState } from "react";
import LeaderAidClientHeader from "./LeaderAidClientHeader";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { deleteLegalAidClient } from "../../features/auth/authActions";

const LegalAidDataTableMarketing = () => {
  const [legalAidFilterClient, setLegalAidFilterClient] = useState("");
  const [clientId, setClientId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [TypeOfApplicationStatuses] = useState([
    "Passport Benefits",
    "Low Income",
  ]);
  const dispatch = useDispatch();
  const dt = useRef(null);
  const { data, isLoading, refetch } = useLegalAidClientsQuery(
    "legalAidDataTableMarketing",
    {
      pollingInterval: 900000,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleLegalAidClientInputChange = (e) => {
    const value = e.target.value;
    setLegalAidFilterClient(value);
    localStorage.setItem("legalAidFilterClient", value);
  };

  const getTypeOfApplicationSeverity = (value) => {
    switch (value) {
      case "Passport Benefits":
        return "#FFD700";
      case "Low Income":
        return "#808080";
      default:
        return null;
    }
  };

  const TypeOfApplicationStatusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.typeOfApplication}
        style={{
          backgroundColor: getTypeOfApplicationSeverity(
            rowData.typeOfApplication
          ),
        }}
      ></Tag>
    );
  };

  const TypeOfApplicationStatusesItemTemplate = (option) => {
    return (
      <Tag
        value={option}
        style={{ backgroundColor: getTypeOfApplicationSeverity(option) }}
      />
    );
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={(e) => {
          setVisible(false);
          const data = {
            id: clientId,
          };
          dispatch(deleteLegalAidClient(data)).then((res) => {
            if (res.payload.status === 200) {
              refetch();
            }
          });
        }}
        autoFocus
        severity="danger"
      />
    </div>
  );

  const TypeOfApplicationStatusesFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={TypeOfApplicationStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={TypeOfApplicationStatusesItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const actionDeleteBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            setVisible(true);
            confirmDeleteProduct(rowData);
          }}
        />
      </>
    );
  };

  const confirmDeleteProduct = (product) => {
    setClientId(product._id);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "1rem",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <p className="m-0">Are you sure you want to delete?</p>
      </Dialog>
      <div className={styles.MarketingTable}>
        <div className="x">
          <DataTable
            className="m-auto mx-lg-3"
            removableSort
            ref={dt}
            value={data.data}
            paginator
            tableStyle={{ minWidth: "50rem" }}
            dataKey="_id"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            globalFilter={legalAidFilterClient}
            header={
              <LeaderAidClientHeader
                handleLegalAidClientInputChange={
                  handleLegalAidClientInputChange
                }
                legalAidFilterClient={legalAidFilterClient}
                dt={dt}
              />
            }
          >
            <Column
              field="firstname"
              header="First Name"
              sortable
              style={{ minWidth: "10rem" }}
            ></Column>
            <Column
              field="surname"
              header="Surname"
              sortable
              style={{ minWidth: "10rem" }}
            ></Column>
            <Column
              field="email"
              header="Email"
              sortable
              style={{ minWidth: "20rem" }}
            ></Column>
            <Column
              field="phone"
              header="Phone"
              sortable
              style={{ minWidth: "15rem" }}
            ></Column>
            <Column
              field="typeOfApplication"
              header="Type Of Application"
              body={TypeOfApplicationStatusBodyTemplate}
              filter
              style={{ minWidth: "20rem" }}
              filterElement={TypeOfApplicationStatusesFilterTemplate}
            ></Column>
            <Column
              body={actionDeleteBodyTemplate}
              exportable={false}
              style={{ minWidth: "4rem" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default LegalAidDataTableMarketing;
