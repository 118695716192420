import React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";

import { addClient } from "../../../features/auth/authActions";
import Error from "../../Error";
import { Message } from "primereact/message";

const AddClient = ({
  productDialog,
  hideDialog,
  classNames,
  allMediatorsData,
  refetch,
  refetchData,
}) => {
  const [showPrivate, SetPrivate] = useState(false);
  const [showZoomLink, SetshowZoomLink] = useState(false);

  const defaultValues = {
    firstName: "",
    surName: "",
    phoneNumber: "",
    email: "",
    dateOfMAIM: "",
    location: "",
    mediatorMail: "",
    caseType: "",
    legalAidType: "",
    zoomLink: "",
  };

  const arrLocation = ["Whatsapp video", "Zoom", "In Person"];

  const { loading, error } = useSelector((state) => state.Case);
  const { userInfo } = useSelector((state) => state.auth);
  const userData = userInfo?.role;

  function convertToLocalFormat(phoneNumber) {
    // Remove any non-digit characters from the input
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    // Check if the number starts with "07"
    if (cleanedNumber.startsWith("07")) {
      // Replace "07" with "+44"
      return "+44" + cleanedNumber.slice(2);
    } else {
      // If it doesn't start with "07", return the number as is
      return phoneNumber;
    }
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    formState,
    watch,
  } = useForm({ defaultValues });

  const { isSubmitting } = formState;

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const {
      firstName,
      surName,
      phoneNumber,
      email,
      dateOfMAIM,
      location,
      mediatorMail,
      caseType,
      legalAidType,
      zoomLink,
    } = data;

    const twilioNumber = convertToLocalFormat(phoneNumber);

    const obj = {
      firstName,
      surName,
      phoneNumber: twilioNumber,
      email,
      dateOfMAIM,
      location,
      mediatorMail,
      caseType,
      legalAidType,
      zoomLink,
    };

    dispatch(addClient(obj)).then(() => {
      refetchData();
      refetch();
      reset();
    });
  };

  const watchLastNameCheck = watch("caseType");
  const whatchLocation = watch("location");

  useEffect(() => {
    if (watchLastNameCheck === "LegalAid") {
      SetPrivate(true);
    } else if (watchLastNameCheck === "private") {
      SetPrivate(false);
    }
    if (whatchLocation === "Zoom") {
      SetshowZoomLink(true);
    } else {
      SetshowZoomLink(false);
    }
  }, [watchLastNameCheck, whatchLocation]);

  const getFormErrorMessage = (name) => {
    return errors[name] && <p className="p-error">{errors[name].message}</p>;
  };

  return (
    <>
      <Dialog
        header="Add Client"
        modal
        visible={productDialog}
        className="p-fluid "
        style={{ width: "40rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        onHide={hideDialog}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <Message
            severity="warn"
            text="Please login with google and config twilio data"
            style={{
              width: "100%",
            }}
          />
          <div className="field">
            <span className="font-bold pop_up_label">
              <label
                htmlFor="firstName"
                className={classNames({ "p-error": !!errors.firstName })}
              >
                First Name:
              </label>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First Name is required.",
                  minLength: { value: 3, message: "Length must be 3 or more" },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={`${classNames({
                      "p-invalid": fieldState.invalid,
                    })} pop_up_input`}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("firstName")}
          </div>
          <div className="field">
            <span className="font-bold pop_up_label">
              <label
                htmlFor="surName"
                className={classNames({ "p-error": !!errors.surName })}
              >
                Surname:
              </label>
              <Controller
                name="surName"
                control={control}
                rules={{
                  required: "surName is required.",
                  minLength: { value: 3, message: "Length must be 3 or more" },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={`${classNames({
                      "p-invalid": fieldState.invalid,
                    })} pop_up_input`}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("surName")}
          </div>
          <div className="field">
            <span className="font-bold pop_up_label">
              <label
                htmlFor="phoneNumber"
                className={classNames({ "p-error": !!errors.phoneNumber })}
              >
                Phone Number:
              </label>
              <p className="text-primary fs-6 m-0 p-0">
                Enter your mobile phone without spaces which you use for
                WhatsApp. Example 07123456789
              </p>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: "phoneNumber is required.",
                  pattern: {
                    value: /^(07[\d]{8,12}|447[\d]{7,11})$/,
                    message:
                      "Entered value does not match UK phone number format (e.g., +447123456789)",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={`${classNames({
                      "p-invalid": fieldState.invalid,
                    })} pop_up_input`}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("phoneNumber")}
          </div>
          <div className="field">
            <span className="font-bold pop_up_label">
              <label
                htmlFor="email"
                className={classNames({ "p-error": !!errors.email })}
              >
                User Email:
              </label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address. E.g. example@email.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={`${classNames({
                      "p-invalid": fieldState.invalid,
                    })} pop_up_input`}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("email")}
          </div>
          {userData === "company" && (
            <>
              <div className="field">
                <span className="font-bold pop_up_label">
                  <label
                    htmlFor="mediatorMail"
                    className={`${classNames({
                      "p-error": !!errors.mediatorMail,
                    })} d-block`}
                  >
                    Mediator Name:
                  </label>

                  <Controller
                    name="mediatorMail"
                    control={control}
                    rules={{
                      required: "mediator name is required.",
                      minLength: {
                        value: 3,
                        message: "Length must be 3 or more",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <select
                        value="default"
                        name="mediatorMail"
                        id={field.name}
                        {...field}
                        className={`${classNames({
                          "p-invalid": fieldState.invalid,
                        })} pop_up_input w-100`}
                      >
                        <option value="" disabled>
                          Select a mediator name
                        </option>
                        {allMediatorsData?.map((mediator) => {
                          return (
                            <option value={mediator.email} key={mediator.email}>
                              {mediator.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  />
                </span>
                {getFormErrorMessage("mediatorMail")}
              </div>
              <div className="field">
                <span className="font-bold pop_up_label">
                  <label
                    htmlFor="mediatorMail"
                    className={`${classNames({
                      "p-error": !!errors.mediatorMail,
                    })} d-block`}
                  >
                    Mediator Email:
                  </label>

                  <Controller
                    name="mediatorMail"
                    control={control}
                    rules={{
                      required: "mediator name is required.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        disabled
                        {...field}
                        className={`${classNames({
                          "p-invalid": fieldState.invalid,
                        })} pop_up_input`}
                      >
                        {" "}
                        {allMediatorsData?.map((mediator) => {
                          return mediator.email;
                        })}{" "}
                      </InputText>
                    )}
                  />
                </span>
              </div>
            </>
          )}
          <>
            <div className="field">
              <span className="font-bold pop_up_label">
                <label
                  htmlFor="dateOfMAIM"
                  className={classNames({ "p-error": !!errors.dateOfMAIM })}
                >
                  Date of MAIM:
                </label>
                <Controller
                  name="dateOfMAIM"
                  control={control}
                  rules={{
                    required: "date Of MAIM is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <input
                      type="datetime-local"
                      step="900"
                      id={field.name}
                      {...field}
                      className={`${classNames({
                        "p-invalid": fieldState.invalid,
                      })} pop_up_input w-100`}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage("dateOfMAIM")}
            </div>
            <div className="field">
              <span className="font-bold pop_up_label">
                <label
                  htmlFor="location"
                  className={classNames({ "p-error": !!errors.location })}
                >
                  Location:
                </label>
                <Controller
                  name="location"
                  control={control}
                  rules={{
                    required: "location is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <select
                      value="default"
                      name="location"
                      id={field.name}
                      {...field}
                      className={`${classNames({
                        "p-invalid": fieldState.invalid,
                      })} pop_up_input w-100`}
                    >
                      <option value="" disabled>
                        Select location
                      </option>
                      {arrLocation?.map((location, i) => {
                        return (
                          <option value={location} key={i}>
                            {location}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
              </span>
              {getFormErrorMessage("location")}
            </div>
          </>
          {showZoomLink ? (
            <div className="field">
              <span className="font-bold pop_up_label">
                <label
                  htmlFor="location"
                  className={classNames({ "p-error": !!errors.location })}
                >
                  zoomLink:
                </label>
                <Controller
                  name="zoomLink"
                  control={control}
                  rules={{
                    required: "zoomLink is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <input
                      value="default"
                      name="zoomLink"
                      id={field.name}
                      {...field}
                      className={`${classNames({
                        "p-invalid": fieldState.invalid,
                      })} pop_up_input w-100`}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage("zoomLink")}
            </div>
          ) : (
            ""
          )}
          {showPrivate ? (
            <div className="field">
              <span className="font-bold pop_up_label">
                <label
                  htmlFor="legalAidType"
                  className={classNames({ "p-error": !!errors.caseType })}
                >
                  Passport / Low Income:
                </label>
                <Controller
                  name="legalAidType"
                  control={control}
                  rules={{
                    required: "legalAidType is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <div className="mt-2">
                      <div>
                        <RadioButton
                          inputId="f5"
                          {...field}
                          inputRef={field.ref}
                          value="passporting"
                          checked={field.value === "passporting"}
                        />
                        <label htmlFor="f5" className=" me-5 ms-2">
                          Passport
                        </label>
                      </div>

                      <div className="mt-2">
                        <RadioButton
                          inputId="f6"
                          {...field}
                          value="lowIncome"
                          checked={field.value === "lowIncome"}
                        />
                        <label htmlFor="f6" className=" me-5 ms-2">
                          Low Income
                        </label>
                      </div>
                    </div>
                  )}
                />
              </span>
              {getFormErrorMessage("legalAidType")}
            </div>
          ) : (
            ""
          )}
          {error && <Error>{error}</Error>}
          <Button
            disabled={isSubmitting}
            type="submit"
            label={
              loading ? (
                <span className="spinner-border spinner-border-sm "></span>
              ) : (
                "Add"
              )
            }
            className="btn-add-clint"
          />
        </form>
      </Dialog>
    </>
  );
};

export default AddClient;
